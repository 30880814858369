<section [hidden]="!ready" class="sec-salesman">
    <div class="grid">
        <div class="title" (click)="expandAccordion()">
            <!-- TITULO -->
            <h3>Deseja informar o código do vendedor?</h3>
            <mat-icon class="form-icon" >
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64"><g id="shop"><path d="M57.89,16.55A.977.977,0,0,0,57,16H50V14h6A6,6,0,0,0,56,2H8A6,6,0,0,0,8,14h6v2H7a.977.977,0,0,0-.89.55l-4,8A1,1,0,0,0,3,26v5a5.014,5.014,0,0,0,3,4.58V61a1,1,0,0,0,1,1H57a1,1,0,0,0,1-1V35.58A5.014,5.014,0,0,0,61,31V26a1,1,0,0,0,.89-1.45ZM44.69,24l-2.25-6h5.87l2.25,6ZM51,26v5a3,3,0,0,1-6,0V26ZM35,26v5a3,3,0,0,1-6,0V26Zm-5.72-2,1.5-6h2.44l1.5,6ZM20,16V14H44v2Zm1.44,8,2.25-6h5.03l-1.5,6ZM27,26v5a3,3,0,0,1-6,0V26Zm13.31-8,2.25,6H36.78l-1.5-6ZM37,26h6v5a3,3,0,0,1-6,0Zm9-10V14h2v2ZM8,12A4,4,0,0,1,8,4H56a4,4,0,0,1,0,8Zm10,2v2H16V14Zm-2.31,4h5.87l-2.25,6H13.44ZM19,26v5a3,3,0,0,1-6,0V26ZM7.62,18h5.94l-2.25,6H4.62ZM5,31V26h6v5a3,3,0,0,1-6,0ZM52,60H40V48a6,6,0,0,1,12,0Zm4,0H54V48a8,8,0,0,0-16,0V60H8V36a4.994,4.994,0,0,0,4-2.03,4.956,4.956,0,0,0,8,0,4.956,4.956,0,0,0,8,0,4.956,4.956,0,0,0,8,0,4.956,4.956,0,0,0,8,0,4.956,4.956,0,0,0,8,0A4.994,4.994,0,0,0,56,36Zm3-29a3,3,0,0,1-6,0V26h6Zm-6.31-7-2.25-6h5.94l3,6Z"/><path d="M35,40H11a1,1,0,0,0-1,1V55a1,1,0,0,0,1,1H35a1,1,0,0,0,1-1V41A1,1,0,0,0,35,40ZM34,54H12V42H34Z"/></g></svg>
            </mat-icon>
        </div>
        <p *ngIf="validated"><button mat-raised-button color="primary" class="small-btn-blue" (click)="expandAccordion()">{{ salesmanId }}</button></p>

        <form [@openClose]="minimizeForm ? 'closed' : 'open'">
            <!-- <ng-template> -->
                <mat-form-field class="form-field">
                    <mat-label>Informe o código do vendedor</mat-label>
                    <input matInput id="salesmanId" name="salesmanId" [(ngModel)]="salesmanId">
                </mat-form-field>

                <button class="button btn-voucher" (click)="applySalesman()">
                    Aplicar
                    <mat-icon class="ico">check_circle_outline</mat-icon>
                </button>
          
            
        </form> 
    </div>
</section>