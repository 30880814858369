<section class="default-modal">
    <div class="default-modal-header">
        <img *ngIf="data.icon" alt="icon" [src]="'../../../assets/imagens/' + data.icon"/>
        <h2>{{ data.title }}</h2>
    </div>

    <div class="content">
        <p>{{ data.description }}</p>
        <p *ngIf="data.subDescription">{{ data.subDescription }}</p>
    </div>

    <button *ngIf="!data.showConfirmation" type="button" class="btn-go-back" (click)="closeModal()">{{ data.button }}</button>

    <div *ngIf="data.showConfirmation" class="btn-confirmation-wrapper">
        <button type="button" class="btn-cancel" (click)="closeModal()">{{ 'goBack' | translate}}</button>
        <button type="button" class="btn-confirm" [mat-dialog-close]="true">{{ data.button }}</button>        
    </div>

</section>

