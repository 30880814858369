<section class="sec-pix-value">
    <div class="grid sec-pix-container">
        <div class="title-wrapper">
            <img src="../../../../../assets/imagens/icon-payments-pix.png"/>
            <h2 class="section-title">{{ 'pix.pix' | translate }}</h2>
            <span *ngIf="discountPercent > 0 && showDiscount" class="discount-tag">{{discountPercent}}% OFF</span>
        </div>
        <form>
            <label>{{'pix.pixPaymentValue' | translate}}:</label>
            <input currencyMask
                class="pix-value-input"
                type="text"
                id="pix-value"
                [(ngModel)]="pixValue"
                name="pix-value"
                [options]="maskOptions"
                (blur)="pixValueChange()"/>
        </form>
        <p class="subtitle">{{description}}</p>
    </div>
</section>