import { Component, OnInit, Output, EventEmitter, Renderer2 } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { trigger, state, style, transition, animate } from '@angular/animations';
import { TranslateService } from '@ngx-translate/core';
import { PaymentService } from 'src/app/services/payment.service';
import { UiService } from 'src/app/services/ui.service';
import { List } from 'linqts';
import { CheckoutCmsService, PaymentMethodsDescription } from 'src/app/services/cms/checkoutCms.service';
import { PaymentLinkService } from 'src/app/services/payment-link.service';
import { environment } from 'src/environments/environment';
import Enumerable from 'linq';
import { MethodModel } from 'src/app/model/payments/method.model';
import { PaymentMethodModel } from 'src/app/model/payments/payment-method.model';
import { PaymentMethodEnum } from 'src/app/model/payments/payment-method.enum';
import { PaymentMethodConfigModel } from 'src/app/model/payments/payment-method-config.model';

@Component({
  selector: 'app-select-payment-method',
  templateUrl: './select-payment-method.component.html',
  styleUrls: ['./select-payment-method.component.scss'],
  animations: [
    trigger('openClose', [
      // ...
      state('open', style({
        height: '*',
        display: 'block',
        opacity: 1
      })),
      state('closed', style({
        height: '0px',
        padding: '0px',
        display: 'none',
        opacity: 0
      })),
      transition('open <=> closed', [
        style({ display: 'block', opacity: 0.5 }),
        animate('0.2s')
      ])
    ]),
  ]
})
export class SelectPaymentMethodComponent implements OnInit {

  @Output() changePaymentMethodEvent = new EventEmitter<any>();

  ready: boolean = false;

  paymentMethods: Array<PaymentMethodModel> = [];
  paymentConditions: Array<any> = [];
  total: number;
  otherDiscounts: number = 0;
  paymentLinkOrigin: string;
  paymentMethodsDescription: PaymentMethodsDescription;
  paymentMethodResult: any;
  data: any;
  paymentMethodEnum = PaymentMethodEnum;
  enableMultiplePayments: boolean = false;

  model: any = {
    paymentMethod: "",
    paymentCondition: "",
    paymentMethodApplied: false,
    minimizeForm: false,
    paymentDescription: ""
  };

  constructor(
    private paymentService: PaymentService,
    private uiService: UiService,
    public translate: TranslateService,
    public dialog: MatDialog,
    private checkoutCmsService: CheckoutCmsService,
    private renderer: Renderer2,
    private paymentLinkService: PaymentLinkService,
  ) { }

  async ngOnInit() {
    try {
      this.renderer.removeClass(document.body, 'back-button-visible');
      
      this.paymentService.dataChangedEvent.subscribe(async (e) => {
        if (e.data) {
          this.data = e.data;
          this.total = e.data.order.totalAmount;
          this.paymentLinkOrigin = e.data.order.origin;
          if (this.paymentLinkOrigin === 'ELOS') {
            this.paymentMethodsDescription = await this.checkoutCmsService.getPaymentMethodsDescriptions();
          }
          
          if (e.event != 'savePaymentMethod') {
            var methods = new List<MethodModel>(e.data.methods);
            var order: PaymentMethodEnum[] = [PaymentMethodEnum.Pix, PaymentMethodEnum.CreditCard, PaymentMethodEnum.MultiplePayments, PaymentMethodEnum.Recurrent];
            this.otherDiscounts = this.paymentLinkService.getOtherDiscounts(this.data.order.payments);

            this.paymentMethods = methods
              .DistinctBy(x => x.paymentMethod_Id)
              .Select(pm => {
                var itemInstallments = methods.Where(x => x.paymentMethod_Id == pm.paymentMethod_Id);
                let queryConditions = this.paymentLinkService.queryPaymentConditions(e.data, pm.paymentMethod_Id);

                let installmentsAllowed = Enumerable.from(queryConditions)
                .groupBy(c => c.installments)
                .select(g => {
                  let f = Enumerable.from(g.getSource()).orderByDescending(o => o.minPurchaseAmountToEnable);
                  return f.firstOrDefault();
                })
                .where(x=> x.minInstallmentAmount == null || x.minInstallmentAmount <= 0 || x.installmentValue >= x.minInstallmentAmount);

                return ({ 
                  id: pm.paymentMethod_Id, 
                  name: pm.paymentMethod_Description, 
                  legend: pm.paymentMethod_Legend,
                  creditCardType: pm.paymentMethod_CreditCardType as PaymentMethodEnum,
                  bestDiscount: this.getBestDiscount(pm),
                  showDiscountPercent: this.showDiscountPercent(pm, new List<PaymentMethodConfigModel>(e.data.order.configurations)),
                  maxInstallments: itemInstallments.Max(x => x.paymentCondition_Installments),
                  maxInstallmentsAllowed: installmentsAllowed.any() ? installmentsAllowed.max(x => x.installments as any) : null,
                  hasInstallmentsAllowed: installmentsAllowed.any(),
                  contingency: pm.contingency,
                  netValue:  pm.netValue,
                  ignoreBestDiscount: false
                } as PaymentMethodModel);
              })
              .Where(w => w.hasInstallmentsAllowed)
              .ToArray();
              
              this.enableMultiplePayments = this.checkForMultiplePayments();

              if (this.enableMultiplePayments) {
                let creditCardDescription = await this.uiService.translate('creditCard.creditCard');
                let pixCardDescription = await this.uiService.translate('pix.pix');
                let paymentDescription = `${pixCardDescription} + ${creditCardDescription}`;

                this.paymentMethods.push({
                  id: 0,
                  name: paymentDescription,
                  legend: '',
                  creditCardType: PaymentMethodEnum.MultiplePayments,
                  showDiscountPercent: false,
                  lastItem: false,
                  netValue: 0
                });
              }

              this.paymentMethods = this.paymentMethods.sort((a, b) => order.indexOf(a.creditCardType) - order.indexOf(b.creditCardType));

              this.paymentMethods[this.paymentMethods.length - 1].lastItem = true;
          } 

          let needToSelectPaymentMethodOnServer = false;

          if (e.data.order.paymentMethodId) {
            this.model.paymentDescription = e.data.order.paymentMethod + " " + e.data.order.paymentCondition;
            this.model.paymentMethodApplied = true;
            this.setMinimizeForm(true);
          } else if (e.data.methods.length == 1) {
            let method = e.data.methods[0];

            e.data.order.paymentMethod = method.paymentMethod_Description;
            e.data.order.paymentMethodId = method.paymentMethod_Id;
            e.data.order.paymentCondition = method.paymentCondition_Description;
            e.data.order.paymentConditionId = method.paymentCondition_Id;

            this.model.paymentDescription = e.data.order.paymentMethod + " " + e.data.order.paymentCondition;
            this.model.paymentMethodApplied = true;

            needToSelectPaymentMethodOnServer = true;
            this.setMinimizeForm(true);
          } else {
            this.model.paymentDescription = "";
            this.model.paymentMethodApplied = false;
            this.setMinimizeForm(false);
          }

          if (e.data.order.paymentMethodId) {
            this.model.paymentMethod = e.data.order.paymentMethodId;
          }
          
          if (e.data.order.paymentMethod_CreditCardType != 3){
            this.queryPaymentConditions(e.data);
          }

          setTimeout(() => {

            this.model.paymentCondition = 
                this.paymentConditions =
                  new List<any>(this.paymentConditions)
                    .Where(d => d.id == e.data.order.paymentConditionId)
                    .FirstOrDefault();
                    
            if (this.model.paymentMethod && !needToSelectPaymentMethodOnServer) {
              this.uiService.sendUiEvent("formapgtoReady");
            }

            setTimeout(async () => {
              this.ready = true;

              if (needToSelectPaymentMethodOnServer) {
                this.paymentService.savePaymentMethodAndCondition.next(e.data.order.paymentMethodId);
               }
            }, 400);
          }, 400);
        }
      });

      this.uiService.uiChangedEvent.subscribe((eventName) => {

        if (eventName == "cardOpen") {
          this.setMinimizeForm(true);
        }
      });
    }
    catch (e) {
      await this.uiService.showError(e);
    }
  }

  showDiscountPercent(pm: any, configurations: List<PaymentMethodConfigModel>): boolean{
    var type = pm.paymentMethod_CreditCardType as PaymentMethodEnum;
    var bestDiscount = this.getBestDiscount(pm);

    if(bestDiscount > 0 &&
      (
        (type == PaymentMethodEnum.CreditCard && configurations.Any(x => x.name == 'showDiscountPercentCredit' && x.value.toLowerCase() == 'true')) ||
        (type == PaymentMethodEnum.Recurrent && configurations.Any(x => x.name == 'showDiscountPercentRecurrent' && x.value.toLowerCase() == 'true'))||
        (type == PaymentMethodEnum.Pix && configurations.Any(x => x.name == 'showDiscountPercentPix' && x.value.toLowerCase() == 'true'))
      ))
      return true;
    else
      return false;
  }

  async queryPaymentConditions(data) {
      this.paymentLinkService.queryPaymentConditions(data);
  }

  async gonext(creditCardType: any = null) {
    try {

      if (this.ready && this.model.paymentMethod != undefined) {
        //Se a forma de pagamento for diferente de PIX, gravamos apenas a forma de pagamento, pois a condição será selecionada depois
        if (creditCardType != 3) {
          this.paymentMethodResult = await this.paymentService.selectPaymentMethod(this.model.paymentMethod); 
        } else if (this.model.paymentMethod) {
          if(this.paymentConditions.length <= 0){
            this.paymentService.data.order.paymentMethodId = this.model.paymentMethod;
            this.paymentConditions = this.paymentLinkService.queryPaymentConditions(this.paymentService.data);
          }
          this.paymentMethodResult = await this.paymentService.savePaymentMethod(this.model.paymentMethod, this.paymentConditions[0].id);
        }
        this.uiService.sendUiEvent("formapgtoReady");
      }
    }
    catch (e) {
      await this.uiService.showError(e);
    }
  }

  selectPaymentMethod(pm) {

    this.model.paymentMethod = pm;

    if (this.ready) {
      setTimeout(async () => {
        this.uiService.sendUiEvent("formapgtoNotReady");

        this.model.paymentMethodApplied = false;

        this.model.paymentCondition = undefined;

      }, 30);
    }
  }

  setMinimizeForm(value) {
    this.model.minimizeForm = value;

    if (this.model.minimizeForm) {
      this.uiService.sendUiEvent("formapgtoClosed");
    } else {
      this.uiService.sendUiEvent("formapgtoOpen");
    }
  }

  expandAccordion() {
    if (this.model.paymentMethodApplied) {
      this.setMinimizeForm(!this.model.minimizeForm);
    }
  }

  async selectPm(pm: PaymentMethodModel){
    this.model.paymentMethod = pm.id;

    if (this.model.paymentMethod > 0){
      this.paymentLinkService.paymentMethodChanged.next(pm);
      this.paymentConditions = this.paymentLinkService.queryPaymentConditions(this.paymentService.data);
    }

    this.model.paymentMethodApplied = true;

    await this.gonext(pm.creditCardType);

    if (!this.paymentMethodResult || (this.paymentMethodResult && !this.paymentMethodResult.outdated)) {
      if (pm.id == 0) {
        var order: PaymentMethodEnum[] = [ PaymentMethodEnum.MultiplePayments, PaymentMethodEnum.CreditCard, PaymentMethodEnum.Recurrent, PaymentMethodEnum.Pix ];
        let multiplePayments = this.paymentMethods.sort((a, b) => order.indexOf(a.creditCardType) - order.indexOf(b.creditCardType));
        this.changePaymentMethodEvent.emit(multiplePayments);
        this.paymentLinkService.multiplePaymentsSelected.next(multiplePayments);
        return;
      }
      this.changePaymentMethodEvent.emit(pm);
    }   
  }

  getBestDiscount(pm: MethodModel){
    return Math.round((pm.discountPercent * 100) * 100) / 100;
  }

  checkForMultiplePayments(): boolean{
    var multiplePaymentsIsEnable: boolean = this.data.order.multiplePaymentsIsEnable;
    let hasCreditCard = Enumerable.from(this.paymentMethods).any(w => w.creditCardType == this.paymentMethodEnum.CreditCard);
    let hasPix = Enumerable.from(this.paymentMethods).any(w => w.creditCardType == this.paymentMethodEnum.Pix);
    let flagEnableMultiplePayments = environment.enableMultiplePayments;

    return multiplePaymentsIsEnable && hasCreditCard && hasPix && flagEnableMultiplePayments;
  }
}
