import { Component, OnInit } from '@angular/core';
import {  Router, ActivatedRoute } from '@angular/router';
import { PaymentService } from 'src/app/services/payment.service';
import { environment } from 'src/environments/environment';
import { UiService } from 'src/app/services/ui.service';

@Component({
  selector: 'app-payment-link',
  templateUrl: './payment-link.component.html',
  styleUrls: ['./payment-link.component.scss']
})
export class PaymentLinkComponent implements OnInit {

code: string;

paymentLink: any;

payment: any;

paymentRequestCode: string;

flagshipEstablishmentId: number;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private paymentService: PaymentService,
    private uiService: UiService
  ) { }

  ngOnInit(): void {
    
    this.route.url.subscribe(async param => {
      this.code = this.route.snapshot.paramMap.get('code');
      this.paymentRequestCode = this.route.snapshot.paramMap.get('payment-code');

      await this.paymentService.loadPaymentRequestByExternalOrder(this.code);

    });

    this.route.queryParams.subscribe( param =>{
      this.flagshipEstablishmentId = param['id'];
      this.paymentService.dataPaymentLink.subscribe(pl =>{
        this.payment = pl;
        if(!this.payment){
          this.router.navigate([this.paymentRequestCode], { queryParams: { tt: true, id:  this.flagshipEstablishmentId} })
        }
      }); 
    });
  }

  async sendPaymentLink(){
    var data = await this.paymentService.flagshipSendPaymentEmail(this.payment.url);
    if(!data.error){
      this.uiService.showToastr('E-mail enviado com sucesso.', 'Sucesso', 'success');
    }
  }

  finalize(){
    window.location.href = environment.flagshipUrl;
  }

}
