import { Component, OnInit, Input, Renderer2 } from '@angular/core';
import { ElosgateService } from 'src/app/services/elosgate.service';
import { environment } from 'src/environments/environment';
import { UiService } from 'src/app/services/ui.service';
import { CheckoutCmsService, PixContent } from 'src/app/services/cms/checkoutCms.service';
import { Router } from '@angular/router';
import { PaymentService } from 'src/app/services/payment.service';
import { MatDialog } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { PaymentLinkService } from 'src/app/services/payment-link.service';
import { ChangePaymentMethodModalComponent } from 'src/app/components/modal/change-payment-method-modal/change-payment-method-modal.component';

@Component({
  selector: 'app-pix',
  templateUrl: './pix.component.html',
  styleUrls: ['./pix.component.scss']
})
export class PixComponent implements OnInit {

  @Input() transactionId: any;

  @Input() externalCode: any;

  @Input() waitingPixProceed = false;

  @Input() isElos: boolean = false;

  @Input() serverTime: any;

  @Input() partiallyPaid: boolean = false;

  pixResult: any;

  counter: { min: number, sec: number };

  hideCode: boolean = false;

  linkPix: string;

  pixValidityInMinutes: number = Number(environment.pixValidityInMinutes);

  pixContent: PixContent;

  copiedToClipboard: boolean = false;

  pixResultCurrency: any;

  pixResultValue: any;

  currency: string = 'R$';

  total: number;

  data: any;

  paymentLinkOrigin: string;

  initialDate: any;

  endDate: any;

  remainingTime: any;

  constructor(
    private elosgateService: ElosgateService,
    private uiService: UiService,
    private renderer:Renderer2,
    private checkoutCmsService: CheckoutCmsService,
    private router: Router,
    private paymentService: PaymentService,
    private paymentLinkService: PaymentLinkService,
    public dialog: MatDialog,
    public translate: TranslateService) { }

  async ngOnInit() {
    this.renderer.removeClass(document.body, 'back-button-visible');
    
    this.paymentService.dataChangedEvent.subscribe(async (e) => {
      this.paymentLinkOrigin = e.data.order.origin;
    });

    if(this.partiallyPaid){
      var validatePayments = await this.paymentService.validateMultiplePaymentsExpirationTime();
      if(validatePayments.paymentsExpired){
        window.location.reload();
        return;
      }
    }

    this.pixContent = await this.checkoutCmsService.getPixContent();

    await this.gerarCobrancaPix();
  }

  async gerarCobrancaPix() {
    if (this.transactionId) {
      this.pixResult = await this.elosgateService.gerarCobrancaPix({ "PagamentoID": this.transactionId, "Cobranca": false, "Culture": "pt" });
      if (this.pixResult) {
        this.linkPix = this.pixResult.Link.trim();
        let valueSplit = this.pixResult.Valor.split(' ');

        this.pixResultCurrency = valueSplit[0];
        //TODO no futuro considerar o idioma enviada para API ao fazer o parse
        this.pixResultValue = parseFloat(valueSplit[1].replace('.', '').replace(',', '.'));

        this.startTimer();
        await this.verificarPagamentoPix();
      }
    }
  }

  async verificarPagamentoPix() {
    let interval = setInterval(async () => {
      var result = await this.elosgateService.verificarPagamentoPix({"PagamentoID": this.transactionId,"Culture":"pt"});
      if(result.Pago){
        clearInterval(interval);
        this.router.navigate([ `/done/${this.externalCode}` ]);
      }
    }, 10000);
  }

  async startTimer() {
    this.initialDate = new Date(this.serverTime);
    this.endDate = new Date(this.serverTime);
    this.endDate.setHours(23, 0, 0);
    
    let interval = setInterval(async () => {
      this.remainingTime = this.getRemainingTime(this.endDate);
      if(this.remainingTime.finish)
      {
        clearInterval(interval);
        await this.timerFinished();
        return;
      }

      this.initialDate.setSeconds(this.initialDate.getSeconds() + 1);
    }, 1000);
    
  }

  async timerFinished(){
    if(this.partiallyPaid){
      var validatePayments = await this.paymentService.validateMultiplePaymentsExpirationTime();
      if(validatePayments.paymentsExpired){
        await this.paymentLinkService.showMultiplePaymentsTimeInfoModal();
        window.location.reload();
        return;
      }
    } 
    else
      window.location.reload();
  }

  hideQRCode() {
    this.hideCode = !this.hideCode;
  }

  copyCode(codeElement) {
    if (this.uiService.isBrowser) {
      codeElement.select();
      document.execCommand('copy');
      codeElement.setSelectionRange(0, 0);
      this.copiedToClipboard = true;
    }
  }


  getRemainingTime(endtime) {
    var t = Date.parse(endtime) - this.initialDate;
    var seconds = Math.floor((t / 1000) % 60);
    var minutes = Math.floor((t / 1000 / 60) % 60);
    var hours = Math.floor((t / (1000 * 60 * 60)) % 24);
    return {
      'hours': hours,
      'minutes': minutes,
      'seconds': seconds,
      finish: hours <= 0 && minutes <= 0 && seconds <= 0
    }
  }

  async cancelPayment(){
    var result = await this.paymentService.cancelPayment();
    if(!result.error){
      window.location.reload();
    }
  }

  async confirmCancel(){
    if(this.partiallyPaid){
      var result = await this.dialog.open(ChangePaymentMethodModalComponent, {
        width: '322px'
      })
      .afterClosed()
      .toPromise();
      
      if(result){
        await this.cancelPayment();
      }  
    }else{
      window.location.reload();
    }
  } 

}


