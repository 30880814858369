import { ElementRef, Inject, Injectable, Renderer2, RendererFactory2 } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { PaymentService } from './payment.service';
import { BehaviorSubject } from 'rxjs';

declare var callBackExternal: any;

export enum EAppIntegrationPaymentStatus {
  Error = 'Error',
  Failure = 'Failure',
  Confirmed = 'Confirmed',
  Exit = "Exit",
  GoToSchedule = 'GoToSchedule',
  OpenExternalLink = "OpenExternalLink"
}

@Injectable({
  providedIn: 'root',
})
export class AppIntegrationService {
  private renderer: Renderer2;
  public origem: string;
  public currentRoute: string;
  public isExternalPayment: boolean;

  public modalTitle: BehaviorSubject<string> = new BehaviorSubject<string>('Checkout');

  constructor(
    private paymentService: PaymentService,
    rendererFactory: RendererFactory2,
    @Inject(DOCUMENT) private document: any
  ) {
    this.renderer = rendererFactory.createRenderer(null, null);
  }

  loadIntegration(parameters: any, isExternalPayment = false) {
    this.origem = parameters?.appChannel;
    this.isExternalPayment = isExternalPayment;
    this.setDataPayment(parameters?.appChannel);
    this.loadScript();
  }

  /* script necessário para integração com app de agendamento */
  loadScript() {
    const script = `
  function callBackExternal(action, data){
    var message = { action: action, data: data};
    webkit.messageHandlers.cordova_iab.postMessage(JSON.stringify(message));
  }`;
    setTimeout(() => {
      const fileref = this.renderer.createElement('script');
      fileref.innerHTML = script;
      this.renderer.appendChild(this.document.body, fileref);
      document.body.classList.add('external-background');
    }, 50);
  }

  sendToApp(paymentStatus: EAppIntegrationPaymentStatus, data: any = null) {
    callBackExternal(paymentStatus, data);
  }

  setDataPayment(appChannel: string) {
    this.paymentService.dataPayment.next({ order: { origin: appChannel } });
  }
}
