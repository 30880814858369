<section class="payment-error-modal">
    <div class="modal-header">
        <img alt="error-icon" src="../../../assets/imagens/error-icon.png"/>
        <h2>{{'paymentError.title' | translate}}</h2>
    </div>
    <div class="payment-error-content">
        <p>{{'paymentError.description' | translate}}</p>
        <p>{{'paymentError.pleaseCheckYourCardData' | translate}}</p>
    </div>
    <button type="button" class="btn-go-back" (click)="closeModal()">{{'goBack' | translate}}</button>
</section>

