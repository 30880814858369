import { trigger, state, style, transition, animate } from '@angular/animations';
import { OnDestroy, Renderer2 } from '@angular/core';
import { Component, HostListener, OnInit, Input } from '@angular/core';
import { CreditCard } from 'src/app/model/credit-card';
import { ElosgateService } from 'src/app/services/elosgate.service';
import { PaymentService } from 'src/app/services/payment.service';
import { UiService } from 'src/app/services/ui.service';
import { Router } from '@angular/router';
import { environment } from '../../../../environments/environment';
import { TranslateService } from '@ngx-translate/core';
import { GiftitemService } from 'src/app/services/giftitem.service';
import { CheckoutCmsService, PaymentMethodsSubtitle } from 'src/app/services/cms/checkoutCms.service';
import { PaymentLinkService } from 'src/app/services/payment-link.service';
import { MatDialog } from '@angular/material/dialog';
import { ParcelasComponent } from 'src/app/pages/payment/formapgto/parcelas/parcelas.component';
import { Subscription } from 'rxjs';
import { RecurrentInfoComponent } from 'src/app/components/modal/recurrent-info/recurrent-info.component';
import { DefaultModalComponent, DefaultModalModel } from 'src/app/components/modal/default-modal/default-modal.component';
import { CurrencyPipe } from '@angular/common';
import { MultiplePaymentsConfirmModalComponent, MultiplePaymentsConfirmModalModel, MultiplePaymentsConfirmModalType } from 'src/app/components/modal/multiple-payments-confirm-modal/multiple-payments-confirm-modal.component';
import { List } from 'linqts';
import { PaymentConditionModel } from 'src/app/model/payments/payment-condition.model';
import { PaymentMethodModel } from 'src/app/model/payments/payment-method.model';
import { CreditCardDisplayModel } from 'src/app/model/payments/credit-card-display.model';
import { PaymentMethodEnum } from 'src/app/model/payments/payment-method.enum';
import { PaymentMethodConfigModel } from 'src/app/model/payments/payment-method-config.model';
import { MultiplePaymentsModel } from 'src/app/model/payments/multiple-payments.model';

declare var TokenEx: any;

@Component({
  selector: 'app-credit-card',
  templateUrl: './credit-card.component.html',
  styleUrls: ['./credit-card.component.scss'],
  animations: [
    trigger('openClose', [

      state('open', style({
        height: '*',
        display: 'block',
        opacity: 1
      })),
      state('closed', style({
        height: '0px',
        padding: '0px',
        display: 'none',
        opacity: 0
      })),
      transition('open <=> closed', [
        style({ display: 'block', opacity: 0.2 }),
        animate('0.2s')
      ])
    ]),
  ]
})
export class CreditCardComponent implements OnInit, OnDestroy {

  responsive: boolean = false;
  responsiveBreakpoint: number = 768;

  ready: boolean = false;
  destroyed: boolean = false;

  public model: CreditCard;

  minimizeForm: boolean = false;
  voucherOpen: boolean = false;
  formapgtoOpen: boolean = false;
  total: number;
  giftItemsResolved: boolean = false;
  requireGiftItemResolve: boolean = false;
  giftItemCampaigns: any[];
  paymentConditionsReady: boolean = false;
  paymentConditions: PaymentConditionModel[] = [];
  paymentConditionId: number;
  paymentConditionSelected: PaymentConditionModel;
  paymentMethodDescription: string;
  creditCardType: any;
  paymentMethodsSubtitles: PaymentMethodsSubtitle;
  isFinalizingOrder: boolean = false;
  isTokenexFormValid: boolean = false;
  portionAmount: any;
  paymentMethodSubtitle: string;
  paymentMethodId: number;

  tooltipOptions: any;
  tooltipReady: boolean = false;
  pixPartialValue: number = 0;
  bestDiscountPercent: any;
  showDiscount: boolean = false;

  @Input() isTotem: boolean;
  @Input() flagshipEstablishmentId: number;
  @Input() paymentMethod: PaymentMethodModel;
  @Input() data: any;
  @Input() showMultiplePayments: boolean = false;
  @Input() multiplePayments: any;

  code: string;

  paymentRequestCode: string;

  configuracaoToken: any = {};

  iframe: any;

  tokenData: any;

  tokenLoaded: boolean = false;

  useTokenex: boolean = environment.useTokenex;

  tokenexLoaded: boolean = false;

  paymentLinkOrigin: string;

  possibleCardType: any;

  creditCardPartialNumber: any;

  creditCardDisplayModel: CreditCardDisplayModel = {firstSix: null, lastFour: null, validity: null, name: null, brandImage:''};

  finalized: boolean = false;

  version: number;

  finalizePurchaseFromPaymentLink: Subscription;

  isRecurrentAvaliable: boolean = false;

  constructor(private paymentService: PaymentService,
    private uiService: UiService,
    private elosgate: ElosgateService,
    private renderer: Renderer2,
    private router: Router,
    private giftitemService: GiftitemService,
    private checkoutCmsService: CheckoutCmsService,
    private paymentLinkService: PaymentLinkService,
    public dialog: MatDialog,
    public translate: TranslateService,
    private currencyPipe: CurrencyPipe) {

    this.model = {
      nome: "",
      numero: "",
      cvv: "",
      usaCvv: false,
      validade: "",
      autorizadoraSelecionada: 2,
      cardImg: "./assets/brands/none.png"
    };
  }

  ngOnDestroy() {
    this.destroyed = true;
    this.paymentMethodSubtitle = null;
    this.paymentMethod = null;
    this.finalizePurchaseFromPaymentLink.unsubscribe();
    this.finalizePurchaseFromPaymentLink = null;
    this.showMultiplePayments = false;
    this.bestDiscountPercent  = null;
    this.iframe.remove();
  }

  async ngOnInit() {
    
    this.paymentLinkService.paymentMethodChanged.subscribe((pm: PaymentMethodModel) => {
      this.paymentMethod = pm;
    });

    if(this.showMultiplePayments){
      let recurrentRef = this.data.methods.filter(w => w.paymentMethod_CreditCardType == 2)[0];
      this.isRecurrentAvaliable = recurrentRef ? true : false;
    }


    this.renderer.addClass(document.body, 'back-button-visible');

    if (this.uiService.isBrowser) {
      this.responsive = (window.innerWidth <= this.responsiveBreakpoint);
    }
    
    this.paymentMethodsSubtitles = await this.checkoutCmsService.getPaymentMethodsSubtitles();

    await this.initCloseSaleComponent();

    await this.queryPaymentConditions(this.data);

    this.buildPaymentMethodSubtitle(this.paymentMethod?.creditCardType);

    await this.initCVVTooltip();

    this.elosgate.onRejected.subscribe(async (ev) => {
      if (ev) {
        await this.paymentService.insertRefusedPaymentRequestEntry(this.paymentConditionId);
      }
    });
  }
  
  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.responsive = (event.target.innerWidth <= this.responsiveBreakpoint);
  }

  changeName($event) {

    this.model.nome = $event.toLocaleUpperCase();

    setTimeout(() => {
      this.model.nome = this.model.nome.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
      this.model.nome = this.model.nome.replace(/[^a-zA-Z ]/g, "");
    }, 10);
  }

  changeNumber($event) {
    setTimeout(() => {
      this.model.cardImg = './assets/brands/'.concat(this.elosgate.obterMarca(this.model.numero)).concat('.png');
    }, 20);

    switch ($event.inputType) {
      case "insertText":
      case "insertFromPaste":
        setTimeout(() => {
          this.model.numero = this.masknumber(this.model.numero);
        }, 10);

        break;
      case "deleteContentBackward":
      case "deleteContentBackward":
        if (this.model.numero.substr(this.model.numero.length - 1) == " ") {
          this.model.numero = this.model.numero.substr(0, this.model.numero.length - 2);
        }
        break;
    }
  }

  masknumber(inputTxt): string {

    var brand = this.elosgate.obterMarca(inputTxt);

    inputTxt = inputTxt.replace(/\D/g, "");

    if (brand == 'amex') {
      inputTxt = inputTxt.replace(/^(\d{4})(\d)/, "$1 $2");
      inputTxt = inputTxt.replace(/(\d{6})(\d)/, "$1 $2");
      inputTxt = inputTxt.substr(0, 19);
    } else {
      inputTxt = inputTxt.replace(/(\d{4})(\d)/, "$1 $2");
      inputTxt = inputTxt.replace(/(\d{4})(\d)/, "$1 $2");
      inputTxt = inputTxt.replace(/(\d{4})(\d)/, "$1 $2");
      inputTxt = inputTxt.replace(/(\d{4})(\d)/, "$1 $2");
      inputTxt = inputTxt.substr(0, 19);
    }
    return inputTxt;
  }

  changeValidade($event) {
    switch ($event.inputType) {
      case "insertText":
      case "insertFromPaste":
        setTimeout(() => {
          this.model.validade = this.maskvalidade(this.model.validade);
        }, 10);

        break;
      case "deleteContentBackward":
      case "deleteContentBackward":
        if (this.model.validade.substr(this.model.validade.length - 1) == "/") {
          this.model.validade = this.model.validade.substr(0, this.model.validade.length - 2);
        }
        break;
    }
  }

  maskvalidade(inputTxt): string {
    inputTxt = inputTxt.replace(/\D/g, "");
    inputTxt = inputTxt.replace(/(\d{2})(\d{1})/, "$1/$2");
    inputTxt = inputTxt.substr(0, 5);

    var currentMonth = new Date().getMonth() + 1;

    if (inputTxt.length >= 5 &&
      //mês inválido
      (inputTxt.substr(0, 2) > 12 || inputTxt.substr(0, 2) == "00" ||

        //mês inválido (menor que o mês atual caso seja o ano atual)
        (inputTxt.substr(0, 2) < currentMonth &&
          inputTxt.substr(3, 2) == new Date().getFullYear().toString().substr(2, 2)) ||

        //ano inválido (menor que o ano atual)
        inputTxt.substr(3, 2) < new Date().getFullYear().toString().substr(2, 2))) {
      inputTxt = "";
    }

    return inputTxt;
  }

  changeCVV($event) {
    switch ($event.inputType) {
      case "insertText":
      case "insertFromPaste":
        setTimeout(() => {
          this.model.cvv = this.maskCVV(this.model.cvv);
        }, 10);

        break;
    }
  }

  maskCVV(inputTxt): string {

    var marca = this.elosgate.obterMarca(this.model.numero);

    inputTxt = inputTxt.replace(/\D/g, "");

    if (marca == 'amex') {
      inputTxt = inputTxt.substr(0, 4);
    } else {
      inputTxt = inputTxt.substr(0, 3);
    }
    return inputTxt;
  }

  async finalizePaymentLink() {
    this.isFinalizingOrder = true;

    if (!this.paymentMethod && !this.showMultiplePayments) {
      let message = await this.uiService.translate("selectPaymentMethod");
      this.uiService.showToastr(message, 'Ops', 'warning');
      return;
    } else if (!this.paymentConditionId) {
      let message = await this.uiService.translate("SELECTPORTIONQUANTITY");
      this.uiService.showToastr(message, 'Ops', 'warning');
      return;
    }

    var result = true;

    if(this.showMultiplePayments){
      result = await this.dialog.open(MultiplePaymentsConfirmModalComponent, {
        width: '322px',
        data: {
          type: this.creditCardType as MultiplePaymentsConfirmModalType
        } as MultiplePaymentsConfirmModalModel
      })
      .afterClosed()
      .toPromise();
    }

    if(result){
      if (!this.uiService.termsAccepted && this.paymentLinkOrigin !== 'ELOS') {
        this.uiService.uiChangedEvent.emit('finalizeWithoutTerms');
        return;
      } else {
        this.uiService.uiChangedEvent.emit('finalizeWithTerms');
      }

      this.tokenLoaded = false;
      this.tokenize();
    }
  }

  /**Tokeniza o cartão digitado através do TOKENEX*/
  tokenize() {
    this.iframe.tokenize();
  }

  /**Função chamada a partir do evento tokenize do TOKENEX para continuar a execução da finalização do pagamento dentro do contexto do angular */
  onTokenize = (async (data) => {
    this.tokenData = data;
    if (this.giftItemCampaigns && this.giftItemCampaigns.length > 0) {
      var appliedGifts = await this.paymentService.checkForAppliedGifts();
      if (!appliedGifts) {
        var response = await this.showNoGiftItemSelectedModal();
        if (!response.value) {
          await this.finalizeOrder();
        }
        return;
      }
    }
    this.finalizeOrder();
  });

  /**Função chamada a partir do evento validate do TOKENEX para validar o número do cartão digitado*/
  onValidate = (async (data) => {
    if(data){
      this.creditCardDisplayModel.firstSix = data.firstSix;
      this.creditCardDisplayModel.lastFour = data.lastFour;
      this.emitCreditCardData();
    }

    if (!data.isValid && this.isFinalizingOrder) {
      await this.showInvalidCardModal();     
    } else if(data.isValid && data.isCvvValid && this.isFormValid()){        
        this.paymentLinkService.validateData.next(true);
    } else{
      this.paymentLinkService.validateData.next(false);
    }

    if(data.isValid && data.isCvvValid){
      this.isTokenexFormValid = true;
    }else{
      this.isTokenexFormValid = false;
    }

  });

  onCardTypeChange = (async (data) => {
    this.possibleCardType = data.possibleCardType;
  });

  async finalizeOrder() {
    try {
      this.uiService.sendUiEvent("finalizeStart");

      var validateResult = await this.paymentService.validatePaymentRequestVersion();      

      if(!validateResult.versionsMatch){
        var modalResult = await this.paymentService.showWrongVersionModal();
        if(modalResult){
            window.location.reload();
        }
        return { outdated: true };
      }

      var isEnable = await this.paymentLinkService.checkMultiplePayments();
      if(!isEnable){
        return { outdated: true };
      }

      var multiplePaymentMethods: MultiplePaymentsModel = null;

      if(this.showMultiplePayments){
        multiplePaymentMethods = {
          creditCardType: this.creditCardType,
          pixValue: this.pixPartialValue
        };
      }

      var payment = await this.paymentService.doCreditCardPayment(this.model, this.isTotem, this.tokenData, this.paymentConditionId, multiplePaymentMethods, this.paymentMethodId);

      if (this.isTotem && payment) {
        this.paymentService.saveFlagshipOrigin();
        var obj = { url: payment.url, qrCode: payment.qrCode };
        this.paymentService.setPaymentLink(obj);

        this.finalized = true;

        this.router.navigate([`/payment-link/${this.code}/${this.paymentRequestCode}`], { queryParams: { id: this.flagshipEstablishmentId } });
      }

    } catch (e) {
      this.uiService.sendUiEvent("finalizeError");
      await this.uiService.showError(e);
    }
  }

  expandAccordion() {
    if (this.minimizeForm) {
      this.uiService.sendUiEvent("cardOpen");
    }
  }

  async showNoGiftItemSelectedModal() {
    return await this.giftitemService.showNoGiftItemSelectedModal();
  }

  private async loadTokenexScript() {

    var src = environment.tokenexScriptUrl;

    var head = document.getElementsByTagName('head')[0];
    let script = document.createElement('script');
    script.setAttribute('src', src);
    head.appendChild(script);

    return script;

  }

  private async loadTokenex() {

      this.configuracaoToken.configuracaoToken.enablePrettyFormat = true;

      this.configuracaoToken.configuracaoToken.enableValidateOnBlur = true;

      this.configuracaoToken.configuracaoToken.enableValidateOnCvvKeyUp = true;

      this.configuracaoToken.configuracaoToken.customDataTypes.forEach(item => {
        item.cvvRequired = true;
      });

      this.configuracaoToken.configuracaoToken.styles = {
        base: "::-webkit-input-placeholder: font-size: 5px;font-family: 'Work Sans', sans-serif;padding: 0 8px;height: 40px;border-radius: 8px;border: 1px solid #E3E9E9;background: #EBF4F7;font-size: 14px;color: #333333;text-transform:uppercase;width:100%;box-sizing: border-box;",
        error: "border: 1px solid rgba(224, 57, 57, 0.5);",
        focus: "border: 1px solid #E3E9E9;outline: 0;",
        cvv: {
          base: "font-family: 'Work Sans', sans-serif;padding: 0 8px;height: 40px;border-radius: 8px;border: 1px solid #E3E9E9;background: #EBF4F7;font-size: 14px;color: #333333;text-transform:uppercase;width: 100%;box-sizing: border-box;",
          error: "border: 1px solid rgba(224, 57, 57, 0.5);",
          focus: "border: 1px solid #E3E9E9;outline: 0;"
        }
      }

      this.iframe = new TokenEx.Iframe("tokenExIframeDiv", this.configuracaoToken.configuracaoToken);

      this.iframe.load();

      this.iframe.on("tokenize", this.onTokenize);

      this.iframe.on("validate", this.onValidate);

      this.iframe.on("cardTypeChange", this.onCardTypeChange);

      this.iframe.on("error", this.onTokenizeError);

  }

  async queryPaymentConditions(data, useRecurrentAsReference: boolean = false) {
    let otherDiscounts = this.paymentLinkService.getOtherDiscounts(this.data.order.payments);
    
    if(this.showMultiplePayments){
      let referenceMethod;
      let configValue

      if(useRecurrentAsReference){
        referenceMethod = this.multiplePayments.find(w => w.creditCardType == PaymentMethodEnum.Recurrent);
        this.creditCardType = 2;    
        configValue = this.data.order.configurations.find(w => w.name === 'showDiscountPercentRecurrent')?.value;  
      }else{
        referenceMethod = this.multiplePayments.find(w => w.creditCardType == PaymentMethodEnum.CreditCard);
        this.creditCardType = 1;
        configValue = this.data.order.configurations.find(w => w.name === 'showDiscountPercentCredit')?.value;
      }

      this.showDiscount = configValue.toLowerCase() == 'true' ? true : false; 

      if(this.showDiscount)
        this.bestDiscountPercent = this.paymentLinkService.getBestDiscount(this.data, referenceMethod.id);
      
      this.paymentMethodDescription = referenceMethod.name;

      let valueWithoutPix = this.data.order.totalAmount - this.pixPartialValue;
      let pixBestDiscount = this.multiplePayments.find(w => w.creditCardType == PaymentMethodEnum.Pix)?.bestDiscount ?? 0;
      let pixFinalValue = this.pixPartialValue - (this.pixPartialValue - (this.pixPartialValue * (pixBestDiscount / 100)));

      this.paymentConditions = this.paymentLinkService.queryPaymentConditions(data, referenceMethod.id, valueWithoutPix, pixFinalValue, otherDiscounts);
    }else{
      this.paymentMethodDescription = this.paymentMethod ? this.paymentMethod.name : data.order.paymentMethod;
      this.paymentConditions = this.paymentLinkService.queryPaymentConditions(data, null, null, null, otherDiscounts);
    }

    this.paymentMethodId = this.paymentMethod ? this.paymentMethod.id : data.order.paymentMethodId;


    if (this.paymentConditions.length == 1) {
       this.paymentConditionId = this.paymentConditions[0].id;
       this.paymentConditionSelected = this.paymentLinkService.mapPaymentCondition(this.paymentConditions[0], data);
    }
  }
    
  async savePaymentMethod() {    
    if (this.paymentMethodId && this.paymentConditionId) {
      await this.paymentService.savePaymentMethod(this.paymentMethodId, this.paymentConditionId);
    }
  }

  validateForm(){
    var result = this.isFormValid();
    this.emitCreditCardData();
    if(!result || !this.isTokenexFormValid){
      this.paymentLinkService.validateData.next(false);
    }else{
      this.paymentLinkService.validateData.next(true);
    }
  }

  isFormValid(): boolean{
    return this.model.nome && this.model.validade ? true : false;
  }

  async initCloseSaleComponent(){

    if(!this.configuracaoToken?.authenticationKey && environment.useTokenex && this.uiService.isBrowser){
      this.configuracaoToken = await this.paymentService.obterChavesCapturaCartao(environment.elosgate_obterchaves_origin);
     

      if(!this.tokenexLoaded && this.configuracaoToken && this.uiService.isBrowser){
        var scriptElement = await this.loadTokenexScript();
        scriptElement.onload = () => {
          this.loadTokenex();
          this.tokenexLoaded = true;
        };
      }
    }

    this.paymentLinkOrigin = this.data.order.origin;
    this.total = this.data.order.totalAmount;
    this.requireGiftItemResolve = this.data.order.requireGiftItemResolve;
    this.giftItemCampaigns = this.data.order.giftItemCampaigns;
    if (this.data.order.paymentMethodId) {
      this.creditCardType = this.data.order.paymentMethod_CreditCardType ?? this.data.methods[0].paymentMethod_CreditCardType;
      if (this.creditCardType == 1) {
        this.model.usaCvv = true;
      } else {
        this.model.usaCvv = false;
      }

      if (!this.paymentService.dataPayment.value) {
        this.paymentService.dataPayment.next(this.data);
      }
    }

    this.code = this.data.order.externalCode;
    this.paymentRequestCode = this.data.order.code;


  //Escuta um evento enviado por outro componente para finalizar o pedido
  this.finalizePurchaseFromPaymentLink = this.paymentLinkService.finalizePurchaseFromPaymentLink.subscribe(async sub => {
      if (sub && sub == true && !this.finalized) {
        await this.finalizePaymentLink();
      }
    });
  }

  emitCreditCardData(){
    this.creditCardDisplayModel.name = this.model.nome;
    this.creditCardDisplayModel.validity = this.model.validade;
    this.creditCardDisplayModel.brandImage = this.getCardFlag(this.creditCardDisplayModel.firstSix, this.creditCardDisplayModel.lastFour).image;
    this.paymentLinkService.creditCardData.next(this.creditCardDisplayModel);
  }

  buildPaymentMethodSubtitle(creditCardType){
    creditCardType = !creditCardType ? 1 : creditCardType;
    this.paymentMethodSubtitle = creditCardType == 1 ? this.paymentMethodsSubtitles?.credit : this.paymentMethodsSubtitles?.recurrent;
  }

  showInstallments(){
    var showDiscountPercent: boolean = false;
    if(this.showMultiplePayments){
      var configurations = new List<PaymentMethodConfigModel>(this.data.order.configurations);
      showDiscountPercent = (this.creditCardType == PaymentMethodEnum.CreditCard && configurations.Any(x => x.name == 'showDiscountPercentCredit' && x.value.toLowerCase() == 'true')) ||
                            (this.creditCardType == PaymentMethodEnum.Recurrent && configurations.Any(x => x.name == 'showDiscountPercentRecurrent' && x.value.toLowerCase() == 'true'));
    }else
    {
      showDiscountPercent = this.paymentMethod.showDiscountPercent;
    }

    const dialogRef = this.dialog.open(ParcelasComponent, {
      panelClass: 'installments-modal',
      data: {
        paymentMethodId: this.paymentMethodId,
        paymentConditions: this.paymentConditions,
        showDiscountPercent: showDiscountPercent,
        order: this.data.order
      }
    });

    dialogRef.afterClosed().subscribe(async (result: PaymentConditionModel)  => {
      if(result)
      {
        this.paymentConditionId = result.id;
        this.paymentConditionSelected = result;

        this.paymentLinkService.paymentConditionChanged.next(result);

        if(this.showMultiplePayments){
          this.paymentLinkService.multiplePaymentsConditionChanged.next({
            pixPartialValue: this.pixPartialValue,
            removeCondition: false
          });
        }
        
      }
    })
  }

  async checkPix(){
    var data: DefaultModalModel = await this.checkPixMinValue() ?? await this.checkPixMinInstallments();

    if(data){
      this.dialog.open(DefaultModalComponent, {
        width: '322px',
        data: data
      });

      this.pixPartialValue = 0;
      this.paymentLinkService.multiplePaymentsConditionChanged.next({
        pixPartialValue: null,
        removeCondition: true
      });
      this.paymentLinkService.clearPixValue.next(null);
    }
  }

  async checkPixMinValue(): Promise<DefaultModalModel>{
    if(!this.pixPartialValue)
      return null;

    var minPixValue: number = this.data.order.minPixValue ?? 0;

    if(minPixValue > 0 && this.pixPartialValue < minPixValue){
      var pixPartialValueCurrency = this.currencyPipe.transform(this.pixPartialValue, this.translate.instant('currency'), '', '1.2-2');
      var minPixValueCurrency = this.currencyPipe.transform(minPixValue, this.translate.instant('currency'), '', '1.2-2');
      return {
        title: await this.translate.get("pixMinValueInfo.title").toPromise(),
        description: (await this.translate.get("pixMinValueInfo.description").toPromise()).replace('{pixValue}', pixPartialValueCurrency),
        subDescription: (await this.translate.get("pixMinValueInfo.subDescription").toPromise()).replace('{minPixValue}', minPixValueCurrency),
        button: await this.translate.get("pixMinValueInfo.button").toPromise()
      };
    }
    else
      return null;
  }

  async checkPixMinInstallments(): Promise<DefaultModalModel> {
    var pixExceedsSale = this.pixPartialValue >= this.data.order.totalAmount;

    var allInstallmentsBelowMinimum = this.paymentConditions.every(x=> 
      (x.minInstallmentAmount != null &&
      x.minInstallmentAmount != 0 &&
      x.installmentValue < x.minInstallmentAmount) || x.installmentValue <= 0);

    if(pixExceedsSale || allInstallmentsBelowMinimum){
      return {
        title: await this.translate.get("pixMinInstallmentsInfo.title").toPromise(),
        description: await this.translate.get("pixMinInstallmentsInfo.description").toPromise(),
        subDescription: await this.translate.get("pixMinInstallmentsInfo.subDescription").toPromise(),
        button: await this.translate.get("pixMinInstallmentsInfo.button").toPromise()
      };
    }
    else 
      return null;
  }
  
  getCardFlag(firstSixDigits: string, lastFourDigits: string) {
    const partialCardNumber = firstSixDigits + lastFourDigits;

    if (/^4/.test(partialCardNumber)) {
      return { brand:'Visa', image: 'visa.png'};
    } else if (/^5[1-5]/.test(partialCardNumber)) {
        return { brand:'MasterCard', image: 'master.png'};
    } else if ((/^34|^37/.test(partialCardNumber))) {
      return { brand:'American Express', image: 'amex.png'};
    } else if (/^6(?:011|5[0-9]{2}|22[1-9]|2[3-9][0-9])/.test(partialCardNumber)) {
      return { brand:'Discover', image: 'discover.png'};
    } else if (/^507(1[0-5]|2[2-9]|5[5])|^50[8-9]|^51|^52|^53|^54|^55/.test(partialCardNumber)) {
      return { brand:'Aura', image: 'aura.png'};
    } else if ((/^36|^3095|^3337|^34[4-9]|^37[0-4|^37[6-9]|^3831|^384[1-9]/.test(partialCardNumber))) {
      return { brand:'Diners Club', image: 'diners.png'};
    } else if ((/^384100|^384140|^384160|^606282|^637095|^637568|^637599|^637609|^637612/.test(partialCardNumber))) {
      return { brand:'Hipercard', image: 'hipercard.png'};
    } else if ((/^35(2[89]|[3-8][0-9])/.test(partialCardNumber))) {
      return { brand:'JCB', image: 'jcb.png'};
    }

    return { brand: null, image: null};
  }

 async initCVVTooltip(){
    let tooltipTextContent = {
      title: await this.uiService.translate("cvvTooltip.title"),
      subtitle: await this.uiService.translate("cvvTooltip.subtitle"),
      description: await this.uiService.translate("cvvTooltip.description"),
    }

    this.tooltipOptions = {
      arrow: true,
      content: `<div class="cvv-tooltip">
                  <div class="tooltip-title">${tooltipTextContent.title}</div>
                  <span class="tooltip-tag">
                    ${tooltipTextContent.subtitle}
                  </span>
                  <div class="tooltip-content">
                    ${tooltipTextContent.description}
                  </div>
                </div>`,
      allowHTML: true,
      maxWidth: 283
    }
    
    this.tooltipReady = true;
  }

  async pixValueChanged(e){
    this.pixPartialValue = e;
    this.paymentConditionSelected = null;
    this.paymentLinkService.multiplePaymentsConditionChanged.next({
      pixPartialValue: this.pixPartialValue,
      removeCondition: true
    });
    await this.queryPaymentConditions(this.data, this.creditCardType == 2);
    await this.checkPix();
  }

  async changeCardType(e){
    this.paymentConditionSelected = null;
    if(e.checked){
      await this.queryPaymentConditions(this.data, true);
      this.buildPaymentMethodSubtitle(2);
    }else{
      await this.queryPaymentConditions(this.data, false);
      this.buildPaymentMethodSubtitle(1);
    }
    this.paymentLinkService.multiplePaymentsConditionChanged.next({
      pixPartialValue: null,
      removeCondition: true
    });
  }

  showRecurrentInfo(){
    const dialogRef = this.dialog.open(RecurrentInfoComponent, {
      width: '322px'
    });
  }

  onTokenizeError = (async (data) => {
    this.isTokenexFormValid = false;
    this.validateForm();
    await this.showInvalidCardModal();
  });

  async showInvalidCardModal(){
    this.dialog.open(DefaultModalComponent, {
      width: '322px',
      data: {
        title: await this.translate.get("invalidCardNumber.title").toPromise(),
        description: await this.translate.get("invalidCardNumber.description").toPromise(),
        button: await this.translate.get("multiplePaymentsTimeInfo.button").toPromise()
      } as DefaultModalModel
    });
  }

}