export const ptBR = {
    invalidRequest: "A requisição é inválida!",
    wrongStatusForOperation: "Este pagamento encontra-se em um status não condizente com a página atual.",
    anErrorWasOcurred: "Ocorreu um erro ao processar sua requisição.",
    goBack: "Voltar",
    goNext: "Prosseguir",
    pleaseWait: "Por favor, aguarde um momento...",
    Voucher: "Voucher de Desconto",
    Installment: "Parcelamento",
    PaymentMethod: "Forma de Pagamento",
    inputCoupounCode: "Informe o código do voucher:",
    CouponCodeIsRequired: "Informe o código do voucher para prosseguir.",
    hello: "Olá!",
    littleToComplete: "Falta pouquinho para concluir sua compra!",
    informClientDocument: "Para começar, informe seu CPF:",
    applyVoucher: "Aplicar Voucher",
    removeVoucher: "Cancelar Voucher",
    inputCardData: "Informar Dados do Cartão",
    noCouponApplied: "Nenhum Voucher Aplicado",
    gotCoupon: "Eu Tenho um voucher!",
    dontGotCoupon: "Eu não tenho um voucher.",
    checkout: "Finalizar Compra",
    PaymentFinalizedSuccessul: "Pagamento Finalizado com sucesso!",
    CreditCardTitleName: "Nome Impresso no Cartão",
    CreditCardNumber: "Número do Cartão",
    CreditCardValidity: "Data de Validade",
    CREDITCARDCVV: "CVC / CVV",
    CREDITCARDDATA: "Dados do Cartão",
    SELECTPORTIONQUANTITY: "Selecione a quantidade de parcelas",
    with: "com",
    ofDiscount: "de desconto",
    couponCodeIsRequired: "Informe o código do voucher para prosseguir.",
    minimunValueNotReached: "O valor mínimo para pagamento não foi atingido.",
    selectInstallment: "(Selecione a quantidade de parcelas)",
    inUntil: "Em Até",
    in: "Em",
    flexibleDiscount: "Desconto Flexível",
    inTheCart: "no carrinho",
    item: "Item",
    items: "Itens",
    termsAndConditions: "Termos e Condições",
    pleaseAcceptTermsToContinueAttention: "Atenção: ",
    pleaseAcceptTermsToContinue: "para prosseguir com o pagamento, é preciso ler e aceitar os termos acima.",
    termsWasAccepted: "Os termos foram aceitos",
    inputCityForMoreLocationResults: "* Caso não encontre sua unidade na lista acima, tente digitar a cidade, nome da unidade ou o CEP para refinar a busca.",
    waitMapPermission: "Aguardando sua permissão para acessar a localização e assim poder exibir as unidades mais próximas a você...",
    reachedPromotions: "Promoções atingidas",
    summary: "RESUMO",
    subTotal: "Subtotal",
    exclusiveSaleCampaignInformation: "Promoções das quais você foi exclusivamente selecionado por atender critérios cuidadosamente definidos.",
    notExclusiveSaleCampaignInformation: "Promoção aplicada nos itens do seu carrinho.",
    fidelityDiscounts: "Descontos fidelidade",
    notEnoughPoints: "Você não possui pontos fidelidade para essa promoção.",
    payment: "PAGAMENTO",
    paymentOptions: "Opções de Pagamento",
    acceptTerms: "Termos de Aceite",
    readAndAccepted: "Declaro que li e aceito os",
    selectedInstallments: "Forma de Parcelamento Selecionada",
    selectGiftItem: "Selecione ao menos uma cortesia para continuar.",
    giftItemMaxQuantityReached: "Você não pode selecionar mais do que {0} cortesia(s) para a promoção {1}",
    claimGiftItems: "Resgatar cortesias",
    doNotClaimGiftItems: "Não desejo resgatar",
    giftItems: "Cortesias",
    wishToInformAffiliate: "Código do Influenciador",
    informAffiliate: "Informe o Código do Influenciador",
    apply: "Aplicar",
    affiliateIsRequired: "Informe o Código do Influenciador para prosseguir.",
    affiliateNotFound: "Influenciador não encontrado",
    remove: "Remover",
    youCanChoose: "Você pode escolher",
    prizes: "prêmios",
    chooseGiftCampaign: "Selecione os prêmios que deseja resgatar",
    iWishToClaim: "Desejo resgatar",
    confirmation: "Confirmação",
    moveFoward: "Seguir mesmo assim",
    noGiftItemSelected: "Nenhuma cortesia foi selecionada. Deseja fazer o resgate agora ou finalizar a compra?",
    restrictAffiliateOrganizationalStructure: "Que pena, a sua unidade de preferência não trabalha com o programa de afiliados, selecione outra unidade para continuar.",
    selectEstablishment: "Selecione uma unidade para continuar",
    sorry: "OPS, FOI MAL...",
    pageNotFound: "A página que você acessou não foi encontrada, acho que sabemos o porquê.",
    pageNotFoundBrowserAddress: "Se você digitou o endereço direto no navegador, por favor checar se foi digitado corretamente.",
    pageNotFoundLink: "Se você clicou em algum lugar para chegar aqui o link pode estar desatualizado.",
    pageNotFoundWhatCanBeDone: "O que pode ser feito?",
    pageNotFoundRedirect: "Sem problemas! Tem várias maneiras de voltar para o e-commerce da {{clientName}}.",
    goBackToPreviousPage: "para a página anterior.",
    useSearch: "Use a busca no topo da página para encontrar novas áreas de depilaçao no site.",
    youCanUseThoseLinks: "Você pode usar um desses links também!",
    homePage: "Página principal",
    myAccount: "Minha conta",
    avaliablePromotions: "Promoções disponíveis",
    achivedCampaignsUserMsg: "<p>Parabéns! Uma ou mais promoções foram atingidas. Você tem cortesias para resgatar.</p> <br> <p> Promoções: </p> {0}",
    achivedCampaignsUserMsgTitle: "Promoção Atingida",
    invalidCard: "Revise o número do cartão. Possivelmente ocorreu um erro de digitação.",
    noAffiliateApplied: "Nenhum Selecionado",
    speakEstablishment: "Fale com a Loja",
    waitProcessOrder: "Por favor, aguarde alguns minutos enquanto seu pedido é processado para fazer o agendamento através do nosso aplicativo.",
    paymentAprovedScheduller : "O seu pagamento foi aprovado. Você pode agendar agora a sua sessão clicando em agendar sessão ou pode fazer o agendamento através do nosso aplicativo.",
    haveVoucher: "Possui cupom?",
    paymentWays: "Formas de pagamento",
    paymentCondition: "Condição de pagamento",
    promotionalItemsDescription: "Itens promocionais não acumulam desconto.",
    sessionScheduller: "Agendar Sessão",
    descriptionOrderNumber: "O número do seu pedido é:",
    selectPaymentMethod: "Por favor, selecione uma forma de pagamento para finalizar o pedido.",
    stepRegister: "Cadastro",
    stepIdentification: "Identificação",
    stepStore:"Loja",
    stepPayment: "Pagamento",
    stepSummary: "Resumo",
    discountFrom: "Desconto de",
    total:"Total",
    totalToBePaid:"Total a ser pago",
    inThe:"no",
    confirm: "Confirmar",
    unit:"Unidade",
    currency:"BRL",
    currencySymbol:"R$",
    scheduleSession: "Agendar sessão",
    dateFormat: "MM/AA",
    new: "Novo",
    and: "e",
    totalDiscount: "Desconto de",
    useRecurrent: "Pagar com crédito recorrente",
    close: 'Fechar',
    voucherUsed: 'Voucher utilizado',
    gift: 'Cortesia',
    masculine: 'Masculino',
    feminine: 'Feminino',
    healthProfileMessage: 'Faça agora o seu Perfil de Saúde e adiante seu primeiro atendimento nas unidades.',
    doHealthProfile: 'Fazer Perfil de Saúde',
    pix: {
        pix: 'Pix',
        payUntil: 'Pagar em até',
        doubts: 'Dúvidas?',
        followInstructions: 'Siga essas instruções',
        copyAboveCode: 'Copie o código acima.',
        goToBankingApp: 'Vá no aplicativo do seu banco ou internet banking.',
        chosePixAndpasteCode: 'Escolha pagamento PIX e cole o código.',
        purchaseWillBeAproved: 'Segundos após finalizar o pagamento, sua compra será aprovada aqui.',
        totalPayment: 'Pagamento total',
        pixCode: 'Código PIX',
        copyPixCode: 'Copiar código PIX',
        hideQRCode: 'Ocultar QR Code',
        showQRCode: 'Mostrar QR Code',
        copiedToClipboard: 'Copiado para área de transferência.',
        myOrdersDescription: 'Você também pode copiar o código pix na área de “Meus Pedidos” no nosso site.',
        generated: 'Código gerado.',
        in: 'em',
        withPix: 'no Pix',
        pixPaymentValue: 'Valor a pagar no PIX',
        changePaymentMethod: 'Alterar forma de pagamento'
    },
    creditCard:{
        creditCard: 'Cartão de Crédito',
        installmentsNumber: 'Número de Parcelas',
        cvv: 'CVC / CVV',
        outOf: 'de',
        details: 'Detalhes',
        istallments: 'Parcelas',
        cancel: 'Cancelar',
        choosePaymentCondition: 'Selecione...',
        from: 'a partir de',
        until: 'em até',
        withCard: 'no cartão',
        discountsUpTo: 'descontos de até',
        fillCardDetails: 'Preencha os dados do cartão',
        inCreditCard : "1x <span>de</span>",
    },
    done: {
      title: 'Pedido realizado com sucesso!',
      processing: 'Processando pedido',
      backToHome: "Voltar para Home",
      whatsAppLink: "https://wa.me/{0}?text={1}"
    },
    donePartial: {
      title: 'Feito!',
      subtitle: {
        first: 'Seu pagamento com',
        middle: 'Cartão de Crédito',
        last:'foi realizado com sucesso.',
      },
      description:{
        first: 'Realize agora o pagamento via',
        last:'pix:',
      },
      waitingConfirmation: 'Aguardando confirmação',
      processing: 'Aguarde um momento, estamos processando o seu pagamento.',
      button: 'Pagar agora'
    },
    previousPayments:{
        title: 'Pagamentos anteriores',
        description: 'Encontramos alguns pagamentos já feitos deste orçamento:',
        discountDescription: 'Tendo um desconto de:',
        footerDescriptionFirst: 'Não se preocupe. O valor restante a ser pago',
        footerDescriptionLast: 'para refletir os pagamentos anteriores com os respectivos descontos.',
        alreadyAdjusted: 'já foi ajustado',
        close: 'Fechar',
        alreadyPaid: 'Você já pagou:'
    },
    wrongVersion:{
        title: 'Proposta atualizada',
        description: 'Notamos que a sua proposta foi atualizada, será necessário atualizar a página.'
    },
    cvvTooltip: {
        title: 'O que é CVC ou CVV?',
        subtitle: 'Segurança',
        description: 'O CVV ou CVC é um código numérico de 3 ou 4 dígitos que normalmente está localizado no verso do seu cartão de crédito.'
    },
    paymentError: {
        title: 'Falha no pagamento',
        description: 'Desculpe, houve um problema ao processar seu pagamento com o cartão de crédito.',
        pleaseCheckYourCardData: 'Por favor, verifique as informações do cartão ou tente uma forma de pagamento diferente.'
    },
    recurrentInfo: {
      title: 'Crédito recorrente',
      button: 'FECHAR'
    },
    pixMinInstallmentsInfo:{
      title: 'ATENÇÃO',
      description: 'O valor restante no crédito após o parcelamento não atingiu o valor mínimo por parcela permitido.',
      subDescription: 'Recomendamos revisar o valor a ser pago no PIX para garantir uma distribuição adequada do valor.',
      button: 'FECHAR'
    },
    pixMinValueInfo:{
      title: 'ATENÇÃO',
      description: 'O valor R${pixValue} inserido para pagamento via PIX é inferior ao mínimo permitido.',
      subDescription: 'Por favor, insira um valor igual ou superior a R${minPixValue} para prosseguir com a transação.',
      button: 'FECHAR'
    },
    multiplePaymentsTimeInfo:{
      title: 'ATENÇÃO',
      description: 'Pagamentos com múltiplas formas não estão disponíveis das 23:00 às 00:00.',
      subDescription: 'Por favor, escolha outra forma de pagamento ou retorne mais tarde.',
      button: 'FECHAR'
    },
    changePaymentMethod: {
      title: 'Alterar forma de pagamento',
      description: 'Todo o pagamento atual será cancelado e você deverá iniciar novamente.',
      subDescriptionFirst: 'Atenção!',
      subDescriptionSecond: 'O valor',
      subDescriptionThird: 'já processado no cartão de crédito pode demorar alguns dias para compensação.',
      subDescriptionFourth: 'Em caso de dúvidas fale com a loja.',
      extraDescription: 'Tem certeza que deseja cancelar o pagamento e iniciar novamente?',
      confirmButton: 'Confirmar',
      cancelButton: 'Cancelar'
    },
    multiplePaymentsConfirm: {
      titleCredit: 'Pix + Crédito',
      titleRecurrent: 'Pix + Crédito Recorrente',
      descriptionFirst: 'Atenção!',
      descriptionSecond: 'Em caso de desistência no processo a compensação bancária pode levar alguns dias para acontecer.',
      subDescription: 'Tem certeza que deseja continuar com duas formas de pagamento?',
      confirmButton: 'Confirmar',
      cancelButton: 'Cancelar'
    },
    invalidCardNumber: {
      title: 'Cartão inválido',
      description: 'Por favor, revise o número do seu cartão e tente novamente'
    },
    voucher: {
      voucherApplied: 'Voucher aplicado com sucesso em'
    }
}
