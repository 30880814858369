import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-change-payment-method-modal',
  templateUrl: './change-payment-method-modal.component.html',
  styleUrls: ['./change-payment-method-modal.component.scss']
})
export class ChangePaymentMethodModalComponent implements OnInit {
  constructor(
    public dialogRef: MatDialogRef<ChangePaymentMethodModalComponent>,
    public translate: TranslateService) {
  }

  async ngOnInit() {
  }
}
