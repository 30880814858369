import { Component, OnInit } from '@angular/core';
import { PaymentService } from 'src/app/services/payment.service';
import { UiService } from 'src/app/services/ui.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-zero-value-payment',
  templateUrl: './zero-value-payment.component.html',
  styleUrls: ['./zero-value-payment.component.scss']
})
export class ZeroValuePaymentComponent implements OnInit {
  total: number;
  giftItemsResolved: boolean = false;
  requireGiftItemResolve: boolean = false;
  giftItemCampaigns: any[];

  constructor(
    private paymentService: PaymentService,
    private uiService: UiService,
    private router: Router) { }

  ngOnInit(): void {
    this.paymentService.dataChangedEvent.subscribe((e) => {
      
      this.total = e.data.order.totalAmount;
      this.requireGiftItemResolve = e.data.order.requireGiftItemResolve;
      this.giftItemCampaigns = e.data.order.giftItemCampaigns;

      if (e.event == 'finalizeZeroValuePayment' && e.data.order.status == 6) {
        this.router.navigate([ `/done/${e.data.order.externalCode}` ]);
      }
      if(e.event == 'saveGiftItems'){
        this.giftItemsResolved = true;
      }
    });
  }

  async finalize(){

    if (!this.uiService.termsAccepted) {
      this.uiService.uiChangedEvent.emit('finalizeWithoutTerms');
      return;
    } else {
      this.uiService.uiChangedEvent.emit('finalizeWithTerms');
    }

    if(this.giftItemCampaigns && this.giftItemCampaigns.length > 0){
        var appliedGifts = await this.paymentService.checkForAppliedGifts();
        if(!appliedGifts){
          var response = await this.uiService.showMessage({
            title: await this.uiService.translate('confirmation'),
            icon: "question",
            showCancelButton: true,
            focusConfirm: false,
            confirmButtonText: await this.uiService.translate('iWishToClaim'),
            cancelButtonText: await this.uiService.translate('moveFoward'),
            html: await this.uiService.translate('noGiftItemSelected'),        
          });
          if(!response.value){
            this.paymentService.finalizeZeroValuePayment();
          }
      }else{
        await this.paymentService.finalizeZeroValuePayment();    
      }
    }else{
      await this.paymentService.finalizeZeroValuePayment();    
    }



    
  }

}
