<section class="sec-checkout pagamento" [style.display]="isLoading ? 'none': 'block'">
    <div *ngIf="!showCreditCardData && !showPixData && !waitingPixProceed && !showPartialDoneData" class="payment-link-container">
        <div class="grid grid-payment-link">
            <app-summary class="col-resumo"></app-summary>
        </div>
        <div class="grid grid-payment-link">
            <app-select-payment-method (changePaymentMethodEvent)="paymentMethodChangeEvent($event)">
            </app-select-payment-method>
        </div>
    </div>

    <div *ngIf="waitingPixProceed">
        <app-pix-confirmation [data]="data" (finalize)="proceed()"></app-pix-confirmation>
    </div>

    <div *ngIf="showCreditCardData && paymentMethod">
        <app-credit-card [paymentMethod]="paymentMethod" [data]="data" [showMultiplePayments]="showMultiplePayments" [multiplePayments]="multiplePayments"></app-credit-card>
    </div>

    <div *ngIf="showPartialDoneData">
        <app-partial-done></app-partial-done>
    </div>

    <div *ngIf="showCreditCardData && !waitingPixProceed">
        <app-payment-summary [data]="data" (back)="goBackToPaymentMethods($event)"></app-payment-summary>
    </div>

    <div *ngIf="waitingPixProceed && !showCreditCardData">
        <app-payment-summary (back)="goBackToPaymentMethods($event)" *ngIf="data" [data]="data" [bypassFormValidation]="true" (proceed)="proceed()"></app-payment-summary>
    </div>

</section>

<div *ngIf="showPixData">
    <app-pix
        [transactionId]="pixTransactionId"
        [waitingPixProceed]="waitingPixProceed"
        [externalCode]="externalCode"
        [isElos]="true"
        [serverTime]="serverTime"
        [partiallyPaid]="partiallyPaid"></app-pix>
</div>
