import { Component, OnInit } from '@angular/core';
import { trigger, state, style, transition, animate } from '@angular/animations';
import { UiService } from 'src/app/services/ui.service';
import { PaymentService } from 'src/app/services/payment.service';

@Component({
  selector: 'app-salesman',
  templateUrl: './salesman.component.html',
  styleUrls: ['./salesman.component.scss'],
  animations: [
    trigger('openClose', [
      // ...
      state('open', style({
        height: '*',
        display: 'block',
        opacity: 1
      })),
      state('closed', style({
        height: '0px',
        padding: '0px',
        display: 'none',
        opacity: 0
      })),
      transition('open <=> closed', [
        style({ display: 'block', opacity: 0.5 }),
        animate('0.2s')
      ])
    ]),
  ]
})
export class SalesmanComponent implements OnInit {

  ready: boolean = false;

  minimizeForm: boolean = true;

  salesmanId: any;

  validated: boolean = false;

  constructor(
    private uiService: UiService,
    private paymentService: PaymentService
  ) { }

  async ngOnInit() {
    try {
      this.paymentService.dataChangedEvent.subscribe(async (e) => {
        this.ready = true;
      });

      this.uiService.uiChangedEvent.subscribe((eventName) => {

        if (eventName == "cardOpen") {
          this.setMinimizeForm(true);
        }
      });


    }
    catch (e) {
      await this.uiService.showError(e);
    }
  }

  expandAccordion() {
    this.setMinimizeForm(!this.minimizeForm);
  }

  setMinimizeForm(value) {
      this.minimizeForm = value;

      if (this.minimizeForm) {
        this.uiService.sendUiEvent("salesmanClosed");
      } else {
        this.uiService.sendUiEvent("salesmanOpen");
      }    
  }


  async applySalesman(){
    this.validated = await this.paymentService.saveSalesman(this.salesmanId);
    if(!this.validated){
      this.uiService.showToastr('Vendedor não encontrado', 'Ops...', 'warning');    
    }else{
      this.setMinimizeForm(true);
    }
  }
}
