import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { OrganizationalStructure } from '../model/organizationalStructure';
import { BusinessError } from '../helpers/BusinessError';
import { BaseService } from './base/base.service';
import { TransferStateService } from './transfer-state.service';

@Injectable({
  providedIn: 'root'
})
export class EstablishmentService extends BaseService {

  constructor(public http: HttpClient,
    public transferState: TransferStateService) {
      super(http, null, transferState);
      this.baseUrl = environment.serviceEstablishmentUrl;
  }

  async getEstablishment(id: number, filterAffiliateAllowedEstablishments: boolean = false): Promise<OrganizationalStructure> {
    const data = await this.get(`/GetEstablishment/${id}/${filterAffiliateAllowedEstablishments}`, true);

    if (data.error) {
      throw new BusinessError(data.message);
    }

    return data.model;
  }

  async getEstablishments(lat: number, lng: number, filterAffiliateAllowedEstablishments: boolean = false): Promise<OrganizationalStructure[]> {
    const data = await this.get(`/GetEstablishments?lat=${lat}&lng=${lng}&filterAffiliateAllowedEstablishments=${filterAffiliateAllowedEstablishments}`, true);

    if (data.error) {
      throw new BusinessError(data.message);
    }

    return data.list;
  }

  async getEstablishmentsByTerms(filterText: string, filterAffiliateAllowedEstablishments: boolean = false): Promise<OrganizationalStructure[]> {
    const data = await this.get(`/GetEstablishments?filterText=${filterText}&filterAffiliateAllowedEstablishments=${filterAffiliateAllowedEstablishments}`, true);

    if (data.error) {
      throw new BusinessError(data.message);
    }

    return data.list;
  }
}
