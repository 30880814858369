import { Component, OnInit, Input } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { PaymentConditionModel } from 'src/app/model/payments/payment-condition.model';
import { PaymentLinkService } from 'src/app/services/payment-link.service';

export interface CreditCardDisplayModel
{
  name: string;
  validity: string;
  cardDigits: string[];
  brandImage: string;
}

@Component({
  selector: 'app-credit-card-display',
  templateUrl: './credit-card-display.component.html',
  styleUrls: ['./credit-card-display.component.scss']
})
export class CreditCardDisplayComponent implements OnInit {


  @Input() data: any;

  paymentConditionSelected: PaymentConditionModel;
  
  model: CreditCardDisplayModel = {
    cardDigits: [],
    name: null,
    validity: null,
    brandImage: ''
  };

  showCard: boolean = false;

  constructor(
    private paymentLinkService: PaymentLinkService,
    public translate: TranslateService) { }

  ngOnInit(): void {
    this.paymentLinkService.creditCardData.subscribe(sub =>{
      if(sub){
          if(sub.firstSix &&
            sub.lastFour &&
            sub.name &&
            sub.validity){
            this.buildModel(sub);
          }else{
            this.model = {
              cardDigits: [],
              name: null,
              validity: null,
              brandImage: ''
            };
            this.showCard = false;
          }
        }
    });

    this.paymentLinkService.paymentConditionChanged.subscribe((pc: PaymentConditionModel) => {
      this.paymentConditionSelected = pc;
    });
  }

  buildModel(model){
    this.buildCreditCardNumberDisplay(model);
    this.model.name = model.name;
    this.model.validity = model.validity;
    this.showCard = true;
  }


  buildCreditCardNumberDisplay(model){
    this.model.cardDigits = [];
    let firstSix = model.firstSix;
    let firstFour = firstSix.substring(0, 4);
    let skipFour = firstSix.substring(4);

    this.model.cardDigits.push(firstFour);
    this.model.cardDigits.push(`${skipFour}**`);
    this.model.cardDigits.push('****');
    this.model.cardDigits.push(model.lastFour);
    this.model.brandImage = model.brandImage;
  }
}
