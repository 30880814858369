import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, Observer } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Injectable({
    providedIn: 'root'
})
export class ElosgateApiService {

  public baseUrl: string;
  public urlSitef: string;
  public ambiente: Number = 0;

  public versaoApp = "2.4";
  public _accessKey: string;

  public mensagemErroTimeOut = "Não foi possível obter uma resposta do servidor. Verifique seu acesso a internet e tente novamente.";

  constructor(private http: HttpClient) {}

  setAmbiente(n: Number){
      switch(n){
          case 0: //Produção
              this.baseUrl = 'https://svcgateway-prd.azurewebsites.net';
              this.urlSitef="https://esitef-ec.softwareexpress.com.br/e-sitef/api/v1/";
              break;
          case 1: //Homologação
              this.baseUrl = 'https://svc-hom.elosgate.com.br';
              this.urlSitef="https://esitef-homologacao.softwareexpress.com.br/e-sitef/api/v1/";
              break;
          case 2: //Teste
              this.baseUrl = 'https://svc-hom.elosgate.com.br';
              this.urlSitef="https://esitef-homologacao.softwareexpress.com.br/e-sitef/api/v1/";
              break;
          case 3: //Pré Produção
              this.baseUrl = 'https://svcgateway-prd-svcgateway-preprod.azurewebsites.net';
              this.urlSitef="https://esitef-ec.softwareexpress.com.br/e-sitef/api/v1/";
              break;
          case 4: //Local Simulado
              this.baseUrl = "http://localhost:10282";
              this.urlSitef = "https://esitef-homologacao.softwareexpress.com.br/e-sitef/api/v1/";
              break;
          case 5: //Local Produção
              this.baseUrl = "http://localhost:10282";
              this.urlSitef = "https://esitef-ec.softwareexpress.com.br/e-sitef/api/v1/";
              break;
          default: //Produção
              this.baseUrl = 'https://svcgateway-prd.azurewebsites.net';
              this.urlSitef="https://esitef-ec.softwareexpress.com.br/e-sitef/api/v1/";
      }
      this.ambiente = n;
  }

  execute(
      service: string,
      action: string,
      parameter: any
  ): Observable<any> {
      return Observable.create((observer: Observer<any>) => {
          const url =
              this.baseUrl +
              (service === "Smart" ? "/Smart/" : "/Generated/") +
              service +
              "SVC.svc/json/" +
              action;
          if (this._accessKey) parameter.CodigoAcesso = this._accessKey;
          parameter.VC = this.versaoApp;
          this.http.post(url, parameter).subscribe(
              (response: any) => {
                  const data = response;

                  if (data.Errors.length === 0) {
                      delete data.Errors;

                      observer.next(data);
                  } else {
                      const errors: string[] = [];

                      for (const error of data.Errors) {
                          errors.push(error.Description);
                      }

                      observer.next({ Errors: errors });
                  }
              },

              (error) => {
                  if (error.name == 'TimeoutError') {
                      observer.next({

                          Errors: [this.mensagemErroTimeOut]
                      });
                  }
                  else {
                      observer.next({
                          Errors: ["Serviço indisponível ou requisição inválida."]
                      });
                  }
              }
          );
      });
  }
  executeSitef(
      action: string,
      parameter: any,
      merchant_id: string,
      valorHeader: string,
      store: boolean
  ): Observable<any> {
      return Observable.create((observer: Observer<any>) => {
          const url =
              this.urlSitef +
              action;
          let metodo = 'post';
          let headers = new HttpHeaders({
              'Content-Type': 'application/json',
              'merchant_id': merchant_id,
              'pay_token': valorHeader,
              'Accept': 'application/json'
          });
          if (store) {
              headers = new HttpHeaders({
                  'Content-Type': 'application/json',
                  'merchant_id': merchant_id,
                  'store_token': valorHeader,
                  'Accept': 'application/json'
              });
              metodo = 'put';
          }
          //if(store){ observer.next("") ;return;} ;
          this.http.request(metodo, url, { headers: headers, body: parameter }).subscribe(
              (response: any) => {
                  const data = response;
                  observer.next(data);

              },

              (error) => {
                  if (error.name == 'TimeoutError') {
                      observer.next({

                          Errors: [this.mensagemErroTimeOut]
                      });
                  }
                  else{
                      let message="Serviço indisponível ou requisição inválida."
                      if(error && error.error && error.error.message)
                          message=error.error.message;
                      if(!store){
                          if( error.error.payment && error.error.payment.authorizer_message)
                              message=error.error.payment.authorizer_message
                      }
                      observer.next({

                          Errors: [message]
                      });

                  }
              }
          );
      });
  }
  
  consultarAutorizadora(id: string, cartao: string, cobranca: boolean, modifiedAt) {
      const parameter: any = {
          TransacaoID: id,
          Numero: cartao.substr(0, 6),
          Digitos: cartao.length,
          Cobranca: cobranca,
          ModifiedAt: modifiedAt
      };
      return this.execute("gateway", "ConsultarAutorizadora", parameter).pipe(
          map((response: any) => {
              if (!response.Errors) {
                  return { data: response, error: null };
              } else {
                  return { error: response.Errors[0] };
              }
          })
      );
  }

  criarTransacaoStorage(id: string, autorizadoraId: number, titular: string, oMesmo: boolean, cobrancaID: string, vencimento: string, tipoAcaoCobranca: number, final: string, numeroDeParcelasAntecipadas?) {
      const parameter: any = {
          TransacaoID: id,
          AutorizadoraID: autorizadoraId,
          Titular: titular,
          OutroTitular: !oMesmo,
          CobrancaID: cobrancaID,
          Vencimento: vencimento,
          TipoAcaoCobranca: tipoAcaoCobranca,
          Final: final,
          NumeroDeParcelasAntecipadas: numeroDeParcelasAntecipadas
      };

      return this.execute("gateway", "CriarTransacaoStorage", parameter).pipe(
          map((response: any) => {
              if (!response.Errors) {
                  return { data: response, error: null };
              } else {
                  return { error: response.Errors[0] };
              }
          })
      );
  }

  sendSitef(transacaoID: string, nita: string, merchant_id: string, store_token: string, authorizer_id: string, number: string, expiry, paytoken: string, cvv: string, holder: string, nit: string) {
      if (paytoken) {
          const parameter: any = {
              "authorizer_id": authorizer_id,
              "card": {
                  "number": number,
                  "expiry_date": expiry,
                  "security_code": cvv,
                  "holder": holder
              }



          };
          return this.executeSitef("payments/js/" + nit, parameter, merchant_id, paytoken, false).pipe(
              map((response: any) => {
                  if (!response.Errors) {
                      return { data: response, error: null };
                  } else {
                      this.registarHistoricoApp(transacaoID, 'payment', "Erro no payments. BIN: " + number.substr(0, 6) + " - AutorizerID: " + authorizer_id + " Mensagem: " + response.Errors[0]).subscribe();
                      return { error: response.Errors[0] };
                  }
              })
          );
      }
      else {
          const parameter: any = {
              "authorizer_id": authorizer_id,
              "card": {
                  "number": number,
                  "expiry_date": expiry
              }
          };

          return this.executeSitef("store/" + nita, parameter, merchant_id, store_token, true).pipe(
              map((response: any) => {
                  if (!response.Errors) {
                      return { data: response, error: null };
                  } else {
                      this.registarHistoricoApp(transacaoID, 'store', "Erro no store.  BIN: " + number.substr(0, 6) + " - AutorizerID: " + authorizer_id + " Mensagem: " + response.Errors[0]).subscribe();
                      return { error: response.Errors[0] };
                  }
              })
          );
      }
  }

  confirmarTransacao(id: string, nita: string, trocarCartao = false, trocarFuturas = false, modifiedAt, origem: string) 
  {
      const parameter: any = {
          ID: id,
          Nita: nita,
          TrocarFuturas: trocarFuturas,
          ModifiedAt: modifiedAt,
          Origem: origem
      };
      let metodo = "ConfirmarTransacao";
      if (trocarCartao)
          metodo = "TrocarCartao";
      return this.execute("gateway", metodo, parameter).pipe(
          map((response: any) => {
              if (!response.Errors) {
                  return { data: response, error: null };
              } else {
                  return { error: response.Errors[0] };
              }
          })
      );
  }

  registarHistoricoApp(id: string, tipo: string, descricao: string) {
      const parameter: any = {
          TransacaoID: id,
          Tipo: tipo,
          Descricao: descricao

      };
      return this.execute("gateway", "RegistarHistoricoApp", parameter).pipe(
          map((response: any) => {
              if (!response.Errors) {
                  return { data: response, error: null };
              } else {
                  return { error: response.Errors[0] };
              }
          })
      );
  }

    storeToken(obj: any) {
        const url = environment.elosgate_storetoken_api;

        var result = this.http.post(url, obj).pipe(
            map((response: any) => {
                if (!response.error) {
                    return { data: response, error: null };
                } else {
                    return { error: response.error };
                }
            })
        )

        return result;
    }

    processarCartaoViaToken(obj: any) {
        const url =
            this.baseUrl +
            ("/Generated/") +
            "gateway" +
            "SVC.svc/json/" +
            "ProcessarCartaoViaToken";

        var result = this.http.post(url, obj).pipe(
            map((response: any) => {
                if (!response.Errors || response.Errors.length === 0) {
                    return { data: response, error: null };
                } else {
                    return { error: response.Errors[0].Description };
                }
            })
        )
        return result;
    }

    gerarCobrancaPix(obj: any) {
        const url =
            this.baseUrl +
            ("/Generated/") +
            "gateway" +
            "SVC.svc/json/" +
            "gerarCobrancaPix";

        var result = this.http.post(url, obj).pipe(
            map((response: any) => {
                if (!response.Errors || response.Errors.length === 0) {
                    return { data: response, error: null };
                } else {
                    return { error: response.Errors[0].Description };
                }
            })
        )
        return result;
    }

    verificarPagamentoPix(obj: any) {
        const url =
            this.baseUrl +
            ("/Generated/") +
            "gateway" +
            "SVC.svc/json/" +
            "verificarPagamentoPix";

        var result = this.http.post(url, obj).pipe(
            map((response: any) => {
                if (!response.Errors || response.Errors.length === 0) {
                    return { data: response, error: null };
                } else {
                    return { error: response.Errors[0].Description };
                }
            })
        )
        return result;
    }



}
