<section class="sec-partial-done-elos">
  <div class="grid">
    <div class="partial-done-elos-content">
      <span *ngIf="ready">
        <h1>{{'donePartial.title' | translate}}</h1>

        <div class="div-img-order" *ngIf="displaySvgCheck">
          <svg class="svg-main" width="178" height="110" viewBox="0 0 178 162" fill="none"
            xmlns="http://www.w3.org/2000/svg">

            <circle cx="88" cy="79" r="26" fill="#42D293" class="svg-elem-1"></circle>

            <rect x="111.378" y="59.1309" width="8.34783" height="50.0869" rx="4.17391"
              transform="rotate(45 111.378 59.1309)" fill="white" class="svg-elem-2"></rect>

            <rect x="60.7832" y="79.0737" width="8.34783" height="30.2327" rx="4.17391"
              transform="rotate(-45 60.7832 79.0737)" fill="white" class="svg-elem-3"></rect>

            <path d="M42.6465 38.3535L21.6465 17.3535" stroke="#42D293" stroke-width="4" stroke-linecap="round"
              class="svg-elem-4 svg-elem"></path>

            <path d="M132 38L153 17" stroke="#42D293" stroke-width="4" stroke-linecap="round"
              class="svg-elem-5 svg-elem">
            </path>

            <path d="M132.354 126L153.354 147" stroke="#42D293" stroke-width="4" stroke-linecap="round"
              class="svg-elem-6 svg-elem"></path>

            <path d="M153 80H176" stroke="#42D293" stroke-opacity="0.5" stroke-width="4" stroke-linecap="round"
              class="svg-elem-7 svg-elem"></path>

            <path d="M2 80H25" stroke="#42D293" stroke-opacity="0.5" stroke-width="4" stroke-linecap="round"
              class="svg-elem-8 svg-elem"></path>

            <path d="M90 17V2" stroke="#42D293" stroke-opacity="0.5" stroke-width="4" stroke-linecap="round"
              class="svg-elem-9 svg-elem"></path>

            <path d="M90 160V145" stroke="#42D293" stroke-opacity="0.5" stroke-width="4" stroke-linecap="round"
              class="svg-elem-10 svg-elem"></path>

            <path d="M44 128.354L23 149.354" stroke="#42D293" stroke-width="4" stroke-linecap="round"
              class="svg-elem-11 svg-elem"></path>

          </svg>
        </div>

        <p class="subtitle">
          {{'donePartial.subtitle.first' | translate}}
          <span class="subtitle-middle">{{'donePartial.subtitle.middle' | translate}}</span>
          {{'donePartial.subtitle.last' | translate}}
          <br />
        </p>

        <div class="partial-done-divider"></div>

        <div class="description" [ngStyle]="{'opacity': !ready ? '0.5' : '1' }">
          <p>
            {{'donePartial.description.first' | translate}}
            <span class="description-last">{{'donePartial.description.last' | translate}}</span>
          </p>
        </div>

        <button type="button" [disabled]="!ready" class="button btn" (click)="confirm()">
          <span>
            <p>{{'donePartial.button' | translate}}</p>
          </span>
        </button>
      </span>

      <div *ngIf="!ready" class="description">
        <h1>{{'donePartial.waitingConfirmation' | translate}}</h1>
        <div class="div-img-waiting">
          <svg width="94" height="94" viewBox="0 0 94 94" fill="none" version="1.1" xmlns="http://www.w3.org/2000/svg">
            <path d="M 74,47 C 74,61.9117 61.9117,74 47,74 32.0883,74 20,61.9117 20,47 20,32.0883 32.0883,20 47,20 61.9117,20 74,33.0883 74,47 Z" fill="#003fc3" />
            <path class="svg-waiting" d="m 30,47 c 0,-8.9722 7.1778,-17 17,-17 9.1296,0 17,7.5556 17,17 0,9.4444 -7.5556,17 -17,17" stroke="#ffffff" stroke-width="4" stroke-linecap="round" />
          </svg>
        </div>
        <p>{{'donePartial.processing' | translate}}</p>
      </div>

    </div>
  </div>
</section>
