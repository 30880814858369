<section class="sec-payment-link">
        <h2>PEDIDO REALIZADO COM SUCESSO!</h2>
        <span class="order">Seu pedido é o <span>#{{code}}</span></span>
        <h3>Pedido aguardando pagamento</h3>
        <img style="max-width: 400px;" src="{{payment.qrCode}}"/>
        <p>Aponte a câmera do seu celular para ler o QR Code</p>
        <button type="btn" (click)="sendPaymentLink()" class="button">Enviar Link por E-mail</button>
        <p>Após realizar o pagamento do pedido você receberá um e-mail com a confirmação do pagamento e poderá fazer o agendamento através do nosso aplicativo.</p>
        <button class="button" type="btn" (click)="finalize()">Finalizar</button>
</section>
