<section class="sec-parcelas">
    <div class="modal-header">
        <h2>{{'creditCard.istallments' | translate}}</h2>
    </div>
    <div class="installment-list">

        <div *ngFor="let item of paymentConditions">
            <div class="installment-item" (click)="setPaymentMethod(item)">
                <p class="installment-value"><span>{{item.installments}}x {{'creditCard.outOf' | translate}} {{'currencySymbol' | translate }}</span> {{item.installmentValue | currency: translate.instant('currency') : '' :'1.2-2' }}</p>
                <span class="discount-percent" *ngIf="!item.feesPercent && this.showDiscountPercent">{{ item.discountPercent | number : '1.2-2' }}% OFF</span>
                <span class="discount-percent-empty" *ngIf="item.feesPercent || !this.showDiscountPercent"></span>
                <p class="total-value">{{ 'currencySymbol' | translate }} {{ item.total | currency: translate.instant('currency') : '' :'1.2-2' }}</p>
            </div>
            <div class="divider"></div>
        </div>
    </div>
    <div class="modal-footer">
        <button class="btn-cancel" type="button" (click)="cancel()">{{'creditCard.cancel' | translate}}</button>
    </div>

</section>
