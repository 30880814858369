<section [hidden]="!ready || manualApplyCampaigns.length <= 0" class="sec-manual-applied-campaigns">
    <div class="grid">
        <div class="title" (click)="expandAccordion()">
            <!-- TITULO -->
			<span class="icon-gift"><img src="../../../../assets/imagens/icon-discount.png"/></span>
            <h2>{{'fidelityDiscounts' | translate}}</h2>
            <span class="collapse-buttons">
                <span *ngIf="!minimizeForm"><i class="arrow up"></i></span>
                <span *ngIf="minimizeForm"><i class="arrow down"></i></span>
            </span>
        </div>        

        <div [@openClose]="minimizeForm ? 'closed' : 'open'" style="width: 100%;">
            <div *ngFor="let item of manualApplyCampaigns">                            
                <div class="checkbox">
                    <input type="checkbox" id="{{item.salesCampaignId}}" name="{{item.salesCampaignDescription}}" [(ngModel)]="item.isApplied" (change)="updateManualApplyCampaigns(item.isApplied, item.salesCampaignId)" />
                    <label for="{{item.salesCampaignId}}" style="width:100%;">
                        <span matTooltip="{{'exclusiveSaleCampaignInformation' | translate}}" *ngIf="item.isExclusive" class="star"></span>
                        <span matTooltip="{{'notExclusiveSaleCampaignInformation' | translate}}" *ngIf="!item.isExclusive" class="star grey"></span>
                        <p>{{item.salesCampaignDescription}}</p>
                    </label>                        
                </div>   
            </div>            
        </div> 
    </div> 
</section>