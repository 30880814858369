import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { CheckoutCmsService, RecurrentInfoContent } from 'src/app/services/cms/checkoutCms.service';

@Component({
  selector: 'app-recurrent-info',
  templateUrl: './recurrent-info.component.html',
  styleUrls: ['./recurrent-info.component.scss']
})
export class RecurrentInfoComponent implements OnInit {
  content: RecurrentInfoContent;

  constructor(
    public dialogRef: MatDialogRef<RecurrentInfoComponent>,
    private checkoutCmsService: CheckoutCmsService) { }

  async ngOnInit() {
    this.content = await this.checkoutCmsService.recurrentInfoContent();
  }

  closeModal(){
    this.dialogRef.close();
  }
}
