<section class="payment-error-modal">
    <div class="modal-header">
        <img alt="error-icon" src="../../../assets/imagens/icon-payments-recurrent.png"/>
        <h2>{{'recurrentInfo.title' | translate}}</h2>
    </div>

    <div [innerHTML]="content?.informative"></div>

    <button type="button" class="btn-go-back" (click)="closeModal()">{{'recurrentInfo.button' | translate}}</button>
</section>

