import { Component, OnInit, Input, Output, EventEmitter, OnDestroy } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { PaymentLinkService } from 'src/app/services/payment-link.service';

@Component({
  selector: 'app-pix-value-form',
  templateUrl: './pix-value-form.component.html',
  styleUrls: ['./pix-value-form.component.scss']
})
export class PixValueFormComponent implements OnInit, OnDestroy {

  @Input() description: string;
  @Output() pixValueChanged = new EventEmitter<any>();
  @Input() data: any;
  clearPixValueSubscription: Subscription;

  pixValue: any;

  currencySimbol: string;

  discountPercent: any;

  showDiscount: boolean = false;

  maskOptions = {
    prefix: null,
    thousands: '.',
    decimal: ','
  };

  constructor(
    private translateService: TranslateService,
    private paymentLinkService: PaymentLinkService) { }

  async ngOnInit() {
    this.clearPixValueSubscription = this.paymentLinkService.clearPixValue.subscribe(e =>{
      this.pixValue = null;
    });

    this.currencySimbol = `${await this.translateService.get('currencySymbol').toPromise()} `;
    this.maskOptions.prefix = this.currencySimbol;
    let configValue = this.data.order.configurations.filter(w => w.name === 'showDiscountPercentPix')[0]?.value;
    this.showDiscount = configValue.toLowerCase() == 'true';
    
    if(this.showDiscount){
      let method = this.data.methods.filter(w => w.paymentMethod_CreditCardType == 3)[0];
      this.discountPercent = Math.round((method.discountAmount * 100) * 100) / 100;
    }
  }

  ngOnDestroy(){
    this.clearPixValueSubscription.unsubscribe();
    this.clearPixValueSubscription = null;
  }

  pixValueChange(){
    this.pixValueChanged.emit(this.pixValue);
  }
}
