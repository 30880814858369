import { Component, OnInit, Input, Output, EventEmitter, OnDestroy } from '@angular/core';
import { PaymentLinkService } from 'src/app/services/payment-link.service';
import { TranslateService } from '@ngx-translate/core';
import { PaymentMethodModel } from 'src/app/model/payments/payment-method.model';
import { PaymentConditionModel } from 'src/app/model/payments/payment-condition.model';
import { PaymentMethodEnum } from 'src/app/model/payments/payment-method.enum';

@Component({
  selector: 'app-payment-summary',
  templateUrl: './payment-summary.component.html',
  styleUrls: ['./payment-summary.component.scss']
})
export class PaymentSummaryComponent implements OnInit, OnDestroy {

  @Input() bypassFormValidation: boolean = false;

  @Input() data: any;

  @Output() back = new EventEmitter<any>(null);

  @Output() proceed = new EventEmitter<any>(null);

  validateData: boolean = false;
  isFormValid: boolean = false;
  paymentMethodSelected: PaymentMethodModel;
  paymentConditionSelected: PaymentConditionModel;
  paymentMethodEnum = PaymentMethodEnum;
  total: number;
  totalDiscount: number;
  multiplePayments: any[] = [];
  pixValue: any;

  constructor(
    private paymentLinkService: PaymentLinkService,
    public translate: TranslateService) { }

  ngOnInit(): void {
    this.total = this.data.order.totalAmount;
    this.totalDiscount = this.data.order.subtotalAmount - this.data.order.totalAmount;

    this.paymentLinkService.paymentMethodChanged.subscribe((pm: PaymentMethodModel) => {
      if (pm) {
        this.paymentMethodSelected = pm;
        this.setTotal();
        this.validateForm();
      }
    });

    this.paymentLinkService.paymentConditionChanged.subscribe((pc: PaymentConditionModel) => {
      if (pc) {
        this.paymentConditionSelected = pc;
        this.setTotal();
        this.validateForm();
      } else {
        this.paymentConditionSelected = null;
      }
      
    });

    this.paymentLinkService.multiplePaymentsSelected.subscribe(sub => {
      if (sub) {

        this.multiplePayments = [];

        sub.forEach(item => {
          if(item.creditCardType == PaymentMethodEnum.CreditCard || item.creditCardType == PaymentMethodEnum.Pix){
            this.multiplePayments.push(item);
          }
        });
        
        this.multiplePayments.forEach(item => {
          if (this.paymentMethodSelected) {
            if (item.bestDiscount > this.paymentMethodSelected.bestDiscount) {
              this.paymentMethodSelected = item;    
            }
          } else {
            this.paymentMethodSelected = item;
          }
        });

        this.setTotal();
        this.validateForm();
      }
    });

    this.paymentLinkService.multiplePaymentsConditionChanged.subscribe(sub => {
      if(sub){
        this.pixValue = sub.pixPartialValue;
        this.validateForm();
        if(sub.removeCondition){
          this.paymentConditionSelected = null;
        }
        if(sub.pixPartialValue && !sub.removeCondition) {
          this.setMultiplePaymentsTotal();
        }
      }
    });

    if (this.bypassFormValidation) {
      this.isFormValid = true;
    } else {
      this.paymentLinkService.validateData.subscribe(sub => {
        this.validateData = sub;
        this.validateForm();
      });
    }
  }

  validateForm(): boolean{
    var result: boolean = false;
    if(!this.validateData ||
      !this.paymentMethodSelected ||
      !this.paymentConditionSelected ||
      (this.multiplePayments.length > 0 && !this.pixValue))
      result = false;
    else
      result = true;

    this.isFormValid = result;
    return result;
  }

  setTotal() {
    let otherDiscounts = this.paymentLinkService.getOtherDiscounts(this.data.order.payments);
    this.total = (this.paymentConditionSelected?.netValue || this.paymentMethodSelected?.netValue || this.data.order.totalAmount) - otherDiscounts;
    this.totalDiscount = this.data.order.subtotalAmount - this.total;
  }

  setMultiplePaymentsTotal(){
    if (this.paymentConditionSelected) {
      this.total = this.paymentConditionSelected.total + this.pixValue;
      this.totalDiscount = this.data.order.subtotalAmount - this.total;
    }
  }

  goBack(){
    this.back.emit(true);
  }

  confirm(){
    if (this.bypassFormValidation) {
      this.proceed.emit(true);
    } else {
      this.paymentLinkService.finalizePurchaseFromPaymentLink.next(true);
    }
  }

  ngOnDestroy(){
    this.paymentConditionSelected = null;
    this.paymentLinkService.paymentConditionChanged.next(null);
    this.paymentLinkService.finalizePurchaseFromPaymentLink.next(null);
    this.paymentLinkService.validateData.next(false);
    this.paymentLinkService.multiplePaymentsSelected.next(null);
    this.paymentLinkService.multiplePaymentsConditionChanged.next(null);
    this.multiplePayments = [];
  }
}