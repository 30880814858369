import { Injectable } from '@angular/core';
import { BaseService } from './base/base.service';
import { environment } from 'src/environments/environment';
import {
  AppIntegrationService,
  EAppIntegrationPaymentStatus,
} from './app-integration.services';
import { BehaviorSubject } from 'rxjs';
import { ElosgateService } from './elosgate.service';
import { APP_AGE } from '../helpers/constants';

declare var TokenEx: any;

@Injectable({
  providedIn: 'root',
})
export class PaymentExternalService {
  constructor(
    private baseService: BaseService,
    private appIntegrationService: AppIntegrationService,
    private elosgateService: ElosgateService
  ) {}

  configuracaoToken: any = {};
  tokenexLoaded: boolean = false;
  isTokenexFormValid: boolean;
  iframe: any;
  tokenData = new BehaviorSubject<any>(null);
  cardData = new BehaviorSubject<any>(null);

  async getCardCaptureKeys(budgetCode: string) {
    const result = await this.baseService.post('/Payment/GetCardCaptureKeys', {
      budgetCode,
    });
    return result?.response;
  }

  async processPayment(request: any) {
    const result = await this.baseService.post(
      '/Payment/PaymentCreditCard',
      request
    );
    return result?.response;
  }

  async startTokenEx(parameters: any) {
    const response = await this.getCardCaptureKeys(parameters.budgetCode);

    if (response?.error) {
      if (parameters?.appChannel === APP_AGE) {
        this.appIntegrationService.sendToApp(
          EAppIntegrationPaymentStatus.Error
        );
      }
      return;
    }

    this.configuracaoToken = response;

    setTimeout(async () => {
      var scriptElement = this.loadTokenexScript();
      scriptElement.onload = () => {
        this.loadTokenex();
        this.tokenexLoaded = true;
      };
    }, 50);
  }

  tokenize() {
    this.iframe.tokenize();
  }

  private async loadTokenex() {
    this.configuracaoToken.configuracaoToken.enablePrettyFormat = true;

    this.configuracaoToken.configuracaoToken.enableValidateOnBlur = true;

    this.configuracaoToken.configuracaoToken.enableValidateOnCvvKeyUp = true;

    this.configuracaoToken.configuracaoToken.customDataTypes.forEach((item) => {
      item.cvvRequired = true;
    });

    const cssBase =
      'height: 40px; box-sizing: border-box; font-family: Work Sans, sans-serif; border: 1px solid #e3e9e9; border-radius: 8px; font-size:14px; width: 100%; padding: 14px; border-color: #e3e9e9; background-color: #ebf4f7; color: #333333';
    const cssError = 'border: 1px solid rgba(224, 57, 57, 0.5);';
    const cssFocus = 'border: 1px solid gray;outline: 0;';

    this.configuracaoToken.configuracaoToken.styles = {
      base: cssBase,
      error: cssError,
      focus: cssFocus,
      cvv: {
        base: cssBase,
        error: cssError,
        focus: cssFocus,
      },
    };

    this.iframe = new TokenEx.Iframe(
      'tokenExIframeDiv',
      this.configuracaoToken.configuracaoToken
    );

    this.iframe.load();

    this.iframe.on('tokenize', this.onTokenize);

    this.iframe.on('validate', this.onValidate);

    this.iframe.on('error', this.onTokenizeError);
  }

  private loadTokenexScript() {
    var src = environment.tokenexScriptUrl;

    var head = document.getElementsByTagName('head')[0];
    let script = document.createElement('script');
    script.setAttribute('src', src);
    head.appendChild(script);

    return script;
  }

  onTokenize = async (data) => {
    this.tokenData.next(data);
  };

  onValidate = async (data) => {
    this.isTokenexFormValid = data?.isValid && data?.isCvvValid;
    this.cardData.next(data);
  };

  onTokenizeError = async (data) => {
    this.isTokenexFormValid = false;
  };

  async getStoreToken(obj: any) {
    return await this.elosgateService.storeToken(obj);
  }
}
