import { Component, OnInit } from '@angular/core';
import { PaymentService } from 'src/app/services/payment.service';
import { trigger, state, style, transition, animate } from '@angular/animations';
import { UiService } from 'src/app/services/ui.service';
import { TranslateService } from '@ngx-translate/core';
import { LocalStorageService } from 'src/app/services/local-storage.service';

@Component({
  selector: 'app-gift-item',
  templateUrl: './gift-item.component.html',
  styleUrls: ['./gift-item.component.scss'],
  animations: [
    trigger('openClose', [
      // ...
      state('open', style({
        height: '*',
        display: 'block',
        opacity: 1
      })),
      state('closed', style({
        height: '0px',
        padding: '0px',
        display: 'none',
        opacity: 0
      })),
      transition('open <=> closed', [
        style({ display: 'block', opacity: 0.5 }),
        animate('0.2s')
      ])
    ]),
  ]
})
export class GiftItemComponent implements OnInit {
  minimizeForm: boolean = true;
  appliedGifts: any[];
  giftItemCampaigns: any[];
  appliedCampaignsDescription: any[];
  storageKey: string;

  constructor(
    private paymentService: PaymentService,
    private uiService: UiService,
    private translate: TranslateService,
    private localStorage: LocalStorageService) { }

  ngOnInit(): void {

    this.paymentService.dataChangedEvent.subscribe(async (e) => {
      this.giftItemCampaigns = e.data.order.giftItemCampaigns;
      this.appliedGifts = e.data.order.appliedGifts;

      if (this.giftItemCampaigns) {

        this.storageKey = `applyGiftItems-${e.data.order.code}`

        this.checkForAppliedGifts();
      }

    });
  }

  expandAccordion() {
    this.setMinimizeForm(!this.minimizeForm);
  }

  setMinimizeForm(value) {
    this.minimizeForm = value;

    if (this.minimizeForm) {
      this.uiService.sendUiEvent("salesmanClosed");
    } else {
      this.uiService.sendUiEvent("salesmanOpen");
    }
  }


  async processGiftItems(applyGiftItems: boolean) {

    if (applyGiftItems) {
      this.localStorage.setItem(this.storageKey, 'true');
      if (await this.validateGiftItemQuantity()) {
        this.saveGiftItems();
      }
    } else {
      this.localStorage.setItem(this.storageKey, 'false');
      await this.removeGifts();
    }
  }

  async validateGiftItemQuantity() {

    var validated = true;
    var appliedGiftItemCampaign = this.giftItemCampaigns.filter(w => w.isApplied == true);
    var i: any;

    for (i in appliedGiftItemCampaign) {

      var campaignDescription = appliedGiftItemCampaign[i].description;
      var appliedGiftItems = appliedGiftItemCampaign[i].giftItems.filter(w => w.isApplied == true);
      //valida se ao menos uma cortesia foi selecionada
      if (appliedGiftItems.length <= 0) {
        this.uiService.showToastr(await this.translate.get("selectGiftItem").toPromise(), 'Ops', 'warning');
        return false;
        //valida se a quantidade de cortesias selecionadas está dentro da quantidade permitida na campanha
      } else if (appliedGiftItems.length > appliedGiftItemCampaign[i].giftItems[0].quantityToBeGranted) {
        var message1 = await this.translate.get("giftItemMaxQuantityReached").toPromise();
        var message2 = message1.replace('{0}', appliedGiftItemCampaign[i].giftItems[0].quantityToBeGranted);
        var message = message2.replace('{1}', campaignDescription);
        this.uiService.showToastr(message, 'Ops', 'warning');
        return false;
      }
    }

    return validated;
  }

  async saveGiftItems() {

    var request = [];
    var i: any;

    var appliedCampaigns = this.giftItemCampaigns.filter(w => w.isApplied == true);

    for (i in appliedCampaigns) {

      var appliedGifItems = appliedCampaigns[i].giftItems.filter(w => w.isApplied == true);

      appliedGifItems.forEach(item => {
        request.push({ salesCampaignId: appliedCampaigns[i].campaignId, itemId: item.id, quantity: item.quantity })
      });
    }

    await this.paymentService.saveGiftItems(request);
  }

  checkForAppliedGifts() {
    this.clearAllGifts();
    if (this.appliedGifts.length > 0) {

      var i: any;
      this.appliedCampaignsDescription = [];

      for (i in this.appliedGifts) {

        var campaignId = this.appliedGifts[i].salesCampaignId;
        var campaign = this.giftItemCampaigns.filter(w => w.campaignId == campaignId)[0];

        if (campaign) {

          if (this.appliedCampaignsDescription.includes(campaign.description) == false) {
            this.appliedCampaignsDescription.push(campaign.description);
          }

          campaign.isApplied = true;

          campaign.giftItems.forEach(item => {
            if (this.appliedGifts.filter(e => e.itemId === item.id).length > 0) {
              item.isApplied = true;
            }
          });

          this.minimizeForm = true;
        }
      }
    }
  }

  clearAllGifts() {
    this.giftItemCampaigns.forEach(item => {
      item.isApplied = false;
      item.giftItems.forEach(w => w.isApplied = false);
    });
  }

  clearOtherGifts(campaignId) {

    this.giftItemCampaigns.filter(w => w.campaignId == campaignId).forEach(item => {

      item.giftItems.forEach(s => s.isApplied = false);

    });

    this.appliedCampaignsDescription = [];
  }

  async removeGifts() {
    await this.paymentService.removeGifts();
    this.setMinimizeForm(true);
  }

}
