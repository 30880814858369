import { Injectable, EventEmitter, Inject, PLATFORM_ID } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import swal from 'sweetalert2';
import { BusinessError } from '../helpers/BusinessError';
import { isPlatformBrowser } from '@angular/common';
import { ToastrService } from 'ngx-toastr';
import { environment } from 'src/environments/environment';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class UiService {

  uiChangedEvent: EventEmitter<any> = new EventEmitter();

  onLoading: EventEmitter<any> = new EventEmitter<any>();
  public hideCopyRight: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  isLoading = false;

  loadingElement: any;

  isBrowser: boolean;

  tenantName: string;

  public termsAccepted: boolean;

  constructor(
    private translateService: TranslateService,
    private toastr: ToastrService,
    @Inject(PLATFORM_ID) platformId: Object) {
    this.isBrowser = isPlatformBrowser(platformId);
  }

  getTenantName(): string {
    return this.tenantName;
  }
  setTenantName(tenantName) {
    this.tenantName = tenantName;
  }

  sendUiEvent(eventName) {
    this.uiChangedEvent.emit(eventName);
  }

  scrollToTop(): Promise<any> {
    return new Promise(resolve => {
      if (this.isBrowser) {
        setTimeout(() => {

          let scrollToTop = window.setInterval(() => {
            let pos = window.pageYOffset;

            var vel = pos * 0.1;

            if (vel < 10) vel = 10;

            if (pos > 180) {
              window.scrollTo(0, pos - vel); // how far to scroll on each step
            } else {
              window.clearInterval(scrollToTop);
              resolve();
            }
          }, 16);
        }, 300);
      } else {
        resolve();
      }
    });
  }

  getDefaultLang(): string {
    return this.translateService.getDefaultLang() ?? environment.defaultLanguage;
  }

  setDefaultLang(lang: string) {
    if (lang) {
      this.translateService.setDefaultLang(lang);
    } else {
      this.translateService.setDefaultLang(environment.defaultLanguage);
    }
  }

  async translate(key: string): Promise<any> {
    return await this.translateService.get(key).toPromise();
  }

  showLoading(message = undefined) {
    this.onLoading.emit({ loading: true, message });
  }

  hideLoading() {
    this.onLoading.emit({ loading: false });
  }

  async showError(e): Promise<any> {

    if (!this.isBrowser) return;

    var message: string;

    if (e instanceof BusinessError) {
      message = e.message;
    } else {
      message = await this.translate('anErrorWasOcurred');
    }

    this.hideLoading();

    return await swal.fire({
      title: 'Ops..',
      text: message,
      icon: 'warning'
    });

  }

  async showMessage(options) {
    if (!this.isBrowser) return;

    return await swal.fire(options);
  }


  showToastr(message, title, type = 'success') {

    switch (type) {
      case 'info':
        this.toastr.info(message, title, {
          progressBar: true,
          timeOut: 10000
        });
        break;
      case 'warning':
        this.toastr.warning(message, title, {
          progressBar: true,
          timeOut: 10000
        });
        break;
      case 'error':
        this.toastr.error(message, title, {
          progressBar: true,
          timeOut: 10000
        });
        break;
      case 'success':
      default:
        this.toastr.success(message, title, {
          progressBar: true,
          timeOut: 5000
        });
    }

  }


  formatCurrency(number): string {
    var lang = this.getDefaultLang();

    var currency = '';

    switch (lang) {
      case 'pt-BR':
        currency = 'BRL';
        break;
      default:
        currency = 'USD';
        break;
    }

    var formatter = new Intl.NumberFormat(lang, {
      style: 'currency',
      currency: currency,
    });

    return formatter.format(number);
  }

  hideModalCookieConsent(){
    let intervalId = setInterval(() => {
      const elemento = document.querySelector(`[aria-label="cookieconsent"]`);
      if(elemento) {
        (elemento as HTMLElement).style.display = 'none';
        clearInterval(intervalId);
      }
    }, 250);
    setTimeout(() => {
      clearInterval(intervalId);
    }, 20000);
  }
}
