import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { UiService } from 'src/app/services/ui.service';
import { environment } from 'src/environments/environment';
import { PacoteServicoService } from './cms/pacoteServico.service';
import { PaymentService } from './payment.service';
import * as CryptoJS from 'crypto-js';

@Injectable({
	providedIn: 'root'
})
export class DataLayerService {

	newLayer: any;
	private lastStep: BehaviorSubject<number> = new BehaviorSubject<number>(undefined);
	private products: BehaviorSubject<any> = new BehaviorSubject<any>(undefined);
	private steps: BehaviorSubject<any> = new BehaviorSubject<any>(undefined);
	private loadData: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

	constructor(private uiService: UiService, private paymentService: PaymentService, private pacoteServicoService: PacoteServicoService) { }

	setProducts(itens: any) {
		this.products.next(itens);
	}

	async createDataLayer(step, pageType = 'checkout') {

		if (!this.uiService.isBrowser) return;

		if(step == this.lastStep.value) return;

		if (pageType == "purchase") {
			this.loadItens();
		}

		if (!this.products.value) {
			this.createSteps(step, pageType);
			if (!this.loadData.value) {
				this.loadData.next(true);
				this.products.subscribe(value => {
					if (value) {
						this.processSteps();
					}
				});
			}
		} else if (this.steps.value) {
			this.createSteps(step, pageType);
		} else {
			this.processDataLayer(step, pageType);
			this.lastStep.next(step);
		}
	}

	async loadItens() {

		let dataPayment = this.paymentService.dataPayment.value;

		let ids = [];
		dataPayment.order.itens.forEach(e => {
			ids.push(e.id);
		});

		let itens = await this.pacoteServicoService.getPacoteServicosByRangeId(ids);

		if (itens) {
			dataPayment.order.itens.forEach(e => {
				let item = itens.find(i => i.id == e.id);
				if (item) {
					item.price = e.netValue;
					item.mainPrice = e.priceTableValue;
				}
			});
			this.products.next(itens);
		}
	}

	sortFunc(a, b) {
		if (a.step < b.step) {
			return -1;
		}
		if (a.step > b.step) {
			return 1;
		}
		return 0;
	}

	createSteps(step, pageType) {
		let steps = this.steps.value;
		if (steps && steps.length) {
			if (steps.find(x => x.step == step)) {
				return;
			}
		}
		else {
			steps = [];
		}
		steps.push({
			step,
			pageType
		});
		this.steps.next(steps);
	}

	async processSteps() {
		if (this.steps.value) {
			let steps = this.steps.value.sort(this.sortFunc);
			steps.forEach(s => {
				this.processDataLayer(s.step, s.pageType);
				this.processDataLayerPurchaseGA4(s.pageType);
				this.lastStep.next(s.step);
			});
			this.steps.next(undefined);
		}
	}

	processDataLayerPurchaseGA4(pageType) {

		if (pageType == 'purchase') {

			this.newLayer = {
				'event': 'purchase'
			}

		 	this.createPurchaseGA4();
			this.createEcommerce();

			if (environment.production == false) {
				console.log("dataLayer: ", this.newLayer);
			}

			window['dataLayer'] = window['dataLayer'] || [];
			window['dataLayer'].push({ ecommerce: null });
			window['dataLayer'].push(this.newLayer);
		}
	}

	processDataLayer(step, pageType) {

		this.newLayer = {
			'pageType': pageType,
			'event': 'virtualPageView'
		}

		if (pageType == 'checkout') {
			this.createCheckout(step);
			this.createProducts();
		} else {
			this.createPurchase();
			this.createProducts('transactionProducts');
		}

		this.createVisitor();

		if (environment.production == false) {
			console.log("dataLayer: ", this.newLayer);
		}

		window['dataLayer'] = window['dataLayer'] || [];
		window['dataLayer'].push(this.newLayer);
	}

	private createPurchaseGA4() {
		let dataPayment = this.paymentService.dataPayment.value;
		let newLayerPurchase = {
			'transaction_id': dataPayment.order.code,
			affiliation: "Online Store",
			value: dataPayment.order.totalAmount,
			tax: "",
			shipping: "",
			currency: "BRL",
			coupon: dataPayment.order.voucherCode
		}

		this.newLayer = { ...this.newLayer, ...newLayerPurchase };
	}

	private createPurchase() {
		let dataPayment = this.paymentService.dataPayment.value;
		let newLayerPurchase = {
			'transactionID': dataPayment.order.code,
			'transactionTotal': dataPayment.order.totalAmount,
			'paymentOption': `${dataPayment.order.paymentCondition} - ${dataPayment.order.paymentMethod}`,
			'previousPage': environment.ecommerceUrl + '/checkout',
			'currentPage': environment.ecommerceUrl + '/done',
		}

		this.newLayer = { ...this.newLayer, ...newLayerPurchase };
	}

	private createCheckout(step) {

		let nameStep = '';
		switch (step) {
			case 1:
				nameStep = 'Termos e Condições';
				break;
			case 2:
				nameStep = 'Unidade de Preferência';
				break;
			case 3:
				nameStep = 'Voucher de Desconto';
				break;
			case 4:
				nameStep = 'Forma de Pagamento';
				break;
			case 5:
				nameStep = 'Dados do Cartão';
				break;
		}

		let newLayerStep = {
			'step': step,
			'nameStep': nameStep,
			'previousPage': environment.ecommerceUrl + '/carrinho',
			'currentPage': environment.ecommerceUrl + '/checkout',
		}

		if (step == 4 || step == 5) {
			let dataPayment = this.paymentService.dataPayment.value;
			let newPaymentOption = {
				'paymentOption': dataPayment.order.paymentMethod
			}
			newLayerStep = { ...newLayerStep, ...newPaymentOption };
		}

		this.newLayer = { ...this.newLayer, ...newLayerStep };
	}

	private createEcommerce()
	{
		if (this.products.value && this.products.value.length) {
			let products = [];
			this.products.value.forEach(function (product, i) {
				products.push({
					item_name: product.description,
					item_id: product.id,
					price: product.price,
					item_brand: "Google",
					item_category: product.categoria,
					item_variant: `${product.categoria}, ${product.areas}, ${product.genero}`,
					quantity: 1
				});
			});
			if (products) {
				let newLayerProducts = {
					'items': products
				}
				this.newLayer = { ...this.newLayer, ...newLayerProducts };
			}
		}
	}

	private createProducts(productsLabel = 'products') {
		if (this.products.value && this.products.value.length) {
			let products = [];
			this.products.value.forEach(function (product, i) {
				products.push({
					id: product.id,
					name: product.description,
					originalPrice: product.mainPrice,
					price: product.price,
					quantity: 1,
					variant: `${product.categoria}, ${product.areas}, ${product.genero}`
				});
			});
			if (products) {
				if (productsLabel == 'transactionProducts') {
					let newLayerTransactionProducts = {
						'transactionProducts': products
					}
					this.newLayer = { ...this.newLayer, ...newLayerTransactionProducts };
				} else {
					let newLayerProducts = {
						'products': products
					}
					this.newLayer = { ...this.newLayer, ...newLayerProducts };
				}
			}
		}
	}

	private createVisitor() {
		let dataPayment = this.paymentService.dataPayment.value;
		let customer = dataPayment.order.customer;
		let encryptedEmail = CryptoJS.SHA256(dataPayment.order.clientMail).toString(CryptoJS.enc.Base64);
		let visitor = {
			'id': customer ? customer.id : 0,
			'email': encryptedEmail,
			'name': dataPayment.order.clientName,
			'isLoggedIn': true,
			'device': this.getDevice(),
			'newBuyer': customer ? customer.hasContract : true,
			'city': (customer && customer.address) ? customer.address.city : '',
			'state': (customer) && customer.address ? customer.address.state : '',
		}
		var newLayerVisitor = {
			'visitor': visitor
		}
		this.newLayer = { ...this.newLayer, ...newLayerVisitor };
	}

	private getDevice() {
		var device = 'desktop';
		if (/(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|ipad|iris|kindle|Android|Silk|lge |maemo|midp|mmp|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows (ce|phone)|xda|xiino/i.test(navigator.userAgent)
			|| /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(navigator.userAgent.substr(0, 4))) {
			device = 'mobile';
		}
		return device;
	}
}