import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import gql from 'graphql-tag';
import { BehaviorSubject } from 'rxjs';


const agradecimentoElosQuery = `
{
  queryAgradecimentoElosContents {
    flatData {
      useCustomHtml,
      html,
      descriptionApp,
      urlAndroid,
      urlIos
    }
  }
}`;


@Injectable({
  providedIn: 'root'
})
export class AgradecimentoElosCmsService {

  //public conheceAreas: any;
  public data: BehaviorSubject<any> = new BehaviorSubject<any>(undefined);

  constructor(private apollo: Apollo) {

  }

  async getData(): Promise<any> {

    var result = await this.apollo.watchQuery<any>({
      query: gql(agradecimentoElosQuery)
    }).result();

    var data = result.data.queryAgradecimentoElosContents.map(q => {
      return {
        useCustomHtml: q.flatData.useCustomHtml,
        html: q.flatData.html,
        descriptionApp: q.flatData.descriptionApp,
        urlAndroid: q.flatData.urlAndroid,
        urlIos: q.flatData.urlIos
      }
    })[0];

    this.data.next(data);

    return data;
  }

}
