import { trigger, state, style, transition, animate } from '@angular/animations';
import { HttpClient } from '@angular/common/http';
import { OnDestroy, Renderer2, ChangeDetectorRef } from '@angular/core';
import { Component, HostListener, OnInit, Input } from '@angular/core';
import { CreditCard } from 'src/app/model/credit-card';
import { ElosgateService } from 'src/app/services/elosgate.service';
import { PaymentService } from 'src/app/services/payment.service';
import { UiService } from 'src/app/services/ui.service';
import { Router } from '@angular/router';
import { environment } from '../../../../environments/environment';
import { TranslateService } from '@ngx-translate/core';
import { List } from 'linqts';
import { GiftitemService } from 'src/app/services/giftitem.service';

declare var TokenEx: any;

@Component({
  selector: 'app-card',
  templateUrl: './card.component.html',
  styleUrls: ['./card.component.scss'],
  animations: [
    trigger('openClose', [

      state('open', style({
        height: '*',
        display: 'block',
        opacity: 1
      })),
      state('closed', style({
        height: '0px',
        padding: '0px',
        display: 'none',
        opacity: 0
      })),
      transition('open <=> closed', [
        style({ display: 'block', opacity: 0.2 }),
        animate('0.2s')
      ])
    ]),
  ]
})
export class CardComponent implements OnInit, OnDestroy {

  responsive: boolean = false;
  responsiveBreakpoint: number = 768;

  ready: boolean = false;
  destroyed: boolean = false;

  public model: CreditCard;

  minimizeForm: boolean = false;
  voucherOpen: boolean = false;
  formapgtoOpen: boolean = false;
  total: number;
  giftItemsResolved: boolean = false;
  requireGiftItemResolve: boolean = false;
  giftItemCampaigns: any[];
  paymentConditionsReady: boolean = false;
  paymentConditions: any[] = [];
  paymentConditionId: number;
  paymentMethodDescription: string;
  creditCardType: any;
  isFinalizingOrder: boolean = false;


  @Input() isTotem: boolean;
  @Input() flagshipEstablishmentId: number;
  @Input() paymentMethod: any;
  @Input() data: any;

  code: string;

  paymentRequestCode: string;

  configuracaoToken: any = {};

  iframe: any;

  tokenData: any;

  tokenLoaded: boolean = false;

  useTokenex: boolean = environment.useTokenex;

  tokenexLoaded: boolean = false;

  constructor(private paymentService: PaymentService,
    private uiService: UiService,
    private http: HttpClient,
    private elosgate: ElosgateService,
    private renderer: Renderer2,
    private router: Router,
    private translateService: TranslateService,
    private changeDetectorRef: ChangeDetectorRef,
    private giftitemService: GiftitemService) {

    this.model = {
      nome: "",
      numero: "",
      cvv: "",
      usaCvv: false,
      validade: "",
      autorizadoraSelecionada: 2,
      cardImg: "./assets/brands/none.png"
    };
  }

  ngOnDestroy() {
    this.destroyed = true;
  }

  async ngOnInit() {

    if (this.uiService.isBrowser) {
        this.responsive = (window.innerWidth <= this.responsiveBreakpoint);
    }

    await this.initComponent();

      try {

        this.paymentService.dataChangedEvent.subscribe(async (e) => {
          if(!this.configuracaoToken?.authenticationKey && environment.useTokenex && this.uiService.isBrowser){
            this.configuracaoToken = await this.paymentService.obterChavesCapturaCartao(environment.elosgate_obterchaves_origin);

            if(!this.tokenexLoaded && this.configuracaoToken && this.uiService.isBrowser){
              var scriptElement = await this.loadTokenexScript();
              scriptElement.onload = () => {
                this.loadTokenex();
                this.tokenexLoaded = true;
              };
            }
          }

          this.total = e.data.order.totalAmount;
          this.requireGiftItemResolve = e.data.order.requireGiftItemResolve;
          this.giftItemCampaigns = e.data.order.giftItemCampaigns;
          if (e.data.order.paymentMethodId) {
            this.creditCardType = e.data.order.paymentMethod_CreditCardType;
            if (this.creditCardType == 1) {
              this.model.usaCvv = true;
            } else {
              this.model.usaCvv = false;
            }

            if (!this.paymentService.dataPayment.value) {
              this.paymentService.dataPayment.next(e.data);
            }
          }

          this.code = e.data.order.externalCode;
          this.paymentRequestCode = e.data.order.code;

          if (e.event == 'saveGiftItems') {
            this.giftItemsResolved = true;
          }
          else if (e.event === "saveVoucher") {
            this.ready = false;
          }

          //quando pix, não carregamos as condições de pagamento
          if(this.creditCardType != 3){
            await this.queryPaymentConditions(e.data);
          }else{
            this.paymentConditions = [];
          }
          this.paymentConditionId = e.data.order.paymentConditionId;

          this.changeDetectorRef.detectChanges();

        });

        this.uiService.uiChangedEvent.subscribe((eventName) => {
          if (eventName == "voucherOpen") {
            this.voucherOpen = true;
          }
          else if (eventName == "voucherClosed") {
            this.voucherOpen = false;
          }

          if (eventName == "formapgtoOpen") {
            this.formapgtoOpen = true;
          }
          else if (eventName == "formapgtoClosed") {
            this.formapgtoOpen = false;
          }

          if (eventName == "formapgtoReady") {
            this.ready = true;

            if (this.uiService.isBrowser && !this.destroyed && !this.isTotem) {
              setTimeout(() => {
                setTimeout(() => {

                  if(this.creditCardType != 3){
                    this.renderer.selectRootElement('#inputCardName').focus();
                  }
                }, 600);
                if(this.creditCardType != 3){
                  this.renderer.selectRootElement('#inputCardName').scrollIntoView();
                }
              }, 300);
            }

          } else if (eventName == "formapgtoNotReady") {
            this.ready = false;
          }

          this.minimizeForm = this.formapgtoOpen || this.voucherOpen;

        });

        //Escuta um evento enviado por outro componente para finalizar o pedido
        this.paymentService.finalizePurchase.subscribe(async sub => {
          if (sub && sub == true) {
            await this.finalize();
          }
        });

        //Caso esteja disponível apenas uma forma de pagamento ou, uma forma de pagamento e uma condição de pagamento, já trazemos selecionadas e persistimos os valores
        this.paymentService.savePaymentMethodAndCondition.subscribe(async sub => {
          if (sub && sub > 0) {
            if (this.paymentMethod && this.paymentConditionId) {
              await this.savePaymentMethod();
            } else if (this.paymentMethod) {
              await this.paymentService.selectPaymentMethod(this.paymentMethod);
            }
          }
        });
      }
      catch (e) {
        await this.uiService.showError(e);
      }
  }


  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.responsive = (event.target.innerWidth <= this.responsiveBreakpoint);
  }

  changeName($event) {

    this.model.nome = $event.toLocaleUpperCase();

    setTimeout(() => {
      this.model.nome = this.model.nome.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
      this.model.nome = this.model.nome.replace(/[^a-zA-Z ]/g, "");
    }, 10);
  }

  changeNumber($event) {
    setTimeout(() => {
      this.model.cardImg = './assets/brands/'.concat(this.elosgate.obterMarca(this.model.numero)).concat('.png');
    }, 20);

    switch ($event.inputType) {
      case "insertText":
      case "insertFromPaste":
        setTimeout(() => {
          this.model.numero = this.masknumber(this.model.numero);
        }, 10);

        break;
      case "deleteContentBackward":
      case "deleteContentBackward":
        if (this.model.numero.substr(this.model.numero.length - 1) == " ") {
          this.model.numero = this.model.numero.substr(0, this.model.numero.length - 2);
        }
        break;
    }
  }

  masknumber(inputTxt): string {

    var brand = this.elosgate.obterMarca(inputTxt);

    inputTxt = inputTxt.replace(/\D/g, "");

    if (brand == 'amex') {
      inputTxt = inputTxt.replace(/^(\d{4})(\d)/, "$1 $2");
      inputTxt = inputTxt.replace(/(\d{6})(\d)/, "$1 $2");
      inputTxt = inputTxt.substr(0, 19);
    } else {


      inputTxt = inputTxt.replace(/(\d{4})(\d)/, "$1 $2");
      inputTxt = inputTxt.replace(/(\d{4})(\d)/, "$1 $2");
      inputTxt = inputTxt.replace(/(\d{4})(\d)/, "$1 $2");
      inputTxt = inputTxt.replace(/(\d{4})(\d)/, "$1 $2");
      inputTxt = inputTxt.substr(0, 19);
    }
    return inputTxt;
  }

  changeValidade($event) {
    switch ($event.inputType) {
      case "insertText":
      case "insertFromPaste":
        setTimeout(() => {
          this.model.validade = this.maskvalidade(this.model.validade);
        }, 10);

        break;
      case "deleteContentBackward":
      case "deleteContentBackward":
        if (this.model.validade.substr(this.model.validade.length - 1) == "/") {
          this.model.validade = this.model.validade.substr(0, this.model.validade.length - 2);
        }
        break;
    }
  }

  maskvalidade(inputTxt): string {
    inputTxt = inputTxt.replace(/\D/g, "");
    inputTxt = inputTxt.replace(/(\d{2})(\d{1})/, "$1/$2");
    inputTxt = inputTxt.substr(0, 5);

    var currentMonth = new Date().getMonth() + 1;

    if (inputTxt.length >= 5 &&
      //mês inválido
      (inputTxt.substr(0, 2) > 12 || inputTxt.substr(0, 2) == "00" ||

        //mês inválido (menor que o mês atual caso seja o ano atual)
        (inputTxt.substr(0, 2) < currentMonth &&
          inputTxt.substr(3, 2) == new Date().getFullYear().toString().substr(2, 2)) ||

        //ano inválido (menor que o ano atual)
        inputTxt.substr(3, 2) < new Date().getFullYear().toString().substr(2, 2))) {
      inputTxt = "";
    }

    return inputTxt;
  }

  changeCVV($event) {
    switch ($event.inputType) {
      case "insertText":
      case "insertFromPaste":
        setTimeout(() => {
          this.model.cvv = this.maskCVV(this.model.cvv);
        }, 10);

        break;
    }
  }

  maskCVV(inputTxt): string {

    var marca = this.elosgate.obterMarca(this.model.numero);

    inputTxt = inputTxt.replace(/\D/g, "");

    if (marca == 'amex') {
      inputTxt = inputTxt.substr(0, 4);
    } else {
      inputTxt = inputTxt.substr(0, 3);
    }
    return inputTxt;
  }


  async finalize() {
    this.isFinalizingOrder = true;
    if (!this.uiService.termsAccepted) {
      this.uiService.uiChangedEvent.emit('finalizeWithoutTerms');
      return;
    } else {
      this.uiService.uiChangedEvent.emit('finalizeWithTerms');
    }
    if(!this.paymentMethod){
      let message = await this.uiService.translate("selectPaymentMethod");
      this.uiService.showToastr(message, 'Ops', 'warning');
      return;
    }
    else if (!this.paymentConditionId) {
      let message = await this.uiService.translate("SELECTPORTIONQUANTITY");
      this.uiService.showToastr(message, 'Ops', 'warning');
      return;
    }

    if (environment.useTokenex) {
      this.tokenLoaded = false;
      this.tokenize();
    } else {
      if (this.giftItemCampaigns && this.giftItemCampaigns.length > 0) {
        var appliedGifts = await this.paymentService.checkForAppliedGifts();
        if (!appliedGifts) {
          var response = await this.uiService.showMessage({
            title: await this.uiService.translate('confirmation'),
            icon: "question",
            showCancelButton: true,
            focusConfirm: false,
            confirmButtonText: await this.uiService.translate('iWishToClaim'),
            cancelButtonText: await this.uiService.translate('moveFoward'),
            html: await this.uiService.translate('noGiftItemSelected'),
          });
          if (!response.value) {
            await this.finalizeOrder();
          }
        } else {
          this.finalizeOrder();
        }
      } else {
        this.finalizeOrder();
      }
    }
  }

  /**Tokeniza o cartão digitado através do TOKENEX*/
  tokenize() {
    this.iframe.tokenize();
  }

  /**Função chamada a partir do evento tokenize do TOKENEX para continuar a execução da finalização do pagamento dentro do contexto do angular */
  onTokenize = (async (data) => {
    this.tokenData = data;
    if (this.giftItemCampaigns && this.giftItemCampaigns.length > 0) {
      var appliedGifts = await this.paymentService.checkForAppliedGifts();
      if (!appliedGifts) {
        var response = await this.showNoGiftItemSelectedModal();
        if (!response.value) {
          await this.finalizeOrder();
        }
        return;
      }
    }
    this.finalizeOrder();
  });

  /**Função chamada a partir do evento validate do TOKENEX para validar o número do cartão digitado*/
  onValidate = (async (data) => {
    if (!data.isValid && this.isFinalizingOrder) {
      this.uiService.showMessage({
        title: "Ops!",
        html: await this.uiService.translate('invalidCard')
      });
    }
  });

  async finalizeOrder() {
    try {

      this.uiService.sendUiEvent("finalizeStart");
      var payment = await this.paymentService.doCreditCardPayment(this.model, this.isTotem, this.tokenData);

      if (this.isTotem && payment) {
        this.paymentService.saveFlagshipOrigin();
        var obj = { url: payment.url, qrCode: payment.qrCode };
        this.paymentService.setPaymentLink(obj);

        this.router.navigate([`/payment-link/${this.code}/${this.paymentRequestCode}`], { queryParams: { id: this.flagshipEstablishmentId } });
      }

    } catch (e) {
      this.uiService.sendUiEvent("finalizeError");
      await this.uiService.showError(e);
    }
  }

  expandAccordion() {
    if (this.minimizeForm) {
      this.uiService.sendUiEvent("cardOpen");
    }
  }

  async showNoGiftItemSelectedModal() {
    return await this.giftitemService.showNoGiftItemSelectedModal();
  }

  private async loadTokenexScript() {

    var src = environment.tokenexScriptUrl;

    var head = document.getElementsByTagName('head')[0];
    let script = document.createElement('script');
    script.setAttribute('src', src);
    head.appendChild(script);

    return script;

  }

  private async loadTokenex() {

    if (environment.useTokenex) {

      this.configuracaoToken.configuracaoToken.placeholder = await this.uiService.translate('CreditCardNumber');

      this.configuracaoToken.configuracaoToken.cvvPlaceholder = 'CVC / CVV';

      this.configuracaoToken.configuracaoToken.styles = {
        base: "::-webkit-input-placeholder: font-size: 5px;font-family: Roboto, sans-serif;padding: 0 8px; border: 1px solid gray; border-radius: 10px;font-size:17px;margin: 0;width: 100%;line-height: 30px;height: 32px;box-sizing: border-box;-moz-box-sizing: border-box;color: gray;",
        error: "border: 1px solid rgba(224, 57, 57, 0.5);",
        focus: "border: 1px solid gray;outline: 0;",
        cvv: {
          base: "font-family: Roboto, sans-serif;padding: 0 8px; border: 1px solid gray;border-radius: 10px;font-size:17px;margin: 0;width: 100%;line-height: 30px;height: 32px;box-sizing: border-box;-moz-box-sizing: border-box;color: gray;",
          error: "border: 1px solid rgba(224, 57, 57, 0.5);",
          focus: "border: 1px solid gray;outline: 0;"
        }
      }


      this.iframe = new TokenEx.Iframe("tokenExIframeDiv", this.configuracaoToken.configuracaoToken);

      this.iframe.load();

      this.iframe.on("tokenize", this.onTokenize);

      this.iframe.on("validate", this.onValidate);

    }
  }

  async queryPaymentConditions(data) {
    this.paymentMethod = this.paymentMethod ? this.paymentMethod : data.order.paymentMethodId;
    this.paymentMethodDescription = data.order.paymentMethod;
    var comText = await this.translateService.get("with").toPromise();
    var ofDiscountText = await this.translateService.get("ofDiscount").toPromise();

    var orderAmount = data.order.subtotalAmount - (data.order.promotionalDiscount ?? 0) - (data.order.voucherDiscount ?? 0) - (data.order.saleCampaignDiscount ?? 0);

    var preAppliedDiscount = (((data.order.voucherDiscount ?? 0) + (data.order.promotionalDiscount ?? 0) + ((data.order.saleCampaignDiscount ?? 0) - (data.order.SalesCampaignAcumulativeDiscountValue ?? 0))) / data.order.subtotalAmount) * 100.0;

    this.paymentConditions =
      new List<any>(data.methods)
        .Where(d => d.paymentMethod_Id == this.paymentMethod)
        .Select(d => (
          {
            id: d.paymentCondition_Id,
            name: d.paymentCondition_Description,
            PortionAmount: this.uiService.formatCurrency(Math.round(((orderAmount - d.maxAllowedDiscount) / d.paymentCondition_Installments) * 100) / 100),
            DiscountAmount: preAppliedDiscount + d.discountAmount
          }))
        .Distinct()
        .Select(d => ({
          id: d.id,
          name: d.name,
          displayName: `${d.name} ${d.PortionAmount}${d.DiscountAmount > 0 ? ` (${comText} ${d.DiscountAmount.toFixed(2)}% ${ofDiscountText})` : ""}`,
          PortionAmount: d.PortionAmount,
          DiscountAmount: d.DiscountAmount
        }))
        .OrderBy(d => d.name)
        .ToArray();

    if (this.paymentConditions.length == 1) {
      this.paymentConditionId = this.paymentConditions[0].id;
    }
  }

  async savePaymentMethod() {
    if (this.paymentMethod && this.paymentConditionId) {

      var validateResult = await this.paymentService.validatePaymentRequestVersion();
      if(!validateResult.versionsMatch){
        await this.paymentService.displayModalGoToMyBudgets();
        return;
      }

      await this.paymentService.savePaymentMethod(this.paymentMethod, this.paymentConditionId);
    }
  }

  /**
   * Inicializa o componente do cartão a partir do carregamento da tela, sem a necessidade de um evento
   */
  async initComponent(){
    if(!this.configuracaoToken?.authenticationKey && environment.useTokenex && this.uiService.isBrowser){
      if(this.data.order.structureId){
        this.configuracaoToken = await this.paymentService.obterChavesCapturaCartao(environment.elosgate_obterchaves_origin);

        if(!this.tokenexLoaded && this.configuracaoToken && this.uiService.isBrowser){
          var scriptElement = await this.loadTokenexScript();
          scriptElement.onload = () => {
            this.loadTokenex();
            this.tokenexLoaded = true;
          };
        }
      }
    }

    this.total = this.data.order.totalAmount;
    this.requireGiftItemResolve = this.data.order.requireGiftItemResolve;
    this.giftItemCampaigns = this.data.order.giftItemCampaigns;
    if (this.data.order.paymentMethodId) {
      this.creditCardType = this.data.order.paymentMethod_CreditCardType;
      if (this.creditCardType == 1) {
        this.model.usaCvv = true;
      } else {
        this.model.usaCvv = false;
      }

      if (!this.paymentService.dataPayment.value) {
        this.paymentService.dataPayment.next(this.data);
      }
    }

    this.code = this.data.order.externalCode;
    this.paymentRequestCode = this.data.order.code;

    //quando pix, não carregamos as condições de pagamento
    if(this.creditCardType != 3){
      await this.queryPaymentConditions(this.data);
    }else{
      this.paymentConditions = [];
    }
    this.paymentConditionId = this.data.order.paymentConditionId;

    this.changeDetectorRef.detectChanges();

    if (this.data.order.paymentMethodId) {
      this.ready = true;
    }

  }
}
