<section class="multiple-payments-confirm-modal">
    <div class="multiple-payments-confirm-modal-header">
        <img alt="icon" src="../../../assets/imagens/multiple-payments-icon.png"/>
        <h2>{{ title }}</h2>
    </div>

    <div class="content">
        <p>
            <span class="sub-description-first">{{ 'multiplePaymentsConfirm.descriptionFirst' | translate }} </span>
            <span>{{ 'multiplePaymentsConfirm.descriptionSecond' | translate }} </span>
        </p>
        <p>{{ 'multiplePaymentsConfirm.subDescription' | translate }}</p>
    </div>

    <div class="btn-confirmation-wrapper">
        <button type="button" class="btn-confirm" [mat-dialog-close]="true">{{ 'multiplePaymentsConfirm.confirmButton'  | translate }}</button>
        <button type="button" class="btn-cancel" [mat-dialog-close]="false">{{ 'multiplePaymentsConfirm.cancelButton' | translate }}</button>       
    </div>
</section>

