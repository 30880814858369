<section class="previous-payments-modal">
    <div class="modal-header">
        <h2>{{'previousPayments.title' | translate}}</h2>
        <p>{{'previousPayments.description' | translate}}</p>
    </div>
    <div class="payment-list">
        <div class="payment" *ngFor="let payment of payments">
            <img *ngIf="payment.paymentWay === 1" src="../../../assets/imagens/icon-banknote.png"/>
            <img *ngIf="payment.paymentWay === 13" src="../../../assets/imagens/icon-payments-pix.png"/>
            <img *ngIf="payment.paymentWay !== 1 && payment.paymentWay  !== 13" src="../../../assets/imagens/icon-payments-credit.png"/>
            <div class="payment-text-block">
                <p class="payment-method-description">{{payment.paymentMethodDescription}}</p>
                <span *ngIf="payment.showDiscountPercent" class="discount-tag">{{payment.appliedDiscount * 100}}% OFF</span>
                <p class="value">{{'currencySymbol' | translate }} {{payment.amount | currency: translate.instant('currency') : '' : '1.2-2'}}</p>
            </div>
            <div class="payment-date">
                <p>{{payment.date}}</p>
                <p>{{payment.paymentTime}}</p>
            </div>
        </div>
    </div>
    <div class="discount-value" *ngIf="totalDiscount > 0">
        <p>{{'previousPayments.discountDescription' | translate}} <span>-{{'currencySymbol' | translate }} {{totalDiscount | currency: translate.instant('currency') : '' : '1.2-2'}}</span></p>
    </div>
    <div class="modal-footer">
        <p>
            {{'previousPayments.footerDescriptionFirst' | translate}} <b>{{'previousPayments.alreadyAdjusted' | translate}}</b> {{'previousPayments.footerDescriptionLast' | translate}}
        </p>
    </div>
    <button type="button" class="btn-close" (click)="closeModal()">{{'previousPayments.close' | translate}}</button>
</section>

