    <!-- HEADER SITE APENS PARA O CHECKOUT  -->

    <header class="sec-header-checkout" [ngClass]="{'sec-header-checkout-payment-link': paymentLinkOrigin === 'ELOS'}" [hidden]="hiddenHeader">

        <section *ngIf="paymentLinkOrigin !== 'ELOS'" class="sec-top">
            <div class="grid">
                <div class="logo">
                    <a [href]="ecommerceUrl" title="">
                        <img src="../../../assets/imagens/logo.webp" title="" alt="" width="253">
                    </a>
                </div>
            </div>
        </section>
        <section *ngIf="paymentLinkOrigin === 'ELOS'" class="sec-top sec-top-payment-link">
            <div class="container">
                <div class="back">
                    <a style="cursor:pointer" (click)="goBack()" title="">
                        <img src="../../../assets/imagens/arrow-left-linear-white.png" title="" alt="" width="30" height="30">
                    </a>
                </div>
                <div class="logo logo-payment-link">
                    <img src="../../../assets/imagens/logo-white.png" title="" alt="" width="173" height="47">
                </div>
            </div>
        </section>

    </header>
    <!-- FIM HEADER SITE -->
    <div class="sec-header-checkout-app-mode" *ngIf="modalMode">
      <h2>{{ modalTitle }}</h2>
      <mat-icon (click)="sendExitToApp()">highlight_off</mat-icon>
    </div>
