<section class="sec-404">
    <div class="grid">

        <div class="desc">
            <h1>{{'sorry' | translate}}</h1>

            <p><b>{{'pageNotFound' | translate}}</b></p>
             
<ul class="disc">
<li>{{'pageNotFoundBrowserAddress' | translate}}</li>
<li>{{'pageNotFoundLink' | translate}}</li>
</ul>

            <h3>{{'pageNotFoundWhatCanBeDone' | translate}}</h3>

            <p><b>{{pageNotFoundRedirectDescription}}</b></p>

<ul class="disc">
<li><a href="#" onclick="history.go(-1); return false;">{{'goBack' | translate}}</a> {{'goBackToPreviousPage' | translate}}</li>
<li>{{'useSearch' | translate}}</li>
<li>{{'youCanUseThoseLinks' | translate}}<br><a [href]="ecommerceUrl">{{'homePage' | translate}}</a> <span class="separator">|</span> <a [href]="ecommerceMyAccountUrl">{{'myAccount' | translate}}</a></li></ul>

        </div>
    </div>
</section>