<div *ngIf="isLoading" class="loading-container flex-content-center">
  <div *ngIf="isLongLoading" class="center">
      <div class="loading-block">
          <div class="loading-block-spinner">
              <mat-progress-spinner
              color="primary"
              mode="indeterminate">
              </mat-progress-spinner>
          </div>
          <span class="loading-text">{{ loadingMessage }}</span>
      </div>
  </div>
</div>

<app-header></app-header>

<router-outlet #outlet="outlet"></router-outlet>

<app-footer *ngIf="!hideFooter"></app-footer>