import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { environment } from 'src/environments/environment';
import { CheckoutCmsService } from 'src/app/services/cms/checkoutCms.service';

@Component({
  selector: 'app-not-found',
  templateUrl: './not-found.component.html',
  styleUrls: ['./not-found.component.scss']
})
export class NotFoundComponent implements OnInit {
  pageNotFoundRedirectDescription: string;
  ecommerceUrl: string;
  ecommerceMyAccountUrl: string;
  clientName: string;
  constructor(private translateService: TranslateService, private checkoutCmsService: CheckoutCmsService) { }

  async ngOnInit(){
    this.ecommerceUrl = environment.ecommerceUrl;
    this.clientName = await this.checkoutCmsService.getBrand();
    this.ecommerceMyAccountUrl = `${this.ecommerceUrl}${environment.ecommerce_myaccount_endpoint}`;
    this.pageNotFoundRedirectDescription = await this.translateService.get('pageNotFoundRedirect', { clientName: this.clientName }).toPromise();
  }

}
