import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import Enumerable from 'linq';
import { InstallmentPayment, PaymentConditionModel } from 'src/app/model/payments/payment-condition.model';

export interface DialogData {
  showDiscountPercent: boolean;
  paymentMethodId: number;
  paymentConditions: Array<PaymentConditionModel>;
  order: any;
}

@Component({
  selector: 'app-parcelas',
  templateUrl: './parcelas.component.html',
  styleUrls: ['./parcelas.component.scss']
})
export class ParcelasComponent implements OnInit {
  showDiscountPercent: boolean;
  paymentMethodId: number;
  paymentConditions: InstallmentPayment[];
  order: any;

  constructor(
    public translate: TranslateService,
    public dialogRef: MatDialogRef<ParcelasComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData) {
      this.showDiscountPercent = data.showDiscountPercent;
      this.paymentMethodId = data.paymentMethodId;
      this.order = data.order;
      this.data = data;
  }

  ngOnInit(): void {
    this.paymentConditions = Enumerable.from(this.data.paymentConditions)
    .where(x => x.installmentValue > 0)
    .groupBy(c => c.installments)
    .select(g => {
      let f = Enumerable.from(g.getSource()).orderByDescending(o => o.minPurchaseAmountToEnable);
      const result: InstallmentPayment = f.select(c => ({
          id: c.id,
          installments: c.installments,
          installmentValue: c.installmentValue,
          discountPercent: c.discountPercent * 100,
          feesPercent: c.feesPercent,
          total: c.total,
          minInstallmentAmount: c.minInstallmentAmount,
          minPurchaseAmountToEnable: c.minPurchaseAmountToEnable
        } as InstallmentPayment)
      ).firstOrDefault();

      if (result.minInstallmentAmount == null || result.minInstallmentAmount <= 0 || result.installmentValue >= result.minInstallmentAmount) {
        return result;
      }
    })
    .where(c => !!c) // Remover os indefinidos
    .orderBy(c => c?.installments)
    .toArray();
  }

  setPaymentMethod(item: PaymentConditionModel){
    this.dialogRef.close(item);
  }

  cancel() {
    this.dialogRef.close();
  }

  round(value, precision = 1) {
    var multiplier = Math.pow(10, precision || 0);
    return Math.round(value * multiplier) / multiplier;
  }
}