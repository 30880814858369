<section class="payment-summary">
    <div class="grid grid-payment-link">
        <div class="discount-wrapper">
            <p class="discount-value" *ngIf="!paymentConditionSelected || !pixValue">
                {{'total' | translate}}
            </p>
            <p class="discount-value" *ngIf="paymentConditionSelected && pixValue">
                {{'totalDiscount' | translate}}: <br>
                {{'currencySymbol' | translate }} -{{ totalDiscount | currency: translate.instant('currency') : '' :'1.2-2'}}
            </p>
        </div>

        <div class="total-wrapper" *ngIf="paymentMethodSelected.creditCardType != paymentMethodEnum.Pix && !paymentConditionSelected && multiplePayments.length <= 0">
            <p class="total-value">
                <span>{{'creditCard.from' | translate}}</span><span class="currency-symbol">&nbsp;{{'currencySymbol' | translate }}&nbsp;</span>
                <span class="currency-value">{{total | currency: translate.instant('currency') : '' :'1.2-2' }}</span>
            </p>
            <p class="condition-description" *ngIf="paymentMethodSelected.creditCardType != paymentMethodEnum.Recurrent">
                <span>{{'creditCard.until' | translate}} {{paymentMethodSelected.maxInstallmentsAllowed}}x {{'creditCard.withCard' | translate}}</span>
            </p>
        </div>

        <div class="total-wrapper" *ngIf="paymentConditionSelected && paymentMethodSelected.creditCardType != paymentMethodEnum.Pix && multiplePayments.length <= 0">
            <p class="total-value">
                <span class="currency-installments">{{paymentConditionSelected.installments}}x</span> {{'creditCard.outOf' | translate}} <span class="currency-symbol">{{'currencySymbol' | translate }}</span><span class="currency-value"> {{paymentConditionSelected.installmentValue | currency: translate.instant('currency') : '' :'1.2-2'}}</span>
            </p>
        </div>

        <div class="total-wrapper" *ngIf="paymentConditionSelected && paymentMethodSelected.creditCardType == paymentMethodEnum.Pix && multiplePayments.length <= 0">
            <p class="total-value">
                <span>{{'currencySymbol' | translate }}&nbsp;</span>
                <span class="currency-value">{{total | currency: translate.instant('currency') : '' :'1.2-2' }}</span>
            </p>
            <p class="condition-description">
                <span>{{'pix.in' | translate}} 1x {{'pix.withPix' | translate}}</span>
            </p>
        </div>

        <div class="total-wrapper" *ngIf="multiplePayments && multiplePayments.length > 0">
            <p class="total-value">
                <span>{{'currencySymbol' | translate }}&nbsp;</span>
                <span class="currency-value" *ngIf="paymentConditionSelected && pixValue">{{ total | currency: translate.instant('currency') : '' :'1.2-2' }}</span>
                <span class="currency-value" *ngIf="!paymentConditionSelected || !pixValue">{{ data.order.totalAmount | currency: translate.instant('currency') : '' :'1.2-2' }}</span>
            </p>
            <p class="condition-description" *ngIf="paymentConditionSelected && pixValue">
                <span>{{'pix.in' | translate}} 1x {{'pix.withPix' | translate}} {{'and' | translate}} {{paymentConditionSelected.installments}}x {{'creditCard.withCard' | translate}}</span>
            </p>
        </div>
        
        <div class="clear"></div>
    </div>

    <div class="grid grid-payment-link" style="display: block !important">
        <div class="button-wrapper">
            <button class="button btn-go-back" (click)="goBack();">{{'goBack' | translate}}</button>
            <button class="button btn-confirm" [disabled]="!isFormValid" (click)="confirm();">{{'confirm' | translate}}</button>
        </div>
    </div>
</section>