import { Component, OnInit, Input } from '@angular/core';
import { trigger, state, style, transition, animate } from '@angular/animations';
import { PaymentService } from 'src/app/services/payment.service';
import { UiService } from 'src/app/services/ui.service';

@Component({
  selector: 'app-manual-apply-campaigns',
  templateUrl: './manual-apply-campaigns.component.html',
  styleUrls: ['./manual-apply-campaigns.component.scss'],
  animations: [
    trigger('openClose', [
      // ...
      state('open', style({
        height: '*',
        display: 'block',
        opacity: 1
      })),
      state('closed', style({
        height: '0px',
        padding: '0px',
        display: 'none',
        opacity: 0
      })),
      transition('open <=> closed', [
        style({ display: 'block', opacity: 0.5 }),
        animate('0.2s')
      ])
    ]),
  ]
})
export class ManualApplyCampaignsComponent implements OnInit {

  

  ready: boolean = false;

  minimizeForm: boolean = true;

  paymentRequestCode: number;

  @Input() manualApplyCampaigns: any[];

  constructor(
    private uiService: UiService,
    private paymentService: PaymentService
  ) { }

  async ngOnInit()  {
    
    
    try {
      this.paymentService.dataChangedEvent.subscribe(async (e) => {
        this.paymentRequestCode = e.data.order.code;
        this.ready = true;
      });

      this.uiService.uiChangedEvent.subscribe((eventName) => {

        if (eventName == "cardOpen") {
          this.setMinimizeForm(true);
        }
      });


    }
    catch (e) {
      await this.uiService.showError(e);
    }
  }

  expandAccordion() {
    this.setMinimizeForm(!this.minimizeForm);
  }

  setMinimizeForm(value) {
      this.minimizeForm = value;

      if (this.minimizeForm) {
        this.uiService.sendUiEvent("salesmanClosed");
      } else {
        this.uiService.sendUiEvent("salesmanOpen");
      }    
  }

  async updateManualApplyCampaigns(value, id){
      var manualRemovedCampaign = !value ? true : false;
      var saleCampaignId = manualRemovedCampaign ? 0 : id;
      this.manualApplyCampaigns = await this.paymentService.getManualApplyCampaigns(this.paymentRequestCode, saleCampaignId, manualRemovedCampaign);
      await this.paymentService.loadPaymentRequest(this.paymentRequestCode);
  }
}
