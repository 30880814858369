import { Injectable } from '@angular/core';
import { PaymentService } from './payment.service';
import { UiService } from 'src/app/services/ui.service';

@Injectable({
  providedIn: 'root'
})
export class PixService {

  constructor(
    private paymentService : PaymentService,
    private uiService: UiService
  ) { }

  async finalizePix(validateTerms: boolean = true, paymentConditionId: number = null){
    if(validateTerms){
      if(await this.validatePix()){
        var result = await this.paymentService.doPixPayment();
        return result;
      }
    }
    var result = await this.paymentService.doPixPayment(paymentConditionId);
    return result;
  }

  async validatePix(){
    if (!this.uiService.termsAccepted) {
      this.uiService.uiChangedEvent.emit('finalizeWithoutTerms');
      return false;
    } else {
      this.uiService.uiChangedEvent.emit('finalizeWithTerms');
    }
    return true;
  }

}
