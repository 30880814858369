<section class="change-payment-method-modal">
    <div class="change-payment-method-modal-header">
        <img alt="icon" src="../../../assets/imagens/icon-payments-pix.png"/>
        <h2>{{ 'changePaymentMethod.title' | translate }}</h2>
    </div>

    <div class="content">
        <p>{{ 'changePaymentMethod.description' | translate }}</p>
        <p>
            <span class="sub-description-first">{{ 'changePaymentMethod.subDescriptionFirst' | translate }} </span>
            <span>{{ 'changePaymentMethod.subDescriptionSecond' | translate }} </span>
            <span class="sub-description-third">{{ 'changePaymentMethod.subDescriptionThird' | translate }} </span>
            <span>{{ 'changePaymentMethod.subDescriptionFourth' | translate }}</span>
        </p>
        <p>{{ 'changePaymentMethod.extraDescription' | translate }}</p>
    </div>

    <div class="btn-confirmation-wrapper">
        <button type="button" class="btn-confirm" [mat-dialog-close]="true">{{ 'changePaymentMethod.confirmButton'  | translate }}</button>
        <button type="button" class="btn-cancel" [mat-dialog-close]="false">{{ 'changePaymentMethod.cancelButton' | translate }}</button>       
    </div>
</section>

