import { Component, OnInit } from '@angular/core';
import { DataLayerService } from 'src/app/services/dataLayer.service';
import { GtmService } from 'src/app/services/gtm.service';
import { PaymentService } from 'src/app/services/payment.service';
import { UiService } from 'src/app/services/ui.service';
import { environment } from 'src/environments/environment';
import { ActivatedRoute } from '@angular/router';
import { PaymentLinkService } from 'src/app/services/payment-link.service';
import { AppIntegrationService, EAppIntegrationPaymentStatus } from 'src/app/services/app-integration.services';
import { APP_AGE } from 'src/app/helpers/constants';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  totalItens: number;
  totalAmount: number;
  finalized: boolean = false;

  ecommerceUrl: string;

  currentEventName: string;
  lastEventName: string;
  dataPaymentLoad: boolean = false;
  done: boolean = false;
  isTotem: boolean;
  flagshipEstablishmentId: number;
  paymentLinkOrigin: string;
  isElos: boolean = false;
  gtmCreated: boolean = false;

  modalTitle: string = 'Checkout';

  constructor(
    private uiService: UiService,
    private paymentService: PaymentService,
    private gtmService: GtmService,
    private dataLayerService: DataLayerService,
    private route: ActivatedRoute,
    private paymentLinkService: PaymentLinkService,
    private appIntegrationService: AppIntegrationService) {
    this.ecommerceUrl = environment.cartUrl;
  }

  async ngOnInit(): Promise<void> {
    this.paymentService.changeHeader.subscribe(paymentLink => {
      this.paymentLinkOrigin = paymentLink ? 'ELOS' : null;
    });

    this.route.queryParams.subscribe(async params => {
      this.isTotem = params['tt'] ? params['tt'] : false;
      if(this.isTotem){
        this.flagshipEstablishmentId = params['id'];
        this.ecommerceUrl = environment.flagshipUrl;
      }
    });

    this.paymentService.dataPayment.subscribe(dataPayment => {
      if (dataPayment && !this.dataPaymentLoad) {
        this.paymentLinkOrigin = dataPayment.order.origin;
        this.dataPaymentLoad = true;
        if (!this.lastEventName && this.currentEventName) {
          this.validateEvent(this.currentEventName);
        }
      }
    });

    this.uiService.uiChangedEvent.subscribe((eventName) => {
      this.validateEvent(eventName);
    });

    this.paymentService.dataChangedEvent.subscribe(async (e) => {
      this.paymentLinkOrigin = e.data.order.origin;
      this.isElos = this.paymentLinkOrigin == "ELOS";

      if (e.event == 'finalize') {
        this.finalized = true;
      }
      if (e.data.order) {
        this.totalItens = e.data.order.itens.length;
        this.totalAmount = e.data.order.totalAmount;
      } else {
        this.totalItens = undefined;
        this.totalAmount = undefined;
      }
    });

    await this.createGTM();
    this.appIntegrationService.modalTitle.subscribe(modalTitle => {
      if(modalTitle) {
        this.modalTitle = modalTitle;
      }
    });
  }

  async createGTM(){
    if(!this.gtmCreated){
      await this.gtmService.createGTM();
      this.gtmCreated = true;
    }
  }

  openSummary() {
    this.uiService.uiChangedEvent.emit('summaryOpen');
  }

  validateEvent(eventName) {
    if (this.finalized || eventName == "done") {
      this.done = true;
      return;
    }
    this.currentEventName = eventName;
    switch (eventName) {
      case 'finalizeStart':
      case 'finalizeError':
        this.lastEventName = eventName;
        break;
      default:
        this.processEvent(eventName);
        break;
    }
  }

  processEvent(eventName) {
    if (eventName == this.lastEventName) return;
    switch (eventName) {
      case 'termsOpen':
        if (this.lastEventName != 'voucherOpen' && this.lastEventName != 'formapgtoNotReady') {
          this.processSteps([1]);
        }
        this.lastEventName = eventName;
        break;
      default:
        this.processDataLayer(eventName);
        break;
    }
  }

  processDataLayer(eventName) {

    let dataPayment = this.paymentService.dataPayment.value;
    if (!dataPayment) return;
    this.dataPaymentLoad = true;

    switch (eventName) {
      case 'termsAccepted':
        this.validateSteps(dataPayment);
        break;
      case 'establishmentOpen':
        if (!this.lastEventName) {
          this.processSteps([1]);
        }
        this.processSteps([2]);
        break;
      case 'voucherOpen':
        if (!this.lastEventName) {
          this.processSteps([1, 2]);
        }
        this.processSteps([3]);
        break;
      case 'formapgtoOpen':
        if (!this.lastEventName) {
          this.processSteps([1, 2, 3]);
        }
        this.processSteps([4]);
        break;
      case 'formapgtoClosed':
        if (!this.lastEventName) {
          this.processSteps([1, 2, 3, 4]);
        }
        this.processSteps([5]);
        break;
      default:
        break;
    }

    this.lastEventName = eventName;
  }

  validateSteps(dataPayment) {
    var step :number;
    if (dataPayment.order.paymentMethodId) {
      step = 5;
    } else {
      step = 4;
    }

    if(!this.isElos)
      this.dataLayerService.createDataLayer(step);
  }

  processSteps(steps: any[]) {
    let dataPayment = this.paymentService.dataPayment.value;
    steps.forEach(step => {
      switch (step) {
        case 3:
          if (steps.length == 1 || (dataPayment && dataPayment.order && dataPayment.order.voucherCode)) {
            if(!this.isElos)
              this.dataLayerService.createDataLayer(step);
          }
          break;
        case 5:
          if (steps.length == 1 || (dataPayment && dataPayment.order && dataPayment.order.paymentMethodId)) {
            if(!this.isElos)
              this.dataLayerService.createDataLayer(step);
          }
          break;
        default:
          if(!this.isElos)
            this.dataLayerService.createDataLayer(step);
          break;
      }
    });
  }

  goBack(){
    this.paymentLinkService.goBack.next(true);
  }

  /* Integração com app de agendamento */
  sendExitToApp() {
    const appIntegrationPaymentStatus = (this.appIntegrationService.currentRoute === 'done')
    ? EAppIntegrationPaymentStatus.Confirmed
    : EAppIntegrationPaymentStatus.Exit;

    this.appIntegrationService.sendToApp(appIntegrationPaymentStatus);
  }

  get hiddenHeader () : boolean {
    return (this.paymentLinkOrigin === APP_AGE || this.modalMode);
  }

  get modalMode () : boolean {
    return this.appIntegrationService.origem === APP_AGE && !this.appIntegrationService.isExternalPayment;
  }
}

