import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { UiService } from 'src/app/services/ui.service';

@Injectable()
export class RequestInterceptor implements HttpInterceptor {

    static inProgressRequests: number = 0;

    constructor(public loaderService: UiService) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

            let requesturl = request.url;

            if (environment.AZURE_FUNCTION_KEY && request.url.indexOf(environment.serviceUrl) >= 0) {
                if (request.url.indexOf('?') !== -1) {
                    requesturl = `${request.url}&code=${environment.AZURE_FUNCTION_KEY}`
                } else {
                    requesturl = `${request.url}?code=${environment.AZURE_FUNCTION_KEY}`
                }

                request = request.clone({
                    url: requesturl
                });
            }

            if (environment.AZURE_FUNCTION_ESTAB_KEY && request.url.indexOf(environment.serviceEstablishmentUrl) >= 0) {
                if (request.url.indexOf('?') !== -1) {
                    requesturl = `${request.url}&code=${environment.AZURE_FUNCTION_ESTAB_KEY}`
                } else {
                    requesturl = `${request.url}?code=${environment.AZURE_FUNCTION_ESTAB_KEY}`
                }

                request = request.clone({
                    url: requesturl
                });
            }

            if (requesturl.indexOf('ValidateToken') === -1) {
                RequestInterceptor.inProgressRequests = RequestInterceptor.inProgressRequests + 1;

                this.loaderService.showLoading();
            }
        
        return next.handle(request).pipe(
            finalize(() => {
                RequestInterceptor.inProgressRequests = RequestInterceptor.inProgressRequests - 1;

                if (RequestInterceptor.inProgressRequests <= 0) {
                    this.loaderService.hideLoading();
                }
            })
        );
    }
}
