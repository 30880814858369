import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { UiService } from '../ui.service';
import { BusinessError } from '../../helpers/BusinessError';
import { TransferStateService } from '../transfer-state.service';
import { Apollo, ApolloBase } from 'apollo-angular';
import gql from 'graphql-tag';

@Injectable({
  providedIn: 'root'
})
export class BaseService {

  baseUrl: string;

  constructor(
    public http: HttpClient,
    public apollo: Apollo,
    public transferState: TransferStateService
  ) {
    this.baseUrl = `${environment.serviceUrl}${environment.defaultTenantName}/${environment.defaultLanguage}`;
  }

  async post(serviceUrl: string, model: any) {

    var data = <any>await this.http.post(`${this.baseUrl}${serviceUrl}`, model).toPromise();

    if (data.error) {
      throw new BusinessError(data.message);
    }

    return data;

  }

  async get(serviceUrl: string, useTransferState: boolean = true) {
    const url = `${this.baseUrl}${serviceUrl}`;

    let data;
    if (useTransferState) {
      data = (await this.transferState.fetch(url, this.http.get(url)).toPromise()) as any;
    } else {
      data = await this.http.get(url).toPromise();
    }

    if (data.error) {
      throw new BusinessError(data.message);
    }

    return data;
  }

  async getFromCMS(key: string, dataQuery: string, use: string = null): Promise<any> {
    let result: any;
    let apolloBase: ApolloBase = this.apollo;

    if (use) {
      apolloBase = this.apollo.use(use);
    }

    try {

      result = await this.transferState.fetch(
        key,
        apolloBase.query({
          query: gql(dataQuery),
          fetchPolicy: 'network-only'
        })
      ).toPromise();

    } catch (e) {
      //existem bugs no squidex onde algumas queries complexas não funcionam via GET.
      //Para não invabilizar o projeto (usar GET para cachear os resultados) temos esse workaround que redireciona a consulta para o modelo POST em caso de erro.
      result = (await this.transferState.fetch(
        key,
        this.apollo.use('post').query({
          query: gql(dataQuery),
          fetchPolicy: 'network-only'
        })
      ).toPromise()) as any;
    }

    return result;
  }

}
