import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { environment } from 'src/environments/environment';
import { UiService } from './services/ui.service';
import { UserIdleService } from 'angular-user-idle';
import { PaymentService } from './services/payment.service';
import { APP_AGE } from './helpers/constants';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'Checkout';

  isLoading: boolean = false;

  isLongLoading: boolean = false;

  isLongLoadingFn: any;

  id: string;

  loadingMessage: string;

  isTotem: boolean;

  flagshipEstablishmentId: number;

  hideFooter: boolean = false;

  constructor(
    private uiService: UiService,
    private route: ActivatedRoute,
    private userIdle: UserIdleService,
    private paymentService: PaymentService) {

    this.uiService.setDefaultLang(environment.defaultLanguage);
    this.uiService.setTenantName(environment.defaultTenantName);
  }

  async ngOnInit() {

    this.paymentService.dataChangedEvent.subscribe((e) => {
      if (e.data) {
          if(e.data.order.origin === 'ELOS'){
            this.hideFooter = true;
          }
      }
    });

    this.paymentService.dataPayment.subscribe(dataPayment => {
      if (dataPayment) {
        setTimeout(() => {
          this.hideFooter = (dataPayment.order.origin === "ELOS" || dataPayment.order.origin === APP_AGE);
        }, 50);
      }
    });

    if (this.uiService.isBrowser) {
      var html = document.getElementById('html-att');
      this.route.queryParams.subscribe(params => {
        this.isTotem = params['tt'] ? params['tt'] : false;
        if (this.isTotem) {
          this.initFlagshipIdle();
          html.className = 'isTotem';
        }
      });
    }

    try {
      this.uiService.onLoading.subscribe((data) => {
        setTimeout(() => {
          if (this.uiService.isBrowser) {
            this.isLoading = data.loading;
            this.loadingMessage = data.message ?? "Por favor, aguarde...";

            if (data.loading) {
              if (!this.isLongLoadingFn) {
                this.isLongLoadingFn = setTimeout(() => {
                  this.isLongLoading = true;
                }, 2500);
              }
            } else {
              if (this.isLongLoadingFn) {
                clearTimeout(this.isLongLoadingFn);
                this.isLongLoadingFn = null;
              }

              this.isLongLoading = false;
            }
          }
        });
      });
    }
    catch (e) {
      await this.uiService.showError(e);
    }
  }

  initFlagshipIdle() {
    if (this.uiService.isBrowser) {
      this.userIdle.startWatching();
      // Start watching when user idle is starting.
      this.userIdle.onTimerStart().subscribe(count => {

      });

      this.userIdle.onTimeout().subscribe(() => {
        //  limpar carrinho
        window.location.href = environment.flagshipUrl;
      });
    }
  }

}
