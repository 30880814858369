<div class="display-card" *ngIf="displayCard">
  <div class="totais">
    <div class="totais-info">
      <label
        [innerHTML]="'creditCard.inCreditCard' | translate"
      ></label>
      <label
        ><span>{{ "currencySymbol" | translate }}</span>
        {{ displayData?.totalValueFormatted }}</label
      >
    </div>
    <div class="total">
      <label
        >{{ "total" | translate }}<br />{{
          "currencySymbol" | translate
        }}
        {{ displayData?.totalValueFormatted }}</label
      >
    </div>
  </div>
  <div class="card">
    <div class="card-item">
      <div class="div-img">
        <img
          [src]="'../../../../assets/brands/white-' + displayData.brandImage"
          class="card-flag"
          aria-label="image card flag"
        />
      </div>
      <label
        >{{ firstSixReplace() }} **** ****
        {{ displayData?.lastFour }}</label
      >
      <label class="card-name">{{ displayData?.name }}</label>
      <label>{{ displayData?.validade }}</label>
    </div>
  </div>
</div>
