import { Injectable, EventEmitter } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { BusinessError } from '../helpers/BusinessError';
import { TranslateService } from '@ngx-translate/core';
import { CreditCard } from '../model/credit-card';
import { ElosgateService } from './elosgate.service';
import { UiService } from './ui.service';
import { OrganizationalStructure } from '../model/organizationalStructure';
import { BehaviorSubject } from 'rxjs';
import { BaseService } from './base/base.service';
import {  Router } from '@angular/router';
import { EstablishmentService } from './establishment.service';
import { TransferStateService } from './transfer-state.service';
import { MatDialog } from '@angular/material/dialog';
import { PaymentErrorComponent } from '../components/modal/payment-error/payment-error.component';
import { EncrypterService } from './encrypter.service';
import { MultiplePaymentsModel } from '../model/payments/multiple-payments.model';
import { PaymentRequestStatusEnum } from '../model/payments/payment-request-status.enum';
import { CheckMultiplePaymentsModel } from '../model/payments/check-multiple-payments.model';
import { DefaultResult } from '../model/default-result';
import { ValidateMultiplePaymentsExpirationTimeModel } from '../model/payments/validate-multiple-payments-expiration-time.model';

@Injectable({
  providedIn: 'root'
})
export class PaymentService extends BaseService{

  dataChangedEvent: EventEmitter<any> = new EventEmitter();

  finalizedEvent: EventEmitter<any> = new EventEmitter();

  public dataPayment: BehaviorSubject<any> = new BehaviorSubject<any>(undefined);
  public changeHeader: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  public dataPaymentLink = new BehaviorSubject<any>(null);

  finalizePurchase = new BehaviorSubject<boolean>(false);

  savePaymentMethodAndCondition = new BehaviorSubject<number>(null);

  data: any;

  showPartialDone: EventEmitter<any> = new EventEmitter();

  goToPix: EventEmitter<any> = new EventEmitter();

  constructor(public http: HttpClient,
    public uiService: UiService,
    public transferState: TransferStateService,
    private translate: TranslateService,
    private elosGateService: ElosgateService,
    private router: Router,
    private establishmentService: EstablishmentService,
    private dialog: MatDialog) {
      super(http, null, transferState);
      this.elosGateService.onError.subscribe((errors) => {

        this.uiService.hideLoading();

        this.uiService.showMessage({
          title: 'Ops',
          html: errors[0]
        });
      });

      //o tratamento do sucesso só serve para atualizar em tela as informações da venda, por trás, no servidor
      //o sistema receberá um callback da ELOSGATE com a confirmação da venda, ele quem aciona o callback dos sistemas
      //que usam o checkout para notificar que a venda foi efetuada com sucesso.
      this.elosGateService.onSucess.subscribe((data) => {

        if (data.Sucesso) {

          this.finalizedEvent.emit(data);

          this.finalize();
        }
      });

      //em caso de multiplas formas de pagamento, após confirmação do pagamento em cartão, devemos exibir a tela intermediária para pagamento via PIX
      this.elosGateService.multiplePaymentsSucess.subscribe((data) => {

        if (data.Sucesso) {

            this.showPartialDone.emit(true);
        }
      });
  }

  /**
   * Carrega os dados do pagamento pelo id
   * @param id
   * @param source
   */
  async loadPaymentRequest(id: any, isPaymentLink: boolean = false, isAppOrigem: boolean = false): Promise<any> {

    if (!id) {
      throw new BusinessError(await this.translate.get("invalidRequest").toPromise());
    }

    if(isPaymentLink && !isAppOrigem){
      var decrypted = EncrypterService.decrypt(id);
      if(decrypted){
        id = decrypted;
      }else{
        this.router.navigate([`/solicitacao-nao-encontrada`]);
        return;
      }
    }

    let result = await this.get(`/Interface/GetData/${id}/${isPaymentLink}`, true);
    if (isPaymentLink) {
      let status = result.status ? result.status as PaymentRequestStatusEnum : PaymentRequestStatusEnum.notExists;
      if (status == PaymentRequestStatusEnum.notExists ||
        status == PaymentRequestStatusEnum.canceled ||
        status == PaymentRequestStatusEnum.finalized ||
        status == PaymentRequestStatusEnum.deleted) {
        this.router.navigate([`/solicitacao-nao-encontrada`]);
        return;
      }
      else
        this.data = result.data;
    }
    else
      this.data = result;

    this.dataChangedEvent.emit({
      data: this.data,
      event: 'loadPayment'
    });

    if (environment.production == false) {
      console.log(this.data.order);
    }

    parent.postMessage(this.data.order, "*");

    this.dataPayment.next(this.data)

    return this.data;
  }

  /**
   * Carrega os dados do pagamento pelo documento do cliente
   * @param document
   * @param source
   */
  async loadPaymentRequestByDocument(document: any): Promise<any> {

    if (!document) {
      throw new BusinessError(await this.translate.get('invalidRequest').toPromise());
    }

    this.data = await this.get(`/Interface/GetDataByDocument/${document}`, true);

    this.dataChangedEvent.emit({
      data: this.data,
      event: 'loadPayment'
    });

    if (environment.production == false) {
      console.log(this.data.order);
    }

    parent.postMessage(this.data.order, "*");

    return this.data;
  }

  /**
   * Passa pelo passo do voucher, informando ou não um código
   * @param id
   * @param voucherCode
   */
  async saveVoucher(voucherCode: string, isBudget?: boolean, budgetId?: number) {

    if (!this.data) {
      throw new BusinessError(await this.translate.get("invalidRequest").toPromise());
    }

    this.data = await this.post('/Interface/SaveVoucher', { code: this.data.order.code, voucherCode: voucherCode, isBudget: isBudget, budgetId: budgetId });

    this.dataChangedEvent.emit({
      data: this.data,
      event: 'saveVoucher'
    });

    if (environment.production == false) {
      console.log(this.data.order);
    }

    parent.postMessage(this.data.order, "*");
  }

  async saveEstablishment(establishment: OrganizationalStructure) {

    if (!this.data) {
      throw new BusinessError(await this.translate.get("invalidRequest").toPromise());
    }

    this.data = await this.post('/Interface/SaveEstablishment', { code: this.data.order.code, establishmentId: establishment.Id });

    this.dataChangedEvent.emit({
      data: this.data,
      event: 'saveVoucher'
    });

    if (environment.production == false) {
      console.log(this.data.order);
    }

    parent.postMessage(this.data.order, "*");
  }

  /**
   * Grava a forma e a condição de pagamento selecionadas
  */
  async savePaymentMethod(paymentMethodId, paymentConditionId) {
    if (!this.data) {
      throw new BusinessError(await this.translate.get("invalidRequest").toPromise());
    }

    if(this.data.order.origin === 'ELOS'){
      var validateResult = await this.validatePaymentRequestVersion();
      if(!validateResult.versionsMatch){
        var modalResult = await this.showWrongVersionModal();
        if(modalResult){
            window.location.reload();
        }
        return { outdated: true };
      }
    }

    let result = await this.post('/Interface/SavePaymentMethod', { code: this.data.order.code, paymentMethodId: paymentMethodId, paymentConditionId: paymentConditionId});


    this.data.order = result;


    this.dataChangedEvent.emit({
      data: this.data,
      event: 'savePaymentMethod'
    });

    if (environment.production == false) {
      console.log(this.data.order);
    }

    parent.postMessage(this.data.order, "*");
  }

  /**
   * Grava a forma de pagamento selecionada
   */
  async selectPaymentMethod(paymentMethodId) {
    if (!this.data) {
      throw new BusinessError(await this.translate.get("invalidRequest").toPromise());
    }

    if(this.data.order.origin === 'ELOS'){
      var validateResult = await this.validatePaymentRequestVersion();
      if(!validateResult.versionsMatch){
        var modalResult = await this.showWrongVersionModal();
        if(modalResult){
            window.location.reload();
        }
        return { outdated: true };
      }
    }

    let result = await this.post('/Interface/SelectPaymentMethod', { code: this.data.order.code, paymentMethodId: paymentMethodId });

    this.data.order = result;

    this.dataChangedEvent.emit({
      data: this.data,
      event: 'savePaymentMethod'
    });

    if (environment.production == false) {
      console.log(this.data.order);
    }

    parent.postMessage(this.data.order, "*");
  }

  /**
   * Retrocede um pagamento para o passo anterior
   * @param id
   */
  async goBack(id: string) {

    this.data.order = await this.post('/Interface/GoBack', { code: id });

    this.dataChangedEvent.emit({
      data: this.data,
      event: 'goBack'
    });

    if (environment.production == false) {
      console.log(this.data.order);
    }

    parent.postMessage(this.data.order, "*");
  }

  /**
   * Efetua um pagamento com o ELOSGATE
   * @param model - dados do cartão
   */
  async doCreditCardPayment(model: CreditCard, isTotem: boolean, tokenData: any, paymentConditionId: number = null, multiplePaymentMethods: MultiplePaymentsModel = null, paymentMethodId: number = null) {
    if (!this.data) {
      throw new BusinessError(await this.translate.get("invalidRequest").toPromise());
    }
    else if(!this.data.order.structureId || !this.validateOrgStructure()){
      throw new BusinessError(await this.translate.get('selectEstablishment').toPromise());
    }

    //primeiro faz a batida no servidor para comunicar com o ELOSGATE e fazer a solicitação do pagamento
    var transaction = await this.getElosGateCode(this.data.order.code, paymentConditionId, multiplePaymentMethods, paymentMethodId);

    if(transaction && transaction.alreadyPaid){
      this.router.navigate([`/done/${transaction.alreadyPaid}`]);
      return;
    }

    if (transaction.notEnoughtPoints) {
      this.uiService.showToastr(await this.translate.get("notEnoughPoints").toPromise(), 'Ops', 'warning');
      setTimeout(() => {
        this.loadPaymentRequest(this.data.order.code);
      }, 2000);
      return;
    }

    if (!isTotem) {
      if (environment.useTokenex){
        //utiliza o serviço do ELOSGATE para transacionar o cartão
        await this.elosGateService.registrarPagamento(transaction, model, tokenData, multiplePaymentMethods != null);
      } else {
        await this.elosGateService.registrarPagamentoOld(transaction, model);
      }
    } else {
      return transaction;
    }

    //os retornos serão tratados nos eventos:
    //this.elosGateService.onError
    //this.elosGateService.onSuccess
    //eles estão assinados no construtor da PaymentService
  }

   /**
   * Indica para o servidor que a biblioteca do elosgate deu ok para a passada do cartão
   * @param id
   */
  async finalize() {

    if (!this.data) {
      throw new BusinessError(await this.translate.get("invalidRequest").toPromise());
    }

    this.data = await this.post('/Interface/Finalize', { code: this.data.order.code });

    this.dataChangedEvent.emit({
      data: this.data,
      event: 'finalize'
    });

    if (environment.production == false) {
      console.log(this.data.order);
    }

    parent.postMessage(this.data.order, "*");
  }

   /**
   * Carrega os dados do pagamento pelo id
   * @param id
   * @param source
   */
  async loadPaymentRequestByExternalOrder(externalOrder: string): Promise<any> {

    if (!externalOrder) {
      throw new BusinessError(await this.translate.get("invalidRequest").toPromise());
    }

    this.data = await this.get(`/Interface/GetDataByExternalOrder/${externalOrder}`);


    if (environment.production == false) {
      console.log(this.data.order);
    }

    this.dataPayment.next(this.data)

    return this.data;
  }

  async getBudgetIntegration(externalOrder: string): Promise<any> {

    if (!externalOrder) {
      throw new BusinessError(await this.translate.get("invalidRequest").toPromise());
    }

    this.data = await this.get(`/Interface/BudgetIntegration/${externalOrder}`);

    return this.data;
  }

  async saveSalesman(salemasnId: number) : Promise<any> {
    if (!this.data) {
      throw new BusinessError(await this.translate.get("invalidRequest").toPromise());
    }

    var validated = await this.post('/Interface/SaveFlagshipSalesman', { code: this.data.order.code, salesmanId: salemasnId });
    return validated;

  }

  async saveFlagshipOrigin() : Promise<any>{
    if (!this.data) {
      throw new BusinessError(await this.translate.get("invalidRequest").toPromise());
    }

      var data = this.post('/Interface/SaveFlagshipOrderOrigin', { code: this.data.order.code });
      return data;
  }


  setPaymentLink(obj: any){
    this.dataPaymentLink.next(obj);
  }

  async flagshipSendPaymentEmail(url: string) : Promise<any>{
    if (!this.data) {
      throw new BusinessError(await this.translate.get("invalidRequest").toPromise());
    }

     return await this.post('/Interface/FlagshipSendPaymentEmail', { code: this.data.order.code, url: url });

  }

  async flagshipUpdateClientEmail(newEmail: string) : Promise<any>{
    if (!this.data) {
      throw new BusinessError(await this.translate.get("invalidRequest").toPromise());
    }

      var data = this.post('/Interface/FlagshipUpdateClientEmail', { code: this.data.order.code, newEmail:  newEmail});
      return data;
  }

  async getManualApplyCampaigns(code: any, manualAppliedCampaignId: number = 0, manualRemovedCampaign: boolean = false): Promise<any> {

    if (!code) {
      throw new BusinessError(await this.translate.get("invalidRequest").toPromise());
    }

    const result = await this.get(`/Interface/GetManualApplyCampaigns/${code}/${manualAppliedCampaignId}/${manualRemovedCampaign}`, true);

    return result.manualAppliedCampaigns;
  }

  async finalizeZeroValuePayment(){

    if (!this.data) {
      throw new BusinessError(await this.translate.get("invalidRequest").toPromise());
    } else if (!this.data.order.structureId || !this.validateOrgStructure()){
      throw new BusinessError(await this.translate.get('selectEstablishment').toPromise());
    }

    this.data.order = await this.post('/Interface/FinalizeZeroValuePayment', { code: this.data.order.code });

    this.dataChangedEvent.emit({
      data: this.data,
      event: 'finalizeZeroValuePayment'
    });
  }

  async saveGiftItems(selectedGifts){

    if (!this.data) {
      throw new BusinessError(await this.translate.get("invalidRequest").toPromise());
    }

    this.data.order = await this.post('/Interface/SaveGiftMethod', { code: this.data.order.code, selectedGifts: selectedGifts });

    this.dataChangedEvent.emit({
      data: this.data,
      event: 'saveGiftItems'
    });
  }

  async removeGifts(){

    if (!this.data) {
      throw new BusinessError(await this.translate.get("invalidRequest").toPromise());
    }

    this.data.order = await this.post('/Interface/RemoveGifts', { code: this.data.order.code });

    this.dataChangedEvent.emit({
      data: this.data,
      event: 'removeGifts'
    });
  }

  async applyAffiliate(utmCampaign: string) : Promise<any>{
    if (!this.data) {
      throw new BusinessError(await this.translate.get("invalidRequest").toPromise());
    }
    this.data.order = await this.post('/Interface/ApplyAffiliate', { code: this.data.order.code, utmCampaign: utmCampaign });
    this.dataChangedEvent.emit({
      data: this.data
    });
  }

  async removeAffiliate() : Promise<any>{
    if (!this.data) {
      throw new BusinessError(await this.translate.get("invalidRequest").toPromise());
    }

    this.data.order = await this.post('/Interface/removeAffiliate', { code: this.data.order.code });

    this.dataChangedEvent.emit({
      data: this.data
    });

  }

  async checkForAppliedGifts() : Promise<any>{
    if (!this.data) {
      throw new BusinessError(await this.translate.get("invalidRequest").toPromise());
    }
    return await this.get(`/Interface/checkForAppliedGifts/${this.data.order.code}`);
  }

  async removeOrgStruct() : Promise<any>{
    if (!this.data) {
      throw new BusinessError(await this.translate.get("invalidRequest").toPromise());
    }

    await this.post('/Interface/RemoveOrgStruct', { code: this.data.order.code });
  }

  /**
   * Função para verificar se a unidade ainda se encontra disponível (com canal) para finalizar o pedido.
   * @returns
   */
  private async validateOrgStructure(){
    let filterAffiliateAllowedEstablishments = this.data.order.affiliate_Id && this.data.order.affiliate_Id > 0 ? true : false;
    var model = await this.establishmentService.getEstablishment(this.data.order.structureId, filterAffiliateAllowedEstablishments);
    return model != null;
  }

  async doPixPayment(paymentConditionId: number = null){
    if (!this.data) {
      throw new BusinessError(await this.translate.get("invalidRequest").toPromise());
    }
    return await this.getElosGateCode(this.data.order.code, paymentConditionId);
  }

  private async getElosGateCode(code, paymentConditionId: number = null, multiplePaymentMethods: MultiplePaymentsModel = null, paymentMethodId: number = null) {
    var transaction = await this.post('/Interface/GetElosgateCode',
    {
      code: code,
      paymentConditionId: paymentConditionId,
      multiplePaymentMethods: multiplePaymentMethods,
      paymentMethodId: paymentMethodId
    });
    return transaction;
  }

  async obterChavesCapturaCartao(environment: string){
    if (!this.data) {
      throw new BusinessError(await this.translate.get("invalidRequest").toPromise());
    }

    return await this.get(`/ElosGate/ObterChavesCapturaCartao/${this.data.order.code}/${environment}`);

  }

  async validatePaymentRequestVersion(){
    if (!this.data) {
      throw new BusinessError(await this.translate.get("invalidRequest").toPromise());
    }

    var result = await this.post(`/Interface/ValidatePaymentRequestVersion`, { code: this.data.order.code, version: this.data.order.version });
    return result;

  }

  async checkMultiplePayments(): Promise<CheckMultiplePaymentsModel> {
    var result = await this.get(`/Interface/CheckMultiplePayments`);
    return result as CheckMultiplePaymentsModel;
  }

  async showWrongVersionModal() {
    var response = await this.uiService.showMessage({
      title: await this.uiService.translate('wrongVersion.title'),
      showCancelButton: false,
      focusConfirm: false,
      confirmButtonText: await this.uiService.translate('OK'),
      html: await this.uiService.translate('wrongVersion.description'),
    });

    return response;
  }

  async insertRefusedPaymentRequestEntry(paymentConditionId: number, reloadWindow: boolean = true){
    if (!this.data) {
      throw new BusinessError(await this.translate.get("invalidRequest").toPromise());
    }

    await this.post(`/Interface/InsertRefusedPaymentRequestEntry`, { code: this.data.order.code, paymentConditionId });

    this.showPaymentErrorModal(reloadWindow);
  }

  showPaymentErrorModal(reloadWindow: boolean = true){
    var dialogRef = this.dialog.open(PaymentErrorComponent, {
      width: '322px'
    });

    dialogRef.afterClosed().subscribe(async ()  => {
      if(reloadWindow)
        window.location.reload();
    });
  }

  async checkPaymentStatus(){
    if (!this.data) {
      throw new BusinessError(await this.translate.get("invalidRequest").toPromise());
    }

    var result = await this.get(`/Interface/CheckPaymentStatus/${this.data.order.code}`);
    return result;
  }

  async cancelPayment(){
    if (!this.data) {
      throw new BusinessError(await this.translate.get("invalidRequest").toPromise());
    }

    var result = await this.get(`/Interface/CancelPayment/${this.data.order.code}`);
    return result;
  }

  async validateMultiplePaymentsExpirationTime(): Promise<ValidateMultiplePaymentsExpirationTimeModel>{
    if (!this.data) {
      throw new BusinessError(await this.translate.get("invalidRequest").toPromise());
    }

    var result = (await this.get(`/Interface/ValidateMultiplePaymentsExpirationTime/${this.data.order.code}`)) as DefaultResult<ValidateMultiplePaymentsExpirationTimeModel>;
    return result.data;
  }

  async getEstablishmentData(id: number): Promise<any> {

    let establishmentData = await this.get(`/Interface/GetEstablishmentData/${id}`);
    if(establishmentData.error){
      throw new BusinessError(await this.translate.get("invalidRequest").toPromise());
    }
    return establishmentData.data;
  }

   async anyQuizAnswered(clientDocument: string): Promise<boolean> {
    let result = await this.get(`/Interface/AnyQuizAnswered/${clientDocument}`);
     if (result.error) {
       throw new BusinessError(await this.translate.get("invalidRequest").toPromise());
     }
     return result.data as boolean;
  }

  async displayModalGoToMyBudgets(){
    var modalResult = await this.showWrongVersionModal();
    if(modalResult){
      const orderUrl = `${environment.ecommerceUrl}/cliente/painel?meusOrcamentos=true`;
      window.location.href = orderUrl;
    }
  }
}
