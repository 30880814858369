import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { DoneComponent } from './pages/done/done.component';
import { NotFoundComponent } from './pages/not-found/not-found.component';
import { PaymentComponent } from './pages/payment/payment.component';
import { PaymentLinkComponent } from './pages/payment/flagship/payment-link/payment-link.component';
import { BudgetComponent } from './pages/budget/budget.component';
import { BudgetNotFoundComponent } from './pages/budget/budget-not-found/budget-not-found.component';
import { CreditCardExternalComponent } from './pages/payment/credit-card-external/credit-card-external.component';


const routes: Routes = [
  { path: 'done/:id', component: DoneComponent },
  { path: 'orcamento/:id', component: BudgetComponent },
  { path: 'orcamento/:origem/:data', component: BudgetComponent },
  { path: 'solicitacao-nao-encontrada', component: BudgetNotFoundComponent },
  { path: 'payment-link/:code/:payment-code', component: PaymentLinkComponent },
  { path: ':id', component: PaymentComponent },
  { path: 'credit-card-external/:data', component: CreditCardExternalComponent },
  { path: '**', component: NotFoundComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    initialNavigation: 'enabled'
})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
