import { trigger, state, style, transition, animate } from '@angular/animations';
import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { CheckoutCmsService } from 'src/app/services/cms/checkoutCms.service';
import { PaymentService } from 'src/app/services/payment.service';
import { UiService } from 'src/app/services/ui.service';

@Component({
  selector: 'app-terms',
  templateUrl: './terms.component.html',
  styleUrls: ['./terms.component.scss'],
  animations: [
    trigger('openClose', [
      // ...
      state('open', style({
        height: '*',
        opacity: 1,
        display: 'block',
        width: '100%'
      })),
      state('closed', style({
        height: '0px',
        padding: '0px',
        opacity: 0,
        display: 'none',
        width: '100%'
      })),
      transition('open <=> closed', [
        style({ display: 'block', opacity: 0.5 }),
        animate('0.2s')
      ])
    ]),
  ]
})
export class TermsComponent implements OnInit {

  public finalizeWithoutTerms: boolean = false;

  constructor(
    public uiService: UiService,
    private translateService: TranslateService,
    private checkoutCmsService: CheckoutCmsService,
    private paymentService: PaymentService,
    public dialog: MatDialog) { }

  ngOnInit(): void {
    this.uiService.uiChangedEvent.subscribe((eventName) => {

      if (eventName == "finalizeWithoutTerms") {
       this.finalizeWithoutTerms = true;
      }
      if (eventName == "finalizeWithTerms") {
       this.finalizeWithoutTerms = false;
      }
    });
  }

  async termsAndConditions() {

    this.dialog.open(DialogContentTerm, {
      data: {
        title: await this.translateService.get('termsAndConditions').toPromise(),
        html: (await this.checkoutCmsService.getData()).termoCondicoes
      },
      width: '90%',
      maxWidth: '700px',
      maxHeight: '90vh',
      autoFocus: false
    });
  }

  markTermAccepted(e) {
    setTimeout(() => {

      if (this.uiService.termsAccepted) {
        this.uiService.uiChangedEvent.emit('termsAccepted');
      } else {
        this.uiService.uiChangedEvent.emit('termsNotAccepted');
      }

    });
  }
}

@Component({
  selector: 'term-dialog',
  templateUrl: 'term-dialog.html',
  styleUrls: ['./term-dialog.scss'],
})
export class DialogContentTerm {
  constructor(
    public dialogRef: MatDialogRef<DialogContentTerm>,
    @Inject(MAT_DIALOG_DATA) public data: any) { }

    close() {
      this.dialogRef.close();
    }
}