<section class="sec-pix pix-container">
    <div class="grid grid-pix">
        <h2>{{'pix.pix' | translate}}</h2>
        <div class="total-payment" (click)="goNext()">
            <p>
                <span class="pix-installment">1x {{'creditCard.outOf' | translate}} {{'currencySymbol' | translate }}</span>
                <span>&nbsp;{{paymentConditionSelected.total | currency:'BRL':'':'1.2-2'}}&nbsp;&nbsp;</span>
                <span *ngIf="paymentMethodSelected.showDiscountPercent && paymentMethodSelected.bestDiscount" class="discount-tag pix-tag">{{paymentMethodSelected.bestDiscount}}% OFF</span>
            </p>
            <div class="img-block">
                <img src="../../../../../assets/imagens/icon-arrow.png"/>
            </div>
        </div>
    </div>    
</section>