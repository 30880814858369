<!-- BOX RESUMO -->
<div class="detail" *ngIf="paymentLinkOrigin !== 'ELOS'">
    <!-- TITULO -->

     <!-- QUANTIDADE DE ITENS NO CARRINHO -->
     <ng-template [ngIf]="itens">

        <div class="expand-cart-itens">
            <span class="qtd-carrinho">
                {{ itens.length }} {{ (itens.length <= 1 ? 'item' : 'items') | translate }} {{ 'inTheCart' | translate }}
            </span>
            <img class="qtd-carrinho-expand" [src]="'../../../assets/imagens/' + (minimizeForm ? 'arrow-down.png' : 'arrow-up.png')" (click)="expandCart()" />
        </div>
        <div [@openClose]="minimizeForm ? 'closed' : 'open'">
            <!-- ITEM PEDIDO -->
            <div class="item-carrinho" *ngFor="let item of itens">
                <!-- IMG SERVICO -->
                <div class="img"><img [src]="item.image" /></div>
                <!-- DESC PEDIDO  -->
                <div class="desc">
                    <!-- TITULO -->
                    <p>{{ item.description }}</p>
                </div>
            </div>
        </div>
        <!-- FIM ITEM -->

        <!-- OBSERVACAO -->
        <p class="item-carrinho-obs"><small><span class="obrig">*</span> {{'promotionalItemsDescription' | translate}}</small></p>

    </ng-template>

    <div class="campaigns-wrapper" *ngIf="appliedCampaigns && appliedCampaigns.length > 0 || giftItemCampaigns && giftItemCampaigns.length > 0">
        <div>
            <span class="reached-campaigns-title">
                {{'reachedPromotions' | translate }}
            </span>
            <img class="qtd-carrinho-expand" [src]="'../../../assets/imagens/' + (minimizeCampaignForm ? 'arrow-down.png' : 'arrow-up.png')" (click)="expandCampaigns()" />
        </div>
        <div [@openClose]="minimizeCampaignForm ? 'closed' : 'open'">
            <div *ngIf="appliedCampaigns && appliedCampaigns.length > 0">
                <div class="item-campaign" *ngFor="let item of appliedCampaigns">
                    <div class="desc">
                        <span matTooltip="{{'exclusiveSaleCampaignInformation' | translate}}" *ngIf="item.isExclusive" class="star"></span>
                        <span matTooltip="{{'notExclusiveSaleCampaignInformation' | translate}}" *ngIf="!item.isExclusive" class="star grey"></span>
                        <p>{{item.salesCampaignDescription}}</p>
                    </div>
                </div>
            </div>
            <div *ngIf="giftItemCampaigns && giftItemCampaigns.length > 0">
                <div class="item-campaign"  *ngFor="let item of giftItemCampaigns">
                    <div class="desc">
                        <span matTooltip="{{'exclusiveSaleCampaignInformation' | translate}}" *ngIf="item.isExclusive" class="star"></span>
                        <span matTooltip="{{'notExclusiveSaleCampaignInformation' | translate}}" *ngIf="!item.isExclusive" class="star grey"></span>
                        <p>{{item.description}}</p>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="campaigns-wrapper" *ngIf="avaliablePromotions && avaliablePromotions.length > 0 ">
        <div>
            <span class="reached-campaigns-title">
                {{'avaliablePromotions' | translate }}
            </span>
            <img class="qtd-carrinho-expand" [src]="'../../../assets/imagens/' + (minimizeCampaignForm ? 'arrow-down.png' : 'arrow-up.png')" (click)="expandCampaigns()" />
        </div>
        <div [@openClose]="minimizeCampaignForm ? 'closed' : 'open'">
            <div class="item-campaign"  *ngFor="let item of avaliablePromotions">
                <div class="desc">
                    <span matTooltip="{{'exclusiveSaleCampaignInformation' | translate}}" *ngIf="item.isExclusive" class="star"></span>
                    <span matTooltip="{{'notExclusiveSaleCampaignInformation' | translate}}" *ngIf="!item.isExclusive" class="star grey"></span>
                    <p>{{item.description}}</p>
                </div>
            </div>
        </div>
    </div>

    <!-- TABELA VALORES -->
    <table class="price-resumo">
        <!-- LINHA DA TABELA COM SUB TOTAL -->
        <tr class="sub">
            <th class="mark">{{'subTotal' | translate }}</th>
            <td class="amount">
                <span class="price">{{ subTotal | currency:'BRL':'symbol':'1.2-2' }}</span>
            </td>
        </tr>
        <!-- FIM LINHA -->
         <!-- LINHA DA TABELA COM DESCONTO VOUCHER -->
         <tr class="sub" *ngIf="voucherCode && (voucherDiscount || voucherDiscountValue)">
            <th class="mark">Voucher ({{voucherCode}})</th>
            <td class="amount">
                <span class="price" *ngIf="voucherDiscount && !voucherDiscountValue"> -{{ voucherDiscount | currency:'BRL':'symbol':'1.2-2' }}</span>
                <span class="price" *ngIf="voucherDiscountValue"> -{{ voucherDiscountValue | currency:'BRL':'symbol':'1.2-2' }}</span>
            </td>
        </tr>
        <!-- FIM LINHA -->
        <!-- LINHA TABELA COM OPC DE PAGAMENTO -->
        <tr class="sub" *ngIf="totalDiscount && totalDiscount > 0">
            <th class="mark">
                {{ discountText }}
            </th>
            <td class="amount">
                <span class="price">-{{ totalDiscount | currency:'BRL':'symbol':'1.2-2' }}</span>
            </td>
        </tr>
        <!-- FIM LINHA -->
        <!-- LINHA TOTAL DO PEDIDO -->
        <tr class="total">
            <th class="mark">
                <strong>Total</strong>
            </th>
            <td>
                <!-- VALOR TOTAL PEDIDO -->
                <strong><span class="price">{{ total | currency:'BRL':'symbol':'1.2-2' }}</span></strong>
            </td>
        </tr>
        <!-- FIM LINHA -->
    </table>
    <!-- FIM TABELA VALORES -->
</div>

<div class="payment-link-summary-container" *ngIf="paymentLinkOrigin === 'ELOS'">
    <div class="client-name">
        <img src="../../../assets/imagens/icon-user.png"/>
        <p>{{clientName}}</p>
    </div>
    <div class="payment-link-establishment">
        <img src="../../../assets/imagens/icon-estab.png"/>
        <div class="payment-link-establishment-text-block">
            <p class="tenant-name">{{tenantName}}</p>
             <p class="establishment-name">{{'unit' | translate }}: <span>{{establishmentName}}</span></p>
        </div>
    </div>

    <div class="payment-link-items-container">
        <span class="item-count" *ngIf="itensPaymentLink && itensPaymentLink.length > 0">
            {{itensPaymentLink.length}} {{itensPaymentLink.length > 1 ?  'itens' : 'item'}}
        </span>
        <div class="clear"></div>
        <div class="payment-link-item" *ngFor="let item of itensPaymentLink">
            <!-- IMG SERVICO -->
            <div *ngIf="!item.image" class="img-placeholder"></div>
            <div class="img">
                <img *ngIf="item.image" [src]="item.image" type="image/jpg" />
                <!-- <object [data]="item.image" type="image/jpeg"></object> -->
            </div>
            <!-- DESC PEDIDO  -->
            <div class="desc" [ngClass]="{'desc-without-gender': !item.showGender && !item.isGift}">
                <!-- TITULO -->
                <p class="item-name">{{ item.name }}</p>
                <div class="item-subtitle"><p class="item-gender" *ngIf="item.showGender">{{ (item.restrictedGender == 1 ? 'feminine' : 'masculine') | translate }}</p>
                    <div class="item-gift-container" *ngIf="item.isGift"> <span class="item-gift">{{'gift' | translate}}</span></div>
                </div>
                <p class="item-description">{{ item.description }}</p>
            </div>
        </div>
        <div class="clear"></div>
    </div>

    <div *ngIf="voucher" class="voucher">
        <div class="voucher-title">{{ 'voucherUsed' | translate}}:</div>

        <div class="voucher-container">
            <div class="left-section">
                <div class="icon-container">
                <div class="icon">
                    <img src="../../../assets/imagens/icon-price.png"/>
                </div>
                </div>
            </div>
            <div class="right-section">
                <div class="title">{{ voucher?.code }}</div>
                <div class="info-section">
                <div class="discount-container">
                    <div class="discount-value">{{ voucher?.percentValue }}%</div>
                </div>
                <div class="price-section">
                    <span class="currency-symbol">{{ 'currencySymbol' | translate }}</span>
                    <span class="price-hyphen"> -</span>
                    <span class="price">{{ voucher?.value }}</span>
                </div>
                </div>
            </div>
        </div>
    </div>

    <div class="payment-history" *ngIf="payments && payments.length > 0">
        <div class="payment-history-summary">
            <img src="../../../assets/imagens/icon-dollar.png"/>
            <div class="payment-history-summary-text-block">
                <p class="payment-history-summary-title">{{'previousPayments.alreadyPaid' | translate}}</p>
                <p class="payment-history-summary-value">{{'currencySymbol' | translate }} {{alreadyPaid | currency: translateService.instant('currency') : '' : '1.2-2'}}</p>
            </div>
            <div class="details-block">
                <a (click)="showPaymentHistory()"> + {{'creditCard.details' | translate}}</a>
            </div>
        </div>

    </div>

    <div class="payment-link-total-price">
        <p>{{'total' | translate }}</p>
        <p>
            {{ total | currency:'BRL':'symbol':'1.2-2' }}<span class="asterisk">*</span>
        </p>
        <div class="clear"></div>
    </div>

</div>