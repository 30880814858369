import { BrowserModule } from '@angular/platform-browser';
import { LOCALE_ID, NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HeaderComponent } from './components/header/header.component';
import { FooterComponent } from './components/footer/footer.component';

import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import {CommonModule, CurrencyPipe, registerLocaleData} from '@angular/common';
import localePt from '@angular/common/locales/pt';
import { TranslateUniversalLoader } from './helpers/TranslateUniversalLoader';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { PaymentComponent } from './pages/payment/payment.component';
import { SummaryComponent } from './components/summary/summary.component';
import { VoucherComponent } from './pages/payment/voucher/voucher.component';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatInputModule} from '@angular/material/input';
import {MatIconModule} from '@angular/material/icon';
import {MatDialogModule} from '@angular/material/dialog';
import {MatRadioModule} from '@angular/material/radio';
import {MatListModule} from '@angular/material/list';
import {MatButtonModule} from '@angular/material/button';
import { MatGridListModule } from '@angular/material/grid-list';
import { FormapgtoComponent } from './pages/payment/formapgto/formapgto.component';
import { ParcelasComponent } from './pages/payment/formapgto/parcelas/parcelas.component';
import { CardComponent } from './pages/payment/card/card.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NotFoundComponent } from './pages/not-found/not-found.component';
import { DoneComponent } from './pages/done/done.component';
import { GraphQLModule } from './graphql.module';
import { CmsRequestInterceptor } from './helpers/interceptors/cms.interceptor';
import { TermsComponent } from './pages/payment/terms/terms.component';
import { SelectEstablishmentComponent } from './components/select-establishment/select-establishment.component';
import {MatSelectModule} from '@angular/material/select';
import {MatDividerModule} from '@angular/material/divider';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { EstablishmentComponent } from './pages/payment/establishment/establishment.component';
import { RequestInterceptor } from './helpers/interceptors/request.interceptor';
import { UserIdleModule } from 'angular-user-idle';
import { environment } from 'src/environments/environment';
import { SalesmanComponent } from './pages/payment/salesman/salesman.component';
import { ToastrModule } from 'ngx-toastr';
import { PaymentLinkComponent } from './pages/payment/flagship/payment-link/payment-link.component';
import {MatTooltipModule} from '@angular/material/tooltip';
import { ManualApplyCampaignsComponent } from './pages/payment/manual-apply-campaigns/manual-apply-campaigns.component';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { ZeroValuePaymentComponent } from './pages/payment/zero-value-payment/zero-value-payment.component';
import { AffiliatesComponent } from './pages/payment/affiliates/affiliates.component';
import { GiftItemComponent } from './pages/payment/gift-item/gift-item.component';
import {TransferHttpCacheModule} from '@nguniversal/common';
import {BrowserTransferStateModule} from '@angular/platform-browser';
import { FinalizePaymentComponent } from './pages/payment/finalize-payment/finalize-payment.component';
import { StepHeaderComponent } from './pages/payment/stepHeader/stepHeader.component';
import { PixComponent } from './pages/payment/pix/pix.component';
import { CreditCardComponent } from './pages/payment/credit-card/credit-card.component';
import { PaymentSummaryComponent } from './pages/payment/payment-summary/payment-summary.component';
import { CreditCardDisplayComponent } from './pages/payment/credit-card/credit-card-display/credit-card-display.component';
import { SelectPaymentMethodComponent } from './pages/payment/select-payment-method/select-payment-method.component';
import { PixConfirmationComponent } from './pages/payment/pix/pix-confirmation/pix-confirmation.component';
import { BudgetComponent } from './pages/budget/budget.component';
import { PreviousPaymentsComponent } from './components/previous-payments/previous-payments.component';
import { TippyDirective } from './directives/tippy.directive';
import { PaymentErrorComponent } from './components/modal/payment-error/payment-error.component';
import { PartialDoneComponent } from './pages/budget/partial-done/partial-done.component';
import { RecurrentInfoComponent } from './components/modal/recurrent-info/recurrent-info.component';
import { PixValueFormComponent } from './pages/payment/pix/pix-value-form/pix-value-form.component';
import { CurrencyMaskModule } from "ng2-currency-mask";
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import { DefaultModalComponent } from './components/modal/default-modal/default-modal.component';
import { ChangePaymentMethodModalComponent } from './components/modal/change-payment-method-modal/change-payment-method-modal.component';
import { MultiplePaymentsConfirmModalComponent } from './components/modal/multiple-payments-confirm-modal/multiple-payments-confirm-modal.component';
import { BudgetNotFoundComponent } from './pages/budget/budget-not-found/budget-not-found.component';
import { CreditCardExternalComponent } from './pages/payment/credit-card-external/credit-card-external.component';
import { CreditCardExternalDisplayComponent } from './pages/payment/credit-card-external/credit-card-external-display/credit-card-external-display.component';
import { PreventDoubleClickDirective } from 'src/app/helpers/preventDoubleClick';
registerLocaleData(localePt)

export function createTranslateLoader() {
    return new TranslateUniversalLoader();
}

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    FooterComponent,
    PaymentComponent,
    SummaryComponent,
    VoucherComponent,
    FormapgtoComponent,
    ParcelasComponent,
    CardComponent,
    NotFoundComponent,
    DoneComponent,
    TermsComponent,
    SelectEstablishmentComponent,
    EstablishmentComponent,
    SalesmanComponent,
    PaymentLinkComponent,
    ManualApplyCampaignsComponent,
    ZeroValuePaymentComponent,
    AffiliatesComponent,
    GiftItemComponent,
    FinalizePaymentComponent,
    StepHeaderComponent,
    PixComponent,
    CreditCardComponent,
    PaymentSummaryComponent,
    CreditCardDisplayComponent,
    SelectPaymentMethodComponent,
    PixConfirmationComponent,
    BudgetComponent,
    PreviousPaymentsComponent,
    TippyDirective,
    PaymentErrorComponent,
    PartialDoneComponent,
    RecurrentInfoComponent,
    PixValueFormComponent,
    DefaultModalComponent,
    ChangePaymentMethodModalComponent,
    MultiplePaymentsConfirmModalComponent,
    BudgetNotFoundComponent,
    CreditCardExternalComponent,
    CreditCardExternalDisplayComponent,
    PreventDoubleClickDirective
  ],
  imports: [
    FormsModule,
    ReactiveFormsModule,
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    HttpClientModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MatProgressSpinnerModule,
    MatFormFieldModule,
    MatInputModule,
    MatIconModule,
    MatDialogModule,
    MatRadioModule,
    MatListModule,
    MatGridListModule,
    MatButtonModule,
    MatSelectModule,
    NgxMatSelectSearchModule,
    MatDividerModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: (createTranslateLoader),
        deps: [HttpClient]
      }
    }),
    GraphQLModule,
    UserIdleModule.forRoot({idle: Number(environment.flagshipIdleTimeoutSeconds), timeout: 5, ping: 120}),
    ToastrModule.forRoot(),
    MatTooltipModule,
    MatCheckboxModule,
    TransferHttpCacheModule,
    BrowserTransferStateModule,
    CurrencyMaskModule,
    MatSlideToggleModule,
    CommonModule
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: RequestInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: CmsRequestInterceptor, multi: true },
    { provide: LOCALE_ID, useValue: "pt-BR" },
    CurrencyPipe
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
