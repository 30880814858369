<section *ngIf="paymentLinkOrigin !== 'ELOS'" [hidden]="!ready || total <= 0" class="sec-formapgto">
    <h2 class="section-title">{{'paymentWays' | translate }}</h2>
    <mat-action-list>
        <mat-radio-group name="paymentMethods" [(ngModel)]="model.paymentMethod" >
            <button mat-list-item class="paymentMethod-item" *ngFor="let paymentMethod of paymentMethods; let i = index" (click)="selectPm(paymentMethod)" >
                <mat-radio-button [value]="paymentMethod.id">
                    <span class="paymentmethod-wrapper">
                        <span class="paymentmethod-name"><span>{{ paymentMethod.name }}</span></span>
                        <span *ngIf="paymentMethod.creditCardType != 3" class="paymentmethod-icon"><img alt="icon-card" src="../../../../assets/imagens/icon-card.png"/></span>
                        <span *ngIf="paymentMethod.creditCardType == 3" class="paymentmethod-icon paymentmethod-icon-pix"><img alt="icon-card" src="../../../../assets/imagens/icon-pix.png"/></span>
                    </span>
                </mat-radio-button> 
            </button>
        </mat-radio-group>
    </mat-action-list>
</section>