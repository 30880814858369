import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { gql } from 'apollo-angular';
//import gql from 'graphql-tag';
import { BehaviorSubject } from 'rxjs';

const queryById = `{
	queryOrganizationalStructureContents(filter: "data/Id/iv eq :ID"){
    flatData{
      id,
      url,
      phones
    }
  }
}`;


@Injectable({
  providedIn: 'root'
})
export class EstablishmentCmsService {

  routes: any;
  public routesStore: BehaviorSubject<any> = new BehaviorSubject<any>(undefined);

  constructor(private apollo: Apollo) { }

  async getEstablishmentById(id: number): Promise<any[]> {

    var result = await this.apollo.use("getSharedContent").watchQuery<any>({
      query: gql(queryById.replace(":ID", `${id}`))
    }).result();
    
    return result.data.queryOrganizationalStructureContents.map(q => {

      return {
        id: q.flatData.id,
        url: q.flatData.url,
        phones: q.flatData.phones
      };
    });
  }

}