<section *ngIf="paymentLinkOrigin !== 'ELOS' && !modalMode" class="sec-finalizacao-pedido">
    <div class="grid">
        <div class="desc">

            <div>
                <app-stepHeader stepId="4" ></app-stepHeader>
            </div>

            <h1>{{ title }}</h1>

            <div class="div-img-order" *ngIf="environments.thank_you_page_payment_confirm">
                <img [src]="imageOrder"/>
            </div>

            <!-- NUMERO DO PEDIDO -->
            <p *ngIf="paymentConfirmed" class="order-number" style="font-size: large;" >{{ 'descriptionOrderNumber' | translate }}<br/><a [href]="orderUrl">#{{ orderCode }}.</a></p>


            <div class="desc-app">
                <p style="font-size: large;"  @loadFormTrigger>{{ description }}</p>
                <p style="font-size: large;">{{ descriptionApp }}</p>
            </div>

            <div class="div-logos">
                <div *ngIf="displayUrlAndroid" class="img-logo-left">
                    <a target="_blank" [href]="urlAndroid">
                        <img src="../../../assets/imagens/LogoGooglePlay.png">
                    </a>
                </div>
                <div *ngIf="displayUrlIos" class="img-logo-right">
                    <a target="_blank" [href]="urlIos">
                        <img src="../../../assets/imagens/LogoAppStore2.png">
                    </a>
                </div>
            </div>

            <div class="done-btn-wrapper">
                <a *ngIf="showPhoneWhatsApp" target="_blank" [href]="'https://wa.me/' + this.phoneWhatsApp + '?text=' + this.messageWhatsApp" class="button btn-checkout-done btn-talk-to-the-store">
                    <i class="bi bi-whatsapp"></i>
                    {{'speakEstablishment' | translate}}
                </a>
                <button *ngIf="showScheduleButton"
                    [disabled]="!paymentConfirmed"
                    class="button btn-checkout-done btn-schedule"
                    (click)="goToSchedule()">
                    {{'scheduleSession' | translate}}
                </button>
            </div>
            <div class="desc-app desc-awaiting-payment"
                *ngIf="showScheduleButton && awaitingPaymentMessage && !paymentConfirmed">
                <p>{{awaitingPaymentMessage}}</p>
            </div>

            <div class="desc-app desc-health-profile" *ngIf="enableHealthProfile">
              <p>{{'healthProfileMessage' | translate}}</p>
            </div>

            <button *ngIf="enableHealthProfile"
                class="button btn-health-profile"
                (click)="goToHealthProfile()">
                {{ 'doHealthProfile' | translate }}
              </button>
        </div>
    </div>
</section>

<section *ngIf="paymentLinkOrigin === 'ELOS' && !modalMode" class="sec-done-elos">
  <div class="grid">
    <div class="done-elos-content">
          <h1>{{ title }}</h1>

          <div class="div-img-order" *ngIf="environments.thank_you_page_payment_confirm && displaySvgCheck">
            <svg class="svg-main" width="178" height="110" viewBox="0 0 178 162" fill="none" xmlns="http://www.w3.org/2000/svg">

              <circle cx="88" cy="79" r="26" fill="#42D293" class="svg-elem-1"></circle>

              <rect x="111.378" y="59.1309" width="8.34783" height="50.0869" rx="4.17391" transform="rotate(45 111.378 59.1309)"
                fill="white" class="svg-elem-2"></rect>

              <rect x="60.7832" y="79.0737" width="8.34783" height="30.2327" rx="4.17391" transform="rotate(-45 60.7832 79.0737)"
                fill="white" class="svg-elem-3"></rect>

              <path d="M42.6465 38.3535L21.6465 17.3535" stroke="#42D293" stroke-width="4" stroke-linecap="round"
                class="svg-elem-4 svg-elem"></path>

              <path d="M132 38L153 17" stroke="#42D293" stroke-width="4" stroke-linecap="round" class="svg-elem-5 svg-elem">
              </path>

              <path d="M132.354 126L153.354 147" stroke="#42D293" stroke-width="4" stroke-linecap="round"
                class="svg-elem-6 svg-elem"></path>

              <path d="M153 80H176" stroke="#42D293" stroke-opacity="0.5" stroke-width="4" stroke-linecap="round"
                class="svg-elem-7 svg-elem"></path>

              <path d="M2 80H25" stroke="#42D293" stroke-opacity="0.5" stroke-width="4" stroke-linecap="round"
                class="svg-elem-8 svg-elem"></path>

              <path d="M90 17V2" stroke="#42D293" stroke-opacity="0.5" stroke-width="4" stroke-linecap="round"
                class="svg-elem-9 svg-elem"></path>

              <path d="M90 160V145" stroke="#42D293" stroke-opacity="0.5" stroke-width="4" stroke-linecap="round"
                class="svg-elem-10 svg-elem"></path>

              <path d="M44 128.354L23 149.354" stroke="#42D293" stroke-width="4" stroke-linecap="round"
                class="svg-elem-11 svg-elem"></path>

            </svg>
          </div>

          <!-- NUMERO DO PEDIDO -->
          <p class="order-number" style="font-size: large;" >{{ 'descriptionOrderNumber' | translate }}<br/><span>#{{ orderCode }}.</span></p>

          <div class="done-divider"></div>

          <div *ngIf="useCustomHtml" [innerHTML]="customHtml"></div>

          <div *ngIf="!useCustomHtml" class="desc-app">
              <p style="font-size: large;">{{ descriptionApp }}</p>
          </div>

          <div *ngIf="!useCustomHtml" class="div-logos">
              <div *ngIf="displayUrlAndroid" class="img-logo-left">
                  <a target="_blank" [href]="urlAndroid">
                      <img src="../../../assets/imagens/LogoGooglePlay.png">
                  </a>
              </div>
              <div *ngIf="displayUrlIos" class="img-logo-right">
                  <a target="_blank" [href]="urlIos">
                      <img src="../../../assets/imagens/LogoAppStore2.png">
                  </a>
              </div>
          </div>

          <div class="done-btn-wrapper">
              <a *ngIf="showPhoneWhatsApp" target="_blank" [href]="'https://wa.me/' + this.phoneWhatsApp + '?text=' + this.messageWhatsApp" class="button btn-checkout-done btn-talk-to-the-store">
                  <i class="bi bi-whatsapp"></i>
                  {{'speakEstablishment' | translate}}
              </a>
          </div>
        </div>
      </div>
</section>

<!-- SECTION PARA INTEGRAÇÃO COM APP -->
<section *ngIf="modalMode" class="sec-done-elos">
  <div class="grid">
    <div class="done-elos-content">
          <div class="div-img-order" *ngIf="environments.thank_you_page_payment_confirm && displaySvgCheck">
            <svg class="svg-main" width="178" height="110" viewBox="0 0 178 162" fill="none" xmlns="http://www.w3.org/2000/svg">

              <circle cx="88" cy="79" r="26" fill="#42D293" class="svg-elem-1"></circle>

              <rect x="111.378" y="59.1309" width="8.34783" height="50.0869" rx="4.17391" transform="rotate(45 111.378 59.1309)"
                fill="white" class="svg-elem-2"></rect>

              <rect x="60.7832" y="79.0737" width="8.34783" height="30.2327" rx="4.17391" transform="rotate(-45 60.7832 79.0737)"
                fill="white" class="svg-elem-3"></rect>

              <path d="M42.6465 38.3535L21.6465 17.3535" stroke="#42D293" stroke-width="4" stroke-linecap="round"
                class="svg-elem-4 svg-elem"></path>

              <path d="M132 38L153 17" stroke="#42D293" stroke-width="4" stroke-linecap="round" class="svg-elem-5 svg-elem">
              </path>

              <path d="M132.354 126L153.354 147" stroke="#42D293" stroke-width="4" stroke-linecap="round"
                class="svg-elem-6 svg-elem"></path>

              <path d="M153 80H176" stroke="#42D293" stroke-opacity="0.5" stroke-width="4" stroke-linecap="round"
                class="svg-elem-7 svg-elem"></path>

              <path d="M2 80H25" stroke="#42D293" stroke-opacity="0.5" stroke-width="4" stroke-linecap="round"
                class="svg-elem-8 svg-elem"></path>

              <path d="M90 17V2" stroke="#42D293" stroke-opacity="0.5" stroke-width="4" stroke-linecap="round"
                class="svg-elem-9 svg-elem"></path>

              <path d="M90 160V145" stroke="#42D293" stroke-opacity="0.5" stroke-width="4" stroke-linecap="round"
                class="svg-elem-10 svg-elem"></path>

              <path d="M44 128.354L23 149.354" stroke="#42D293" stroke-width="4" stroke-linecap="round"
                class="svg-elem-11 svg-elem"></path>

            </svg>
          </div>
          <!-- NUMERO DO PEDIDO -->
          <p class="order-number" style="font-size: large;" >{{ 'descriptionOrderNumber' | translate }}<br/><span>#{{ orderCode }}.</span></p>
          <div class="done-divider"></div>
          <div class="done-btn-wrapper">
              <a *ngIf="showPhoneWhatsApp" (click)="openExternalLink()" class="button btn-checkout-done btn-talk-to-the-store">
                  <i class="bi bi-whatsapp"></i>
                  {{'speakEstablishment' | translate}}
              </a>
          </div>
          <section *ngIf="modalMode" class="sec-modal-mode">
            <button
              [disabled]="!paymentConfirmed"
              class="button btn-modal-mode btn-modal-mode-schedule"
              (click)="sendToApp(appIntegrationPaymentStatus.GoToSchedule)">
              {{'scheduleSession' | translate}}
            </button>

            <button
              class="button btn-modal-mode btn-modal-mode-back-home"
              (click)="sendToApp(appIntegrationPaymentStatus.Confirmed)">
              {{'done.backToHome' | translate}}
            </button>
          </section>
        </div>
      </div>
</section>
