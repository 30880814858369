import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import gql from 'graphql-tag';
import { BehaviorSubject } from 'rxjs';


const chatQuery = `
{
    queryChatContents {
      flatData {
        whatsappMessage,
        whatsappRelationshipCenter
      }
    }
  }`;


@Injectable({
  providedIn: 'root'
})
export class ChatCmsService {

  public data: BehaviorSubject<any> = new BehaviorSubject<any>(undefined);

  constructor(private apollo: Apollo) {

  }

  async getData(): Promise<any> {

    var result = await this.apollo.watchQuery<any>({
      query: gql(chatQuery)
    }).result();

    var data = result.data.queryChatContents.map(q => {
      return {
        whatsappMessage: q.flatData.whatsappMessage,
        whatsappRelationshipCenter: q.flatData.whatsappRelationshipCenter
      }
    })[0];

    this.data.next(data);

    return data;

  }

}
