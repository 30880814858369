import { Component, OnInit, Renderer2 } from '@angular/core';
import { trigger, state, style, transition, animate } from '@angular/animations';
import { UiService } from 'src/app/services/ui.service';
import { PaymentService } from 'src/app/services/payment.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-affiliates',
  templateUrl: './affiliates.component.html',
  styleUrls: ['./affiliates.component.scss'],
  animations: [
    trigger('openClose', [
      // ...
      state('open', style({
        height: '*',
        display: 'block',
        opacity: 1
      })),
      state('closed', style({
        height: '0px',
        padding: '0px',
        display: 'none',
        opacity: 0
      })),
      transition('open <=> closed', [
        style({ display: 'block', opacity: 0.5 }),
        animate('0.2s')
      ])
    ]),
  ]
})
export class AffiliatesComponent implements OnInit {
  ready: boolean = false;

  minimizeForm: boolean = true;

  validated: boolean = false;

  id: number = null;

  utmCampaign: string;

  description: string;

  affiliateApplied: boolean = false;

  constructor(
    private uiService: UiService,
    private paymentService: PaymentService,
    private translate: TranslateService,
    private renderer: Renderer2) { }

  async ngOnInit() {
    try {
      this.paymentService.dataChangedEvent.subscribe(async (e) => {
        this.ready = true;        
        
        this.utmCampaign = e.data.order.affiliate_UTMCampaign
        if(this.utmCampaign){
          this.affiliateApplied = true;
        }
        
        if (e.data.order.paymentMethodId && !this.affiliateApplied) {
          this.description = await this.translate.get("noAffiliateApplied").toPromise();
        } else {
          this.description = null;
        }

      });

      this.uiService.uiChangedEvent.subscribe((eventName) => {

        if (eventName == "cardOpen") {
          this.setMinimizeForm(true);
        }
      });


    }
    catch (e) {
      await this.uiService.showError(e);
    }
  }

  expandAccordion() {
    this.setMinimizeForm(!this.minimizeForm);
  }

  setMinimizeForm(value) {
      this.minimizeForm = value;

      if (this.minimizeForm) {
        this.uiService.sendUiEvent("salesmanClosed");
      } else {
        this.uiService.sendUiEvent("salesmanOpen");
      }    
  }

  async applyAffiliate(){

    if(!this.utmCampaign){
      this.uiService.showMessage({
        title: 'Ops..',
        html: await this.translate.get("affiliateIsRequired").toPromise()
      });
      this.renderer.selectRootElement('#affiliateId').focus();
      return;
    }

    await this.paymentService.applyAffiliate(this.utmCampaign);

    if(this.utmCampaign){
      this.affiliateApplied = true;
      this.setMinimizeForm(true);
    }else{
      this.uiService.showMessage({
        title: 'Ops..',
        html: await this.translate.get("affiliateNotFound").toPromise()
      });
    }
  }

  async removeAffiliate(){
    await this.paymentService.removeAffiliate();
    if(!this.utmCampaign){
      this.affiliateApplied = false;
      this.renderer.selectRootElement('#affiliateId').focus();
    }
  }

}
