import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import gql from 'graphql-tag';
import { BaseService } from '../base/base.service';
import { TransferStateService } from '../transfer-state.service';

const pacoteServicosByRangeIdQuery = `
{
	queryPacoteServicosContents(filter: "data/Id/iv in (:RANGE_ID)"){
    flatData{
      id,
      ecommerceDescription,
      images {  url },
      price,
      mainPrice,
      categoria {
        flatData {
          description
        }
      },
      areas {
        flatData {
          description
        }
      },
      genero {
        flatData {
          description
        }
      }
    }
  }
}`;

@Injectable({
  providedIn: 'root'
})
export class PacoteServicoService extends BaseService {

  public conheceAreas: any;

  constructor(apollo: Apollo, transferState: TransferStateService) {
    super(null, apollo, transferState);
  }

  async getPacoteServicosByRangeId(ids: Number[]): Promise<any> {

    const pacoteServicoKey = `pacote-servico-${ids.join(',')}`;

    const result = await this.getFromCMS(
      pacoteServicoKey,
      pacoteServicosByRangeIdQuery.replace(":RANGE_ID", `${ids.join(',')}`));

    return result.data.queryPacoteServicosContents.map(q => {
      return {
        id: q.flatData.id,
        description: q.flatData.ecommerceDescription,
        image: q.flatData.images ? q.flatData.images[0].url : null,
        price: q.flatData.price,
        mainPrice: q.flatData.mainPrice,
        categoria: q.flatData.categoria[0].flatData.description,
        areas: q.flatData.areas[0].flatData.description,
        genero: q.flatData.genero[0].flatData.description,
      }
    });
  }
}
