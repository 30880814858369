import { Component, ElementRef, OnInit, Renderer2, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { PaymentService } from 'src/app/services/payment.service';
import { UiService } from 'src/app/services/ui.service';
import { trigger, state, style, transition, animate } from '@angular/animations';
import { PacoteServicoService } from 'src/app/services/cms/pacoteServico.service';

@Component({
  selector: 'app-voucher',
  templateUrl: './voucher.component.html',
  styleUrls: ['./voucher.component.scss'],
  animations: [
    trigger('openClose', [
      // ...
      state('open', style({
        height: '*',
        opacity: 1,
        display: 'block'
      })),
      state('closed', style({
        height: '0px',
        padding: '0px',
        opacity: 0,
        display: 'none'
      })),
      transition('open <=> closed', [
        style({ display: 'block', opacity: 0.5 }),
        animate('0.2s')
      ])
    ]),
  ]
})
export class VoucherComponent implements OnInit {

  ready: boolean = false;
  applyBudgetVoucher: boolean = false;
  count: number = 0;
  budgetVoucherRemoved = false;
  public model: any = {
    haveCoupon: "",
    couponCodeDescription: "",
    couponCode: "",
    voucherApplied: false,
    minimizeForm: true,
    isBudget: false,
    budgetId: null,
    voucherCode: null
  };
  packageVoucherItens: any[] = [];

  constructor(
    private paymentService: PaymentService,
    private uiService: UiService,
    private translate: TranslateService,
    private renderer: Renderer2,
    private pacoteServicoService: PacoteServicoService) {

  }

  async ngOnInit() {
    try {
      this.paymentService.dataChangedEvent.subscribe(async (e) => {

        let packageVoucherItensIds = [];
        this.packageVoucherItens = [];
        e.data.order.itens.forEach(item =>{
          if(item.voucherDiscountCouponId){
            this.packageVoucherItens.push(item); 
            packageVoucherItensIds.push(item.id)           
          }
        });

        if(this.packageVoucherItens && this.packageVoucherItens.length > 0){
          let cmsItems = await this.pacoteServicoService.getPacoteServicosByRangeId(packageVoucherItensIds);

          this.packageVoucherItens.forEach(item => {
            item.description = cmsItems.filter(w => w.id == item.id)[0]?.description;
            item.discountPercent = Math.round(((item.voucherValue / item.priceTableValue) * 100) * 100) / 100;
          });
        }

        this.model.isBudget = e.data.order.isBudget;
        this.model.budgetId = e.data.order.budgetId;
        if (e.data) {
          this.setMinimizeForm(true);
          
          if (e.data.order.voucherCode && !e.data.order.isBudget) {
            this.model.haveCoupon = "true";
            this.model.couponCode = e.data.order.voucherCode;
            this.model.voucherApplied = true;


            this.model.couponCodeDescription = this.model.couponCode;
          } else {
            this.model.haveCoupon = "";
            this.model.couponCode = "";
            this.model.voucherApplied = false;

            if (e.data.order.paymentMethodId) {
              this.model.couponCodeDescription = await this.translate.get("noCouponApplied").toPromise();
            } else {
              this.model.couponCodeDescription = "";
            }
            if(e.data.order.isBudget && e.data.order.voucherCode){
              this.model.couponCodeDescription = e.data.order.voucherCode;
              this.model.couponCode = e.data.order.voucherCode;
              this.model.voucherApplied = true;
              this.budgetVoucherRemoved = false;
              
              if(this.count <= 0){
                await this.applyVoucher();
                this.count++;
              }
            }

          }

          this.ready = true;
        }
        else {
          this.ready = false;
        }
      });



      this.uiService.uiChangedEvent.subscribe((eventName) => {

        if (eventName == "cardOpen") {
          this.setMinimizeForm(true);
        }
      });
    }
    catch (e) {
      await this.uiService.showError(e);
    }


  }

 

  async tryRemoveVoucher() {
    if (this.model.voucherApplied && this.model.haveCoupon == "") {
      try {
        this.model.couponCode = "";

        await this.paymentService.saveVoucher(this.model.couponCode);
      }
      catch (e) {
        await this.uiService.showError(e);
      }
    }
  }

  async applyVoucher() {
    try {
      if (this.model.couponCode == "" && this.model.voucherApplied == false)
      {
        this.uiService.showMessage({
          title: 'Ops..',
          html: await this.translate.get("couponCodeIsRequired").toPromise()
        });

        this.renderer.selectRootElement('#inputCouponCode').focus();

        return;
      }

      await this.paymentService.saveVoucher(this.model.couponCode, this.model.isBudget, this.model.budgetId);

    }
    catch (e) {
      await this.uiService.showError(e);
      this.model.couponCode = null;
    }
  }

  async removeVoucher() {

    try {
      this.model.couponCode = "";
      if(this.model.isBudget){
        this.budgetVoucherRemoved = true;
      }

      await this.paymentService.saveVoucher(this.model.couponCode);
    }
    catch (e) {
      await this.uiService.showError(e);
    }
  }

  setMinimizeForm(value) {

    this.model.minimizeForm = value;

    if (this.model.minimizeForm) {
      this.uiService.sendUiEvent("voucherClosed");
    } else {
      this.uiService.sendUiEvent("voucherOpen");
    }
  
  }

  expandAccordion() {
    this.setMinimizeForm(!this.model.minimizeForm);

    if (!this.model.minimizeForm && !this.model.voucherApplied) {
      setTimeout(() => {
        this.renderer.selectRootElement('#inputCouponCode').focus();
      }, 300);

    }
  }
}
