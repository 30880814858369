import { Component, OnInit, ViewChild, Input, ElementRef, OnDestroy, Output, EventEmitter } from '@angular/core';
import { OrganizationalStructure } from 'src/app/model/organizationalStructure';
import { FormControl } from '@angular/forms';
import { ReplaySubject, Subject } from 'rxjs';
import { MatSelect } from '@angular/material/select';
import { takeUntil } from 'rxjs/operators';
import { LocationService } from 'src/app/services/location.service';
import { environment } from 'src/environments/environment';
import { EstablishmentService } from 'src/app/services/establishment.service';

const weekdays = new Array(
  "Domingo", "Segunda", "Terça", "Quarta", "Quinta", "Sexata", "Sábado"
);

@Component({
  selector: 'app-select-establishment',
  templateUrl: './select-establishment.component.html',
  styleUrls: ['./select-establishment.component.scss']
})
export class SelectEstablishmentComponent implements OnInit, OnDestroy {

  @Input() model: OrganizationalStructure;

  @Output() modelChange: EventEmitter<OrganizationalStructure> = new EventEmitter<OrganizationalStructure>();

  @Input() filterAffiliateAllowedEstablishments: boolean;

  unidades: OrganizationalStructure[] = [];

  /** control for the selected bank */
  selectUnidadeCtrl: FormControl = new FormControl();

  /** control for the MatSelect filter keyword */
  selectUnidadeFilterCtrl: FormControl = new FormControl();

  /** list of banks filtered by search keyword */
  filteredUnidades: ReplaySubject<OrganizationalStructure[]> = new ReplaySubject<OrganizationalStructure[]>(1);

  @ViewChild('selectUnidade', { static: true }) selectUnidade: MatSelect;

  /** Subject that emits when the component has been destroyed. */
  protected _onDestroy = new Subject<void>();

  lat: number = -23.6094149;
  lng: number = -46.6695226;
  userLat: number;
  userLng: number;
  zoom: number = 12;
  userPosition: boolean = false;
  mapReady: boolean = false;
  usesGeolocation: boolean;

  fnFilterEstablishment: any;

  @ViewChild('search') public searchElementRef: ElementRef;

  constructor(
    private locationService: LocationService,
    private establishmentService: EstablishmentService
  ) { 
    this.usesGeolocation = environment.usesGeolocation;

  }

  async ngOnInit() {
    
    // escuta o campo de pesquisa afim de acionar o filtro da lista de unidades
    this.selectUnidadeFilterCtrl.valueChanges
      .pipe(takeUntil(this._onDestroy))
      .subscribe(() => {
        this.filterUnidades();
      });
  }

  async initialize() {
    await this.loadUserPosition();
  }
  
  /**
   * Obtem a tradução do dia da semana
   * @param daysOfWeek lista que representa o dia da semana de 0 a 6 (se passado mais de um valor, o retorno é no formado "Domingo à Sábado")
   */
  getWeekDay(daysOfWeek: number[]) {
    if (!daysOfWeek || !daysOfWeek.length) return '';
    let fDay = weekdays[daysOfWeek[0]];
    let lDay = daysOfWeek.length > 1 ? ' à ' + weekdays[daysOfWeek[daysOfWeek.length - 1]] : '';
    return `${fDay}${lDay}`;
  }

  /**
   * Transforma a descrição, trazendo apenas a última partícula após um traço
   * Ex. SP - SÃO PAULO - MOEMA, vira MOEMA
   * @param description Descrição da unidade
   */
  getEstablishmentDescription(description: string) {

    if (!description) return description;

    var i = description.lastIndexOf('-');

    if (i > 0) {
      return description.substr(i+1).trim();
    }

    return description;
  }

  /**
   * Faz a selecão da unidade e comunica o Evento a quem usar o componente
   * @param selected unidade selecionada
   */
  selecionarUnidade(selected: OrganizationalStructure) {

    this.model = selected;

    this.modelChange.next(this.model);

    this.lat = selected.AddressLat;
    this.lng = selected.AddressLng;
  }

  ngOnDestroy() {
    this._onDestroy.next();
    this._onDestroy.complete();
  }

  /**
   * Método responsável pelo filtro por texto das unidades.
   * Primeiro tenta encontrar o termo digitado na lista em memória.
   * Se não achar, envia o termo para o servidor para buscar remoto.
   */
  protected async filterUnidades() {
    
    if (this.fnFilterEstablishment) {
      clearTimeout(this.fnFilterEstablishment);
    }

    let search = this.selectUnidadeFilterCtrl.value;
    
    if (!search) {
      this.filteredUnidades.next(this.unidades.slice());
      return;
    } else {
      search = search.toLowerCase();
    }

    var filter = this.unidades.filter(unidade => unidade.Description.toLowerCase().indexOf(search) > -1); 

    if (filter.length) {
      this.filteredUnidades.next(filter);
    } else {
      this.fnFilterEstablishment = setTimeout(async () => {
        this.unidades = await this.establishmentService.getEstablishmentsByTerms(search, this.filterAffiliateAllowedEstablishments);
        this.filteredUnidades.next(this.unidades.slice());  
      }, 700);
    }
  }

  /**
   * Carrega a posição atual do usuário solicitando a geo-localização
   */
  async loadUserPosition() {

    try {

      if (environment.usesGeolocation) {

        var pos = await this.locationService.getPosition();

        this.userPosition = true;
        this.userLat = pos.lat;
        this.userLng = pos.lng;
        this.lat = pos.lat;
        this.lng = pos.lng;
        this.loadEstablishments();
      } else {
        this.userPosition = false;
      }
    }
    catch (e) {
      this.userPosition = false;
    }
    finally {
      this.mapReady = true;
    }
  }

  /**
   * Carrega as unidades com base na posição atual do usuário.
   * A lista de unidades vem do servidor
   */
  async loadEstablishments() {

    this.unidades = await this.establishmentService.getEstablishments(this.lat, this.lng, this.filterAffiliateAllowedEstablishments);

    this.filteredUnidades.next(this.unidades.slice());
  }
}
