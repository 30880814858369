import { Injectable, EventEmitter } from '@angular/core';
import { CreditCard } from '../model/credit-card';
import { RegistraPagamentoInterface } from '../model/registra-pagamento-interface';
import { ElosgateApiService } from './elosgate.api.service';
import { UiService } from './ui.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ElosgateService {

  public onSucess = new EventEmitter();
  public onError = new EventEmitter();
  public onRejected = new EventEmitter();
  public multiplePaymentsSucess = new EventEmitter();

  private transacao: RegistraPagamentoInterface;

  public model: CreditCard;

  public tokenData: any;

  constructor(private api: ElosgateApiService,
    private uiService: UiService) {
    this.api.setAmbiente(environment.elosGateEnvironment);
  }

  obterMarca(numero): string {

    numero = numero.replace(" ", "");

    const visa = /^4[0-9]/;
    const master = /^5[1-5]/;
    const amex = /^3[47]/;
    const diners = /^3(?:0[0-5]|[68][0-9])/;
    const discovery = /^6(?:011|5)/;
    const jcb = /^((2131)|(1800)|(35))/;
    const elo = /^(((636368)|(438935)|(636369)|(504175)|(451416)|(636297)|(506699)))|((5067)|(4576)|(4011))/;
    const hipercard = /^(606282)|(3841)/;
    const aura = /^50[0-9]/;

    return hipercard.test(numero) ? 'hipercard' :
      elo.test(numero) ? 'elo' :
        jcb.test(numero) ? 'jcb' :
          aura.test(numero) ? 'aura' :
            discovery.test(numero) ? 'discover' :
              diners.test(numero) ? 'diners' :
                amex.test(numero) ? 'amex' :
                  master.test(numero) ? 'master' :
                    visa.test(numero) ? 'visa' : 
                      'none';
  }

  async registrarPagamento(transacao: RegistraPagamentoInterface, model: CreditCard, tokenData: any, ignoreFinalize: boolean = false) {

    this.transacao = transacao;
    this.model = model;
    this.tokenData = tokenData;

    this.model.numero = this.model.numero.replace(/ /g, "");

    let agora = new Date();
    let mes =
      ("00" + agora.getFullYear()).slice(-2) +
      ("00" + (agora.getMonth() + 1)).slice(-2);
    let mesInformado: string;

    let erroValidacao = [];

    if (this.model.validade)
      mesInformado = this.model.validade.substring(3, 5) + this.model.validade.substring(0, 2);

    //RETORNO DE ERRO
    if (!this.model.nome)
      erroValidacao.push("Informe o nome do tilular do cartão");
    if (!this.model.validade)
      erroValidacao.push("Informe o mês de validade do cartão de crédito");
    if (mesInformado < mes)
      erroValidacao.push("Cartão expirado");

    if (erroValidacao.length > 0) {
      this.goOnError(erroValidacao);
      return;
    }

    const cardExp = this.model.validade.substring(0, 2) + this.model.validade.substring(3, 5);

    this.uiService.showLoading("Processando...(1)");

    var response = await this.storeToken(
      {
        tokenData: JSON.stringify(this.tokenData),
        expiration: cardExp,
        transacaoID: this.transacao.transacaoId
      }
    );

      if (response.error) {
        erroValidacao.push(response.error);
        this.goOnError(erroValidacao);
      } else if(response && response.data) {
        await this.processarCartaoViaToken(
          {
            TransacaoID: this.transacao.transacaoId,
            Titular: this.model.nome,
            OutroTitular: false,
            Vencimento: cardExp,
            TipoAcaoCobranca: 0,
            DadosToken: JSON.stringify(response.data.tokenizationData),
            Localizacao: {SolicitaLocalizacao: null, AceitaTermos: true},
            VC: "3.0"
          },
          ignoreFinalize
        );
      }
  }

  consultarAutorizadoraAPI(cardExp: any) {
    this.api.consultarAutorizadora(
      this.transacao.transacaoId,
      this.model.numero,
      false,
      null,
    )
      .subscribe((response: any) => {
        if (response.error) {
          this.onResponseError(response.error);
        }
        else if (response.data.ID <= 0) {
          this.onResponseError(response.data.Mensagem);
        } else {
          this.model.autorizadoraSelecionada = response.data.ID;
          this.criarTransacaoStorageAPI(cardExp, 0);
        }
      });
  }

  criarTransacaoStorageAPI(cardExp: string, tipoCobranca: any) {
    this.api.criarTransacaoStorage(
      this.transacao.transacaoId,
      this.model.autorizadoraSelecionada,
      this.model.nome,
      false,
      null,
      cardExp,
      tipoCobranca,
      this.model.numero.substr(this.model.numero.length - 4, 4)
    )
      .subscribe((response: any) => {
        this.uiService.showLoading("Processando...(2)");


        if (response.error) {
          this.onResponseError(response.error);
        } else {
          let parametros = response.data.Parametro;
          // this.cobrancaID = response.data.CobrancaID;
          if (!response.data.Sucesso) {
            this.onResponseError(response.data.Mensagem);
            return;
          }
          let transacaoPagamentoID = response.data.TransacaoPagamentoID;
          this.sendSitefAPI(cardExp, parametros, transacaoPagamentoID);
        }
      });
  }

  sendSitefAPI(cardExp: any, parametros: any, transacaoPagamentoID: string) {
    this.api.sendSitef(
      transacaoPagamentoID,
      parametros.nita,
      parametros.merchantId,
      parametros.storeToken,
      this.model.autorizadoraSelecionada.toString(),
      this.model.numero,
      cardExp,
      parametros.paytoken,
      this.model.cvv,
      this.model.nome,
      parametros.nit
    )
      .subscribe((response: any) => {
        this.uiService.showLoading("Processando...(3)");

        //let confirmar=true;
        if (response.error) {
          if (!this.model.usaCvv) {
            this.onResponseError(response.error);
            return;
          }
        }
        this.confirmaTransacaoAPI(parametros, transacaoPagamentoID);
      });
  }

  confirmaTransacaoAPI(parametros: any, transacaoPagamentoID: string) {
    this.api.confirmarTransacao(
      transacaoPagamentoID,
      parametros.nita,
      false,
      false,
      null,
      'E-commerce'
    )
      .subscribe((response: any) => {
        this.uiService.hideLoading();
        if (response.error) {
          this.onResponseError(response.error);
        } else {
          if (!response.data.Sucesso) {
            this.onResponseError(response.data.Mensagem);
          } else {
            this.goOnSucess(response.data);
          }
        }
      });
  }

  onResponseError(response: string) {
    this.uiService.hideLoading();

    this.uiService.showMessage({
      title: "Ops!", 
      html: response
    });
  }

  validateCreditCardnumber(inputNum) {
    let digit, digits, flag, sum, _i, _len;
    flag = true;
    sum = 0;
    digits = (inputNum + '').split('').reverse();
    for (_i = 0, _len = digits.length; _i < _len; _i++) {
      digit = digits[_i];
      digit = parseInt(digit, 10);
      if ((flag = !flag)) {
        digit *= 2;
      }
      if (digit > 9) {
        digit -= 9;
      }
      sum += digit;
    }
    return sum % 10 === 0;
  }


  async storeToken(obj: any) {
    return this.api.storeToken(obj).toPromise();    
  }

  async processarCartaoViaToken(obj: any, ignoreFinalize: boolean = false) {
    return this.api.processarCartaoViaToken(obj).subscribe((response: any) => {
      this.uiService.hideLoading();
      if (response.error) {
        console.log(response.error);
        this.onRejected.emit(true);
      } else if (!response.data.Sucesso) {
        console.log(response.data.Mensagem);
        this.onRejected.emit(true);
      } else {
        if(!ignoreFinalize)
          this.goOnSucess(response.data);
        else
          this.goMultiplePaymentsSucess(response.data);  
      }
    });
  }

  keyUpChecker(ev) {
    let elementChecker: string;
    let format = /\d+/;
    elementChecker = ev.target.value;
    if (!format.test(elementChecker)) {
      this.model.numero = elementChecker.slice(0, -1);
    }
  }

  goOnSucess(item: any) {
    this.onSucess.emit(item);
  }

  goOnError(arr: String[]) {
    this.onError.emit(arr);
  }

  goMultiplePaymentsSucess(item: any){
    this.multiplePaymentsSucess.emit(item);
  }

  async registrarPagamentoOld(transacao: RegistraPagamentoInterface, model: CreditCard) {
    this.transacao = transacao;
    this.model = model;

    this.model.numero = this.model.numero.replace(/ /g, "");

    let agora = new Date();
    let mes =
      ("00" + agora.getFullYear()).slice(-2) +
      ("00" + (agora.getMonth() + 1)).slice(-2);
    let mesInformado: string;

    let erroValidacao = [];

    if (this.model.validade)
      mesInformado = this.model.validade.substring(3, 5) + this.model.validade.substring(0, 2);

    //RETORNO DE ERRO
    if (!this.model.nome)
      erroValidacao.push("Informe o nome do tilular do cartão");
    if (!this.model.numero)
      erroValidacao.push("Informe o número do cartão de crédito");
    if (this.model.numero && this.model.numero.length < 13)
      erroValidacao.push("O cartão de crédito deve ter no mínimo 13 números");
    if (!this.model.validade)
      erroValidacao.push("Informe o mês de validade do cartão de crédito");
    if (mesInformado < mes)
      erroValidacao.push("Cartão expirado");
    if (this.model.numero && !this.validateCreditCardnumber(this.model.numero))
      erroValidacao.push("Revise o número do cartão. Possivelmente ocorreu um erro de digitação.");
    if (this.model.usaCvv && !this.model.cvv)
      erroValidacao.push("Informe o código de segurança do seu cartão");

    if (erroValidacao.length > 0) {
      this.goOnError(erroValidacao);
      return;
    }

    const visa = /^4[0-9]{12}(?:[0-9]{3})/;
    const master = /^5[1-5][0-9]{14}/;
    const amex = /^3[47][0-9]{13}/;
    const diners = /^3(?:0[0-5]|[68][0-9])[0-9]{11}$/;
    const discovery = /^6(?:011|5[0-9]{2})[0-9]{12}/;
    const jcb = /^(?:2131|1800|35\d{3})\d{11}"/;
    const elo = /^(401178|401179|431274|438935|451416|457393|457631|457632|504175|627780|636297|636369|(506699|5067[0-6]\d|50677[0-8])|(50900\d|5090[1-9]\d|509[1-9]\d{2})|65003[1-3]|(65003[5-9]|65004\d|65005[0-1])|(65040[5-9]|6504[1-3]\d)|(65048[5-9]|65049\d|6505[0-2]\d|65053[0-8])|(65054[1-9]|6505[5-8]\d|65059[0-8])|(65070\d|65071[0-8])|65072[0-7]|(65090[1-9]|65091\d|650920)|(65165[2-9]|6516[6-7]\d)|(65500\d|65501\d)|(65502[1-9]|6550[3-4]\d|65505[0-8]))[0-9]{10,12}/;

    const hipercard = /^(606282\d{10}(\d{3})?)|(3841\d{15})$/;
    const aura = /^50[0-9]{14,17}$/;
    this.model.autorizadoraSelecionada =
      hipercard.test(this.model.numero) ? 5 :
        elo.test(this.model.numero) ? 41 :
          jcb.test(this.model.numero) ? 43 :
            aura.test(this.model.numero) ? 6 :
              discovery.test(this.model.numero) ? 44 :
                diners.test(this.model.numero) ? 33 :
                  amex.test(this.model.numero) ? 3 :
                    master.test(this.model.numero) ? 2 :
                      visa.test(this.model.numero) ? 1 :
                        0;

    const cardExp = this.model.validade.substring(0, 2) + this.model.validade.substring(3, 5);

    this.uiService.showLoading("Processando...(1)");

    if (this.model.autorizadoraSelecionada == 0) {
      erroValidacao.push("O cartão de crédito não foi identificado");
      this.consultarAutorizadoraAPI(cardExp); //Se tudo OK, irá processar a função "processaCartao"
    } else {
      this.criarTransacaoStorageAPI(cardExp, 0);
    }
  }

  async gerarPix(obj: any){
    return await this.gerarCobrancaPix(obj);
  }

  async gerarCobrancaPix(obj: any){
    var response = await this.api.gerarCobrancaPix(obj).toPromise();
    if(response){
      this.uiService.hideLoading();

      if (response.error) {
        this.onResponseError(response.error);
      }else{
        return response.data;
      }
    }
  }

  async verificarPagamentoPix(obj: any){
    var response = await this.api.verificarPagamentoPix(obj).toPromise();
    if(response){
      this.uiService.hideLoading();
      if (response.data.error) {
        this.onResponseError(response.data.error);
      }else{
        return response.data;
      }
    }
  }

}