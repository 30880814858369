import { animate, state, style, transition, trigger } from '@angular/animations';
import { Component, OnInit, Renderer2 } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CheckoutCmsService } from 'src/app/services/cms/checkoutCms.service';
import { DataLayerService } from 'src/app/services/dataLayer.service';
import { PaymentService } from 'src/app/services/payment.service';
import { UiService } from 'src/app/services/ui.service';
import { environment } from 'src/environments/environment';
import { AgradecimentoCmsService } from 'src/app/services/cms/agradecimentoCms.service';
import { EstablishmentCmsService } from 'src/app/services/cms/establishmentCms.service';
import { ChatCmsService } from 'src/app/services/cms/chatCms.service';
import { clear } from 'console';
import { AgradecimentoElosCmsService } from 'src/app/services/cms/agradecimentoElosCms.service';
import { DomSanitizer } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { AppIntegrationService, EAppIntegrationPaymentStatus } from 'src/app/services/app-integration.services';
import { APP_AGE } from 'src/app/helpers/constants';

@Component({
  selector: 'app-done',
  animations: [
    trigger('loadFormTrigger', [
      transition(':enter', [
        style({ opacity: 0 }),
        animate('100ms', style({ opacity: 1 })),
      ]),
      transition(':leave', [
        animate('100ms', style({ opacity: 0 }))
      ])
    ]),
  ],
  templateUrl: './done.component.html',
  styleUrls: ['./done.component.scss']
})
export class DoneComponent implements OnInit {

  appUrl: string;
  ecmUrl: string;
  orderUrl: string;

  enableHealthProfile: boolean = false;

  orderCode: string;

  paymentConfirmed: boolean = false;
  ecmSchedule: string;
  urlHealthProfile: string;
  timeWaitPaymentConfirmation: number = 10000;
  environments = environment;

  title: string = "";
  description: string = "";
  imageOrder: string = "";
  descriptionApp: string = "";
  urlAndroid: string = "";
  urlIos: string = "";
  showPhoneWhatsApp: boolean = false;
  phoneWhatsApp: String;
  messageWhatsApp: String;
  displayUrlAndroid: boolean = false;
  displayUrlIos: boolean = false;
  displaySvgCheck: boolean = false;
  showScheduleButton: boolean;
  awaitingPaymentMessage: string;
  isElos: boolean;
  data: any;

  paymentLinkOrigin: string = 'ELOS';

  customHtml: any;
  useCustomHtml: boolean = false;

  appIntegrationPaymentStatus = EAppIntegrationPaymentStatus;

  constructor(
    private route: ActivatedRoute,
    private dataLayerService: DataLayerService,
    private paymentService: PaymentService,
    private uiService: UiService,
    private checkoutCmsService: CheckoutCmsService,
    private agradecimentoCmsService: AgradecimentoCmsService,
    private establishmentCmsService: EstablishmentCmsService,
    private chatCmsService: ChatCmsService,
    private agradecimentoElosCmsService: AgradecimentoElosCmsService,
    private translateService: TranslateService,
    private renderer: Renderer2,
    private sanitizer: DomSanitizer,
    private appIntegrationService: AppIntegrationService) {

    this.appUrl = `${environment.ecommerceUrl}/app`;
    this.ecmUrl = environment.ecommerceUrl;
    this.ecmSchedule = this.ecmUrl + environment.ecommerce_schedule_endpoint;
    this.urlHealthProfile = this.ecmUrl + environment.urlHealthProfile;
  }

  ngOnInit(): void {

    this.renderer.removeClass(document.body, 'back-button-visible');
    this.uiService.uiChangedEvent.emit('done');

    this.route.url.subscribe(async params => {
      this.orderCode = this.route.snapshot.paramMap.get('id');
      this.orderUrl = `${environment.ecommerceUrl}/cliente/painel?meusPedidos=true`;

      this.data = await this.paymentService.loadPaymentRequestByExternalOrder(this.orderCode);
      this.paymentLinkOrigin = this.data.order.origin;

      this.isElos = this.paymentLinkOrigin == "ELOS";

      if (!this.isElos) {
        this.dataLayerService.createDataLayer(6, 'purchase');

        if (this.environments.enableHealthProfile) {
          let anyQuizAnswered = await this.paymentService.anyQuizAnswered(this.data.order.clientDocument);
          this.enableHealthProfile = !anyQuizAnswered;
        }
      }
      else
        this.renderer.addClass(document.body, 'is-payment-link');

      var agradecimentoData = await this.agradecimentoCmsService.getData();

      if (!this.isElos) {
        this.title =  agradecimentoData.title ? agradecimentoData.title : "";
        this.description =  agradecimentoData.description ? agradecimentoData.description : "";
        this.imageOrder = agradecimentoData.imageOrder ? agradecimentoData.imageOrder[0].url : null;
        this.descriptionApp =  agradecimentoData.descriptionApp ? agradecimentoData.descriptionApp : "";
        this.urlAndroid =  agradecimentoData.urlAndroid ? agradecimentoData.urlAndroid : "";
        this.urlIos =  agradecimentoData.urlIos ? agradecimentoData.urlIos : "";
        this.showPhoneWhatsApp = agradecimentoData.showPhoneWhatsApp ? agradecimentoData.showPhoneWhatsApp : false;

        this.messageWhatsApp = agradecimentoData.whatsappMessage ? agradecimentoData.whatsappMessage : "";
        this.showScheduleButton = agradecimentoData.showScheduleButton;
        this.awaitingPaymentMessage = agradecimentoData.awaitingPaymentMessage;
      } else  { //Agradecimento específico para links gerados no ELOS
        var agradecimentoElosData = await this.agradecimentoElosCmsService.getData();

        this.title = `${await this.translateService.get('done.title').toPromise()}`;
        this.useCustomHtml = agradecimentoElosData.useCustomHtml;
        this.customHtml = this.sanitizer.bypassSecurityTrustHtml(agradecimentoElosData.html);
        this.descriptionApp = agradecimentoElosData.descriptionApp;
        this.urlAndroid = agradecimentoElosData.urlAndroid;
        this.urlIos = agradecimentoElosData.urlIos;

        this.showPhoneWhatsApp = agradecimentoData.showPhoneWhatsApp ? agradecimentoData.showPhoneWhatsApp : false;
        this.messageWhatsApp = agradecimentoData.whatsappMessage ? agradecimentoData.whatsappMessage : "";
      }

      this.displayUrlAndroid = this.urlAndroid ? true : false;
      this.displayUrlIos = this.urlIos ? true : false;

      //Buscar telefones da loja selecionada (establishment.service.ts..getEstablishment(id: number))
      var establishment = await this.establishmentCmsService.getEstablishmentById(this.data.order.structureId);
      var establishmentHasPhone = false;
      var element = establishment[0];
      for (let index = 0; index < element.phones.length; index++)
      {
        var Tipo = element.phones[index].Type;
        if (Tipo == 'WhatsappPhone')
        {
          this.phoneWhatsApp = element.phones[index].Number.replace("(","").replace(")","")
              .replace(" ", "")
              .replace(" ", "")
              .replace(" ", "");
          establishmentHasPhone = true;
          break;
        }
      }

      if (!establishmentHasPhone)
      {
        var chatData = await this.chatCmsService.getData();
        this.phoneWhatsApp = chatData.whatsappRelationshipCenter;
        this.messageWhatsApp = chatData.whatsappMessage;
      }

      if (this.environments.thank_you_page_payment_confirm) {

        if (this.data && this.data.order && this.data.order.budgetIntegrated) {

          this.paymentConfirmed = true;

        } else if (!this.isElos) {

          setTimeout(async () => {

            let cmsData = this.checkoutCmsService.data.value;
            if(cmsData && cmsData.timeWaitPaymentConfirmation){
              this.timeWaitPaymentConfirmation = (cmsData.timeWaitPaymentConfirmation * 1000);
            }

            this.uiService.showLoading(await this.translateService.get('done.processing').toPromise());
            this.loadBudgetProgress();

          }, 1000);
        }
      }
      this.displaySvgCheck = true;
      this.setAppIntegrationInfo();
    });
  }

  async loadBudgetProgress() {

    var interval = setInterval(async () => {
      this.data = await this.paymentService.getBudgetIntegration(this.orderCode);

      this.paymentConfirmed = this.data.budgetIntegrated;

      this.uiService.hideLoading();

      if(this.paymentConfirmed){
        clearInterval(interval);
      }

    }, this.timeWaitPaymentConfirmation);
  }

  goToSchedule(){
    if(!this.paymentConfirmed) return;

    if(this.uiService.isBrowser){
      window.location.href = this.ecmSchedule;
    }
  }

  goToHealthProfile(){
    if(this.uiService.isBrowser){
      window.location.href = this.urlHealthProfile;
    }
  }

  async openExternalLink(){
      let data = await this.translateService.get('done.whatsAppLink').toPromise();
      data = data.replace('{0}', this.phoneWhatsApp);
      data = data.replace('{1}', this.messageWhatsApp);

      this.appIntegrationService.sendToApp(EAppIntegrationPaymentStatus.OpenExternalLink, data);
  }

  sendToApp(appIntegrationPaymentStatus: EAppIntegrationPaymentStatus){
    if(!this.paymentConfirmed && appIntegrationPaymentStatus === EAppIntegrationPaymentStatus.GoToSchedule) {
      return;
    }

    this.appIntegrationService.sendToApp(appIntegrationPaymentStatus);
  }

  get modalMode () : boolean {
    return this.appIntegrationService.origem === APP_AGE;
  }

  setAppIntegrationInfo(){
    this.appIntegrationService.currentRoute = 'done';
    this.appIntegrationService.modalTitle.next(this.title);
  }
}
