import { Component, OnDestroy, OnInit, Renderer2 } from '@angular/core';
import { SafeHtml } from '@angular/platform-browser';
import { CheckoutCmsService } from 'src/app/services/cms/checkoutCms.service';
import { PaymentService } from 'src/app/services/payment.service';
import { UiService } from 'src/app/services/ui.service';

@Component({
  selector: 'app-budget-not-found',
  templateUrl: './budget-not-found.component.html',
  styleUrls: ['./budget-not-found.component.scss']
})
export class BudgetNotFoundComponent implements OnInit, OnDestroy {
  content: SafeHtml;

  constructor(
    private renderer: Renderer2,
    private paymentService: PaymentService,
    private checkoutCmsService: CheckoutCmsService,
    private uiService: UiService) { }

  async ngOnInit() {
    this.renderer.addClass(document.body, 'is-payment-link');
    this.paymentService.changeHeader.next(true);
    this.uiService.hideCopyRight.next(true);
    this.content = await this.checkoutCmsService.getBudgetNotFoundContent();
  }

  ngOnDestroy(): void {
    this.paymentService.changeHeader.next(false);
    this.uiService.hideCopyRight.next(false);
  }
}
