<div *ngIf="showMultiplePayments">
    <app-pix-value-form [description]="paymentMethodsSubtitles?.pixPartial" [data]="data" (pixValueChanged)="pixValueChanged($event)"></app-pix-value-form>
</div>
<section [hidden]="total <= 0" class="sec-card">
    <div class="grid credit-card-container">
        <div class="title-wrapper">
            <img *ngIf="showMultiplePayments" src="../../../../assets/imagens/icon-payments-credit.png"/>        
            <h2 class="section-title">{{ paymentMethodDescription }}</h2>
            <span *ngIf="showMultiplePayments && showDiscount && bestDiscountPercent > 0" class="discount-wrapper">
                <span>{{'creditCard.discountsUpTo' | translate}}</span>
                <span class="discount-tag">{{bestDiscountPercent}}% OFF</span>                                
            </span>
        </div>
        <div class="subtitle-wrapper">
            <p class="section-subtitle">{{ paymentMethodSubtitle }}</p>
        </div>
		<!--TOKENEX-->
        <form [@openClose]="minimizeForm ? 'closed' : 'open'">
            <label class="credit-card-label">{{'CreditCardTitleName' | translate}}</label>
			<input class="tokenex-input" type="text" id="inputCardName" [(ngModel)]="model.nome" name="name" (ngModelChange)="changeName($event);validateForm()"/>

            <label class="credit-card-label">{{'CreditCardNumber' | translate}}</label>
            <div style="position: relative;">
                <div id="tokenExIframeDiv" style="max-height: 40px;"> 
                </div>
                <div *ngIf="creditCardDisplayModel.brandImage && creditCardDisplayModel.brandImage !== ''">
                    <div class="card-flag-div">
                        <img [src]="'../../../../assets/brands/' + creditCardDisplayModel.brandImage" class="card-flag" onerror="this.style.display='none';"/>
                    </div>
                </div>
            </div>

			
            <div class="validity-container" style="width:48%;float:left;">
                <label class="credit-card-label">{{'CreditCardValidity' | translate}}</label>
                <input class="tokenex-input val" type="tel" [(ngModel)]="model.validade" name="validade" (input)="changeValidade($event);validateForm()" placeholder="{{'dateFormat' | translate}}">
            </div>

            <div class="cvv-container" style="width:48%;float:right;">
                <label class="credit-card-label cvv-label">
                    {{'creditCard.cvv' | translate}}
                    <img *ngIf="tooltipReady" tippy 
                        [tippyOptions]="tooltipOptions" 
                        src="../../../../assets/imagens/icon-question-mark.png"/>
                </label>
                <div *ngIf="configuracaoToken"
                    id="CvvTextbox">
                </div>
            </div>
            
            <div style="clear:both;"></div>

            <span *ngIf="showMultiplePayments && isRecurrentAvaliable">
                <label class="credit-card-label recurrent-label">{{'useRecurrent' | translate}}
                    <img (click)="showRecurrentInfo()" src="../../../../assets/imagens/icon-question-mark.png"/>
                </label>

                <mat-slide-toggle (change)="changeCardType($event)"></mat-slide-toggle>
            </span>

            <label class="credit-card-label">{{'creditCard.installmentsNumber' | translate}}</label>
			<select
				class="payment-condition-select"
                (click)="showInstallments()"
				name="paymentCondition">
                <option selected *ngIf="!paymentConditionSelected">{{'creditCard.choosePaymentCondition' | translate}}</option>
                <option selected *ngIf="paymentConditionSelected"><span>{{paymentConditionSelected.installments}}x {{'creditCard.outOf' | translate}}</span> {{'currencySymbol' | translate }} {{paymentConditionSelected.installmentValue | currency: translate.instant('currency') : '' :'1.2-2' }}</option>
            </select>
        </form>
        <app-credit-card-display style="width:100%;" [data]="data"></app-credit-card-display>
    </div>
</section>