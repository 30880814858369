import { Component, OnInit, Output, EventEmitter, Input, Renderer2 } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ParcelasComponent } from './parcelas/parcelas.component';
import { trigger, state, style, transition, animate } from '@angular/animations';
import { TranslateService } from '@ngx-translate/core';
import { PaymentService } from 'src/app/services/payment.service';
import { UiService } from 'src/app/services/ui.service';
import { List } from 'linqts';
import { CheckoutCmsService, PaymentMethodsDescription } from 'src/app/services/cms/checkoutCms.service';

@Component({
  selector: 'app-formapgto',
  templateUrl: './formapgto.component.html',
  styleUrls: ['./formapgto.component.scss'],
  animations: [
    trigger('openClose', [
      // ...
      state('open', style({
        height: '*',
        display: 'block',
        opacity: 1
      })),
      state('closed', style({
        height: '0px',
        padding: '0px',
        display: 'none',
        opacity: 0
      })),
      transition('open <=> closed', [
        style({ display: 'block', opacity: 0.5 }),
        animate('0.2s')
      ])
    ]),
  ]
})
export class FormapgtoComponent implements OnInit {

  @Output() changePaymentMethodEvent = new EventEmitter<any>();

  ready: boolean = false;

  paymentMethods: Array<any> = [];
  paymentConditions: Array<any> = [];
  total: number;
  paymentLinkOrigin: string;
  paymentMethodsDescription: PaymentMethodsDescription;

  model: any = {
    paymentMethod: "",
    paymentCondition: "",
    paymentMethodApplied: false,
    minimizeForm: false,
    paymentDescription: ""
  };

  order: any;

  constructor(
    private paymentService: PaymentService,
    private uiService: UiService,
    public translate: TranslateService,
    public dialog: MatDialog,
    private checkoutCmsService: CheckoutCmsService,
    private renderer: Renderer2) {

  }

  async ngOnInit() {
    try {
      this.renderer.removeClass(document.body, 'back-button-visible');

      this.paymentService.dataChangedEvent.subscribe(async (e) => {
        if (e.data) {
          this.order = e.data.order;
          this.total = e.data.order.totalAmount;
          this.paymentLinkOrigin = e.data.order.origin;
          if(this.paymentLinkOrigin === 'ELOS'){
            this.paymentMethodsDescription = await this.checkoutCmsService.getPaymentMethodsDescriptions();
          }
          if (e.event != 'savePaymentMethod') {

            this.paymentMethods = new List<any>(e.data.methods)
              .Select(d => ({
                id: d.paymentMethod_Id,
                name: d.paymentMethod_Description,
                legend: d.paymentMethod_Legend,
                creditCardType: d.paymentMethod_CreditCardType
              }))
              .Distinct()
              .OrderBy(d => d.name)
              .ToArray();
          }
          if (e.event === 'saveVoucher') {
            this.model.paymentMethod = null;
          }

          let needToSelectPaymentMethodOnServer = false;

          if (e.data.order.paymentMethodId) {
            this.model.paymentDescription = e.data.order.paymentMethod + " " + e.data.order.paymentCondition;
            this.model.paymentMethodApplied = true;
            this.setMinimizeForm(true);
          } else if (e.data.methods.length == 1) {
            let method = e.data.methods[0];

            e.data.order.paymentMethod = method.paymentMethod_Description;
            e.data.order.paymentMethodId = method.paymentMethod_Id;
            e.data.order.paymentCondition = method.paymentCondition_Description;
            e.data.order.paymentConditionId = method.paymentCondition_Id;

            this.model.paymentDescription = e.data.order.paymentMethod + " " + e.data.order.paymentCondition;
            this.model.paymentMethodApplied = true;

            needToSelectPaymentMethodOnServer = true;
            this.setMinimizeForm(true);
          } else {
            this.model.paymentDescription = "";
            this.model.paymentMethodApplied = false;
            this.setMinimizeForm(false);
          }

          if (e.data.order.paymentMethodId) {
            this.model.paymentMethod = e.data.order.paymentMethodId;
          }

          if(e.data.order.paymentMethod_CreditCardType != 3){
            this.queryPaymentConditions(e.data);
          }

          setTimeout(() => {

            this.model.paymentCondition =
                this.paymentConditions =
                  new List<any>(this.paymentConditions)
                    .Where(d => d.id == e.data.order.paymentConditionId)
                    .FirstOrDefault();

            if (this.model.paymentMethod && !needToSelectPaymentMethodOnServer) {
              this.uiService.sendUiEvent("formapgtoReady");
            }

            setTimeout(async () => {
              this.ready = true;

              if (needToSelectPaymentMethodOnServer) {
                this.paymentService.savePaymentMethodAndCondition.next(e.data.order.paymentMethodId);
               }
            }, 400);
          }, 400);
        }
      });

      this.uiService.uiChangedEvent.subscribe((eventName) => {

        if (eventName == "cardOpen") {
          this.setMinimizeForm(true);
        }
      });
    }
    catch (e) {
      await this.uiService.showError(e);
    }
  }

  async queryPaymentConditions(data) {

    var comText = await this.translate.get("with").toPromise();
    var ofDiscountText = await this.translate.get("ofDiscount").toPromise();

    var orderAmount = data.order.subtotalAmount - (data.order.promotionalDiscount ?? 0) - (data.order.voucherDiscount ?? 0) - (data.order.saleCampaignDiscount ?? 0);

    var preAppliedDiscount = (((data.order.voucherDiscount ?? 0) + (data.order.promotionalDiscount ?? 0) + ((data.order.saleCampaignDiscount ?? 0) - (data.order.SalesCampaignAcumulativeDiscountValue ?? 0))) / data.order.subtotalAmount) * 100.0;

    this.paymentConditions =
      new List<any>(data.methods)
        .Where(d => d.paymentMethod_Id == this.model.paymentMethod)
        .Select(d => (
          {
            id: d.paymentCondition_Id,
            name: d.paymentCondition_Description,
            PortionAmount: this.uiService.formatCurrency(Math.round(((orderAmount - d.maxAllowedDiscount) / d.paymentCondition_Installments) * 100) / 100),
            DiscountAmount: preAppliedDiscount + d.discountAmount
          }))
        .Distinct()
        .Select(d => ({
          id: d.id,
          name: d.name,
          displayName: `${d.name} ${d.PortionAmount}${d.DiscountAmount > 0 ? ` (${comText} ${d.DiscountAmount.toFixed(0)}% ${ofDiscountText})` : ""}`,
          PortionAmount: d.PortionAmount,
          DiscountAmount: d.DiscountAmount
        }))
        .OrderBy(d => d.name)
        .ToArray();
  }

  async openPaymentConditionDialog() {

    await this.queryPaymentConditions(this.paymentService.data);

    const dialogRef = this.dialog.open(ParcelasComponent, {
      data: {
        paymentMethod: this.model.paymentMethod,
        paymentConditions: this.paymentConditions,
        order: this.order
      }
    });

    var result = await dialogRef.afterClosed().toPromise();

    if (result) {
      this.model.paymentCondition = result;

      await this.gonext();
    } else {
      this.uiService.sendUiEvent("formapgtoNotReady");
      this.model.paymentMethodApplied = false;
      this.model.paymentCondition = undefined;
      this.model.paymentMethod = undefined;
    }
  }

  async gonext(creditCardType: any = null) {
    try {
      if (this.ready && this.model.paymentMethod != undefined) {
        var validateResult = await this.paymentService.validatePaymentRequestVersion();
        if(!validateResult.versionsMatch){
         await this.paymentService.displayModalGoToMyBudgets();
          return;
        }
        //Se a forma de pagamento for diferente de PIX, gravamos apenas a forma de pagamento, pois a condição será selecionada depois
        if(creditCardType != 3){
          await this.paymentService.selectPaymentMethod(this.model.paymentMethod);
        }else if(this.model.paymentMethod && this.paymentConditions.length > 0){
          await this.paymentService.savePaymentMethod(this.model.paymentMethod, this.paymentConditions[0].id)
        }
        this.uiService.sendUiEvent("formapgtoReady");
      }
    }
    catch (e) {
      await this.uiService.showError(e);
    }
  }

  selectPaymentMethod(pm) {

    this.model.paymentMethod = pm;

    if (this.ready) {
      setTimeout(async () => {
        this.uiService.sendUiEvent("formapgtoNotReady");

        this.model.paymentMethodApplied = false;

        this.model.paymentCondition = undefined;

      }, 30);
    }
  }

  setMinimizeForm(value) {
    this.model.minimizeForm = value;

    if (this.model.minimizeForm) {
      this.uiService.sendUiEvent("formapgtoClosed");
    } else {
      this.uiService.sendUiEvent("formapgtoOpen");
    }
  }

  expandAccordion() {
    if (this.model.paymentMethodApplied) {
      this.setMinimizeForm(!this.model.minimizeForm);
    }
  }

  async selectPm(pm){
      this.model.paymentMethod = pm.id;
      await this.queryPaymentConditions(this.paymentService.data);
      this.model.paymentMethodApplied = true;
      this.changePaymentMethodEvent.emit(this.model.paymentMethod);
      await this.gonext(pm.creditCardType);
  }

}
