<section class="credit-card-display" *ngIf="showCard && paymentConditionSelected">
    <div class="credit-card">
        <div class="card-flag-display" *ngIf="model.brandImage && model.brandImage !== ''">
            <img class="card-flag" [src]="'../../../../assets/brands/white-' + model.brandImage" alt="card-flag" onerror="this.style.display='none';"/>
        </div>
        <div class="card-number-display" *ngIf="model?.cardDigits && model.cardDigits.length > 0">
            <span *ngFor="let item of model.cardDigits">
                {{item}}
            </span>
        </div>
        <div class="card-name-display">
            <p>{{model?.name}}</p>
        </div>
        <div class="card-validity-display">
            <p>{{model?.validity}}</p>
        </div>        
    </div>
    <div class="credit-card-display-container">
        <div class="condition-container">
            <div class="condition-text-block">
                <p>
                    <span class="bigger-text">{{paymentConditionSelected.installments}}x</span> {{'creditCard.outOf' | translate}}<br/>
                    <span class="bigger-text">          
                        <span class="small-text">{{'currencySymbol' | translate }}</span> {{paymentConditionSelected.installmentValue | currency: translate.instant('currency') : '' :'1.2-2' }}
                    </span>
                </p>
            </div>
        </div>
        <div class="total-text-block">
            <p>
                {{'total' | translate}}<br/>
                {{'currencySymbol' | translate }} {{paymentConditionSelected.total | currency: translate.instant('currency') : '' :'1.2-2' }}
            </p>
        </div>
    </div>
    <div class="clear"></div>
    

</section> 
