// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  usesGeolocation: true,
  ecommerceUrl: "https://ecm-hml.espacolaser.com.br",
  serviceUrl: 'https://func-hml-checkout-el.azurewebsites.net/api/',
  AZURE_FUNCTION_KEY: 'bSIU5LrfCSR3pl5q4SysSEPYd6mEyqc/wIBk31TMxNLTJ92B4Vg0bQ==',
  serviceEstablishmentUrl: "https://ecm-hml-api.evup.com.br/api/espacolaser/pt-BR",
  AZURE_FUNCTION_ESTAB_KEY: 'wAmOk3c71Pi7aTbQnFyBmBsf3RkBhbkuRChX1pFCjN5yIwIA1PXkaA==',
  elosGateEnvironment: 1,
  defaultTenantName: 'espacolaser',
  defaultLanguage: 'pt-BR',
  cms_url: 'https://cms-hml.evup.com.br',
  cms_content_endpoint: '/api/content/ecommerce/graphql',
  cms_assets_endpoint: '/api/assets/ecommerce/{0}',
  cms_identity_endpoint: '/identity-server/connect/token',
  cms_identity_client_id: 'ecommerce:site',
  cms_identity_client_secret: 'bpoa4vuzittoimna5yv2rj6ic3xj3fcxu4sudq7aewix',
  cms_api_assets_endpoint: '/api/assets/',
  ecommerce_schedule_endpoint: "/cliente/painel?agenda=true&agendar=true",
  thank_you_page_payment_confirm: true,
  flagshipUrl: 'https://app-hml-fsui-el.azurewebsites.net/',
  flagshipIdleTimeoutSeconds: '60',
  ecommerce_myaccount_endpoint: "/customer/account/",
  useTokenex: true,
  tokenexScriptUrl: 'https://test-htp.tokenex.com/iframe/iframe-v3.min.js',
  elosgate_storetoken_api: "https://payments-hom.elosgate.com.br/StoreToken",
  elosgate_obterchaves_origin: "ELHML",
  cms_url_shared: 'https://cms-hml.evup.com.br',
  cms_content_endpoint_shared: '/api/content/shared/graphql',
  cms_identity_client_id_shared: 'shared:default',
  cms_identity_client_secret_shared: 'mvk3ll2imzjvmoeqxwxexxkwiimprrkvz0x7saluqsmx',
  cartUrl: "https://ecm-hml.espacolaser.com.br/carrinho",
  pixValidityInMinutes: '59',
  enableMultiplePayments: true,
  enableHealthProfile: true,
  urlHealthProfile: "/cliente/painel?perfilSaude=true"
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
