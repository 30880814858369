import { animate, state, style, transition, trigger } from '@angular/animations';
import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { PacoteServicoService } from 'src/app/services/cms/pacoteServico.service';
import { DataLayerService } from 'src/app/services/dataLayer.service';
import { LocalStorageService } from 'src/app/services/local-storage.service';
import { PaymentService } from 'src/app/services/payment.service';
import { UiService } from 'src/app/services/ui.service';
import { CheckoutCmsService } from 'src/app/services/cms/checkoutCms.service';
import { PreviousPaymentsComponent } from 'src/app/components/previous-payments/previous-payments.component';
import { MatDialog } from '@angular/material/dialog';
import { CurrencyPipe } from '@angular/common';
import { PaymentLinkItemModel } from 'src/app/model/payments/payment-link-item.model';
import { VoucherModel } from 'src/app/model/payments/voucher.model';

@Component({
  selector: 'app-summary',
  templateUrl: './summary.component.html',
  styleUrls: ['./summary.component.scss'],
  animations: [
    trigger('openClose', [

      state('open', style({
        height: '*',
        display: 'block',
        opacity: 1
      })),
      state('closed', style({
        height: '0px',
        padding: '0px',
        display: 'none',
        opacity: 0
      })),
      transition('open <=> closed', [
        style({ display: 'block', opacity: 0.2 }),
        animate('0.2s')
      ])
    ]),
  ]
})
export class SummaryComponent implements OnInit {

  subTotal: number;
  voucherDiscount: number;
  voucherCode: string;
  total: number;
  discountText: string;
  itens: Array<any>;
  itensPaymentLink: PaymentLinkItemModel[];
  minimizeForm: boolean = false;
  minimizeCampaignForm: boolean = false;

  totalDiscount: number;
  showTotalDiscount: boolean = true;

  voucherDiscountValue: number;
  appliedCampaigns: any[];
  avaliablePromotions: any[];
  giftItemCampaigns: any[];
  clientName: string;

  paymentLinkOrigin: string;
  establishmentName: string;
  tenantName: string;
  payments: any;
  alreadyPaid: any = 0;
  voucher: VoucherModel;
  
  constructor(
    private uiService: UiService,
    private paymentService: PaymentService,
    public translateService: TranslateService,
    private pacoteServicoService: PacoteServicoService,
    private dataLayerService: DataLayerService,
    private localStorage: LocalStorageService,
    private checkoutCmsService: CheckoutCmsService,
    public dialog: MatDialog,
    private currencyPipe: CurrencyPipe
  ) { }

  ngOnInit(): void {
    this.paymentService.dataChangedEvent.subscribe(async (e) => {
      this.paymentLinkOrigin = e.data.order.origin;
      this.payments = e.data.order.payments.filter(w => w.status == 6);
      this.payments.forEach(item => {
        this.alreadyPaid += item.amount;
      });

      this.voucherDiscount = 0;
      this.voucherCode = '';
      this.showTotalDiscount = true;
      this.totalDiscount = 0;

      if (e.data.order) {
        this.appliedCampaigns = e.data.order.appliedCampaigns;
        this.subTotal = e.data.order.subtotalAmount;
        this.total = e.data.order.totalAmount;
        this.avaliablePromotions = e.data.order.avaliablePromotions;
        this.giftItemCampaigns = e.data.order.giftItemCampaigns

        if (this.paymentLinkOrigin !== 'ELOS') {
          let ids = [];
          e.data.order.itens.forEach(e => {
            ids.push(e.id);
          });

          this.itens = await this.pacoteServicoService.getPacoteServicosByRangeId(ids);
          if (this.itens) {
            e.data.order.itens.forEach(e => {
              let item = this.itens.find(i => i.id == e.id);
              if (item) {
                item.price = e.netValue;
                item.mainPrice = e.priceTableValue;
              }
            });
            this.dataLayerService.setProducts(this.itens);
          }

          //verifica se existem items avulsos, caso sim adiciona a lista de itens a serem exibidos
          var serviceItens = e.data.order.itens.filter(item => item.isServiceItem);
          serviceItens.forEach(item => {
            this.itens.push({
              description: item.name,
              id: item.id
            });
          });

          //verifica se existem pacotes que não existem no cms
          var packageNotExistsOnCms = e.data.order.itens.filter(item => item.packageNotExistsOnCms);
          packageNotExistsOnCms.forEach(item => {
            this.itens.push({
              description: item.name,
              id: item.id
            });
          });
        } else {
          this.itensPaymentLink = e.data.order.itens;
          this.itensPaymentLink?.forEach(x => x.showGender = x.restrictedGender === 1 || x.restrictedGender === 2);
        }

        if (this.paymentLinkOrigin !== 'ELOS') {
          if (e.data.order.voucherDiscount || e.data.order.promotionalDiscount || e.data.order.saleCampaignDiscount) {
            this.voucherDiscount = e.data.order.voucherDiscount;
            this.voucherCode = e.data.order.voucherCode;
            this.discountText = `${await this.translateService.get('flexibleDiscount').toPromise()}`;
          } else if (e.data.order.paymentCondition) {
            var percent = 100 - ((this.total / this.subTotal) * 100);
            this.discountText = `${await this.translateService.get('in').toPromise()} ${e.data.order.paymentCondition.trim()} - (${percent.toPrecision(2).concat('%')})`;
          } else {

            var higherInstallmentSuggestion = e.data.higherInstallmentSuggestion;

            this.discountText = `${await this.translateService.get('inUntil').toPromise()} ${higherInstallmentSuggestion?.paymentCondition_Description?.trim()} - (${higherInstallmentSuggestion?.discountAmount?.toPrecision(2).concat('%')})`;
          }
        }

        if (this.voucherCode) {

          this.voucherDiscountValue = e.data.order.voucherDiscountValue;

          this.totalDiscount = (this.subTotal - this.total - (this.voucherDiscountValue ? this.voucherDiscountValue : this.voucherDiscount));
        } else {
          this.totalDiscount = (this.subTotal - this.total - this.voucherDiscount);
        }

        //Verifica se é necessário a apresentação da mensagem de atingimento da promoção
        await this.checkAvaliableGiftMessage(e.data.order.code);
      }

      if (this.paymentLinkOrigin === 'ELOS') {
        this.tenantName = await this.checkoutCmsService.getBrand();
        const establishment = await this.paymentService.getEstablishmentData(e.data.order.structureId);
        this.establishmentName = establishment?.description;
        this.clientName = e.data.order.clientName;

        if (e.data.order.voucherCode) {
          let percentValue = (e.data.order.voucherDiscountValue / (e.data.order.totalAmount + e.data.order.salesCampaignAcumulativeDiscountValue + e.data.order.voucherDiscountValue)) * 100;
          let percentValueFixed = parseFloat(percentValue.toFixed(2));

          this.voucher = {
            code: e.data.order.voucherCode,
            value: this.currencyPipe.transform(e.data.order.voucherDiscountValue, '', '', '1.2-2'),
            percentValue: Number.isInteger(percentValueFixed) ? percentValueFixed.toString() : this.currencyPipe.transform(percentValueFixed, '', '', '1.2-2')
          };
        }
      }
    });
  }

  closeSummary() {
    this.uiService.uiChangedEvent.emit('summaryClose');
  }
  expandCart() {
    this.minimizeForm = !this.minimizeForm;
  }

  expandCampaigns() {
    this.minimizeCampaignForm = !this.minimizeCampaignForm;
  }

  async checkAvaliableGiftMessage(orderCode) {

    var giftItemCampaignsClone = this.giftItemCampaigns && this.giftItemCampaigns.length > 0 ? [...this.giftItemCampaigns]: [];

    if (giftItemCampaignsClone.length > 0 && this.avaliablePromotions.length > 0) {

      //Controle para que a mensagem não seja mais apresenta caso o cliente tenha selecionado a opção "Não desejo resgatar"
      let storageKey: string = `applyGiftItems-${orderCode}`;

      let achivedCampaigns = new Array();

      this.avaliablePromotions.forEach(promo => {
        var applyGiftItems = this.localStorage.getItem(storageKey);
        var appliedCampaign = giftItemCampaignsClone.find(f => f.campaignId == promo.campaignId);

        if (promo.achieved && appliedCampaign.isApplied == false && (applyGiftItems == null || applyGiftItems.includes("true"))) {
          var promotionStr = promo.description;
          if (promotionStr) {
            achivedCampaigns.push(promotionStr);
          }
        }
      });

      // remove aplicados do array
      this.avaliablePromotions = this.avaliablePromotions.filter(f => f.achieved == false);

      await this.showAvaliableGiftMessage(achivedCampaigns);
    }
  }

  async showAvaliableGiftMessage(achivedCampaigns) {

    let userMsg: string;
    let campaignsJoin: string = "";

    if (achivedCampaigns && achivedCampaigns.length > 0) {

      achivedCampaigns.forEach(promotiondesc => {
        if (promotiondesc) {
          campaignsJoin += `<p> • ${promotiondesc} </p>`;
        }
      });

      userMsg = `${await this.translateService.get('achivedCampaignsUserMsg').toPromise()}`

      userMsg = userMsg.replace('{0}', campaignsJoin);

      var response = await this.uiService.showMessage({
        title: await this.uiService.translate('achivedCampaignsUserMsgTitle'),
        html: userMsg,
        width: '600px'
      });

      if (response.value) {
        document.getElementById("gift-itens-section").scrollIntoView();
      }
    }
  }

  showPaymentHistory(){
    const dialogRef = this.dialog.open(PreviousPaymentsComponent, {
      width: '322px',
      data: {
       payments: this.payments
      }
    });
  }

  roundUp(value: number, decimalPlaces: number): number {
    const factor = Math.pow(10, decimalPlaces);
    return Math.ceil(value * factor) / factor;
  }
}
