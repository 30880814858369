import { ActivatedRoute } from '@angular/router';
import { Component, OnInit, Input } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-stepHeader',
  templateUrl: './stepHeader.component.html',
  styleUrls: ['./stepHeader.component.scss']
})
export class StepHeaderComponent implements OnInit {

  fromCart: boolean;

  step1: string;
  step2: string;
  step3: string;
  step4: string;

  numStep1: string;
  numStep2: string;
  numStep3: string;
  numStep4: string;
  

  @Input() stepId: string = "1";

  showStep: boolean = false;

  constructor(private route: ActivatedRoute, private translateService: TranslateService) { }

  async ngOnInit(){

    this.step1 = await this.translateService.get('stepIdentification').toPromise();
    this.step2 = await this.translateService.get('stepStore').toPromise();
    this.step3 = await this.translateService.get('stepPayment').toPromise();
    this.step4 = await this.translateService.get('stepSummary').toPromise();


    this.numStep1 = "1";
    this.numStep3 = "2";
    this.numStep4 = "3";

    this.route.queryParams.subscribe(async params => {
      if (params['fromCart'] && params['fromCart'].toLowerCase() == "true")
      {
        this.fromCart = true;
        this.step1 = await this.translateService.get('stepRegister').toPromise();
        this.showStep = true;
        this.numStep1 = "1";
        this.numStep2 = "2";
        this.numStep3 = "3";
        this.numStep4 = "4";
      }
    });
  }

}
