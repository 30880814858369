import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import gql from 'graphql-tag';
import { BehaviorSubject } from 'rxjs';


const agradecimentoQuery = `
{
  queryAgradecimentoContents {
    flatData {
      title,
      description,
      imageOrder {
        url,
        pixelWidth,
        pixelHeight
      },
      descriptionApp,
      urlAndroid,
      urlIos,
      showWhatsAppButton,
      whatsappMessage,
      showScheduleButton,
      awaitingPaymentMessage
    }
  }
}`;


@Injectable({
  providedIn: 'root'
})
export class AgradecimentoCmsService {

  //public conheceAreas: any;
  public data: BehaviorSubject<any> = new BehaviorSubject<any>(undefined);

  constructor(private apollo: Apollo) {

  }

  async getData(): Promise<any> {

    var result = await this.apollo.watchQuery<any>({
      query: gql(agradecimentoQuery)
    }).result();

    var data = result.data.queryAgradecimentoContents.map(q => {
      return {
        title: q.flatData.title,
        description: q.flatData.description,
        imageOrder: q.flatData.imageOrder,
        descriptionApp: q.flatData.descriptionApp,
        urlAndroid: q.flatData.urlAndroid,
        urlIos: q.flatData.urlIos,
        showPhoneWhatsApp: q.flatData.showWhatsAppButton,
        whatsappMessage: q.flatData.whatsappMessage,
        showScheduleButton: q.flatData.showScheduleButton,
        awaitingPaymentMessage: q.flatData.awaitingPaymentMessage
      }
    })[0];

    this.data.next(data);

    return data;
  }

}
