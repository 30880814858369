import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-previous-payments',
  templateUrl: './previous-payments.component.html',
  styleUrls: ['./previous-payments.component.scss']
})
export class PreviousPaymentsComponent implements OnInit {

  payments: any;
  totalDiscount: any = 0;

  constructor(
    public dialogRef: MatDialogRef<PreviousPaymentsComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public translate: TranslateService
    ) { }

  ngOnInit(): void {
    this.payments = this.data.payments;
    this.payments.forEach(item => {
      let date = new Date(item.paymentDate);
      item.paymentTime = date.toLocaleTimeString([], {hour: '2-digit', minute:'2-digit'});
      item.date = date.toLocaleDateString();
      item.paymentMethodDescription = item.paymentMethodDescription.toLowerCase();
      item.showDiscountPercent = item.appliedDiscount > 0;
      this.totalDiscount += item.amount * item.appliedDiscount;
    });
  }


  calculateOriginalPaymentValue(netValue, discountPercent){
    let originalValue = netValue / (1 - discountPercent);
    originalValue = Math.round(originalValue * 100) / 100;
    return originalValue;
  }

  closeModal(){
    this.dialogRef.close();
  }

}
