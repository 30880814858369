import { trigger, state, style, transition, animate } from '@angular/animations';
import { Component, OnInit, ViewChild, Input } from '@angular/core';
import { SelectEstablishmentComponent } from 'src/app/components/select-establishment/select-establishment.component';
import { OrganizationalStructure } from 'src/app/model/organizationalStructure';
import { EstablishmentService } from 'src/app/services/establishment.service';
import { PaymentService } from 'src/app/services/payment.service';
import { UiService } from 'src/app/services/ui.service';

@Component({
  selector: 'app-establishment',
  templateUrl: './establishment.component.html',
  styleUrls: ['./establishment.component.scss'],
  animations: [
    trigger('openClose', [
      // ...
      state('open', style({
        height: '*',
        display: 'block',
        opacity: 1
      })),
      state('closed', style({
        height: '0px',
        padding: '0px',
        display: 'none',
        opacity: 0
      })),
      transition('open <=> closed', [
        style({ display: 'block', opacity: 0.5 }),
        animate('0.2s')
      ])
    ]),
  ]
})
export class EstablishmentComponent implements OnInit {

  ready: boolean = false;

  /**
   * Definido que a unidade de preferência deve ficar oculta caso já tenha sido selecionada previamente:
   * https://dev.azure.com/evuptec/Kanban%20EL/_workitems/edit/29358
   */
  isHidden: boolean = false;

  /**
   * Se marcado, sobreescreve a propriedade isHidden (serve para manter mostrando na tela uma vez que foi solicitado para exibir)
   */
  isShow: boolean = false;

  minimizeForm: boolean = false;

  model: OrganizationalStructure;

  selectEstablishmentInitialized: boolean = false;

  isBudget: boolean = false;

  filterAffiliateAllowedEstablishments: boolean;

  showRestrictAffiliateOrganizationalStructureMessage: boolean = false;
  orgStructSelected: boolean = false;

  @ViewChild('select') selectEstablishment: SelectEstablishmentComponent;

  @Input() isTotem: boolean;

  @Input() flagshipEstablishmentId: number;


  constructor(
    private uiService: UiService,
    private paymentService: PaymentService,
    private establishmentService: EstablishmentService) { }

  async ngOnInit() {
    try {
      this.paymentService.dataChangedEvent.subscribe(async (e) => {
        if (e.data) {
          this.filterAffiliateAllowedEstablishments = e.data.order.affiliate_Id && e.data.order.affiliate_Id > 0 ? true : false;
          if(this.isTotem){
            this.model = await this.establishmentService.getEstablishment(this.flagshipEstablishmentId);
          }
          else if (e.data.order.structureId) {
            this.isHidden = true;
            this.model = await this.establishmentService.getEstablishment(e.data.order.structureId, this.filterAffiliateAllowedEstablishments);
          }

          e.data.order.structureId = await this.validateModel(e.data.order.structureId, this.model);

          this.isBudget = e.data.order.isBudget;
          this.ready = true;

          if(!e.data.order.structureId || this.showRestrictAffiliateOrganizationalStructureMessage){
              this.isHidden = false;
              this.isShow = true;
              this.setMinimizeForm(false);

              if (this.showRestrictAffiliateOrganizationalStructureMessage) {
                await this.showRestrictOrganizationalStructureMessage();
              }
          }
        }
        else {
          this.ready = false;
        }
      });

      this.uiService.uiChangedEvent.subscribe((eventName) => {

        if (eventName == "cardOpen") {
          this.setMinimizeForm(true);
        }
      });

      this.setMinimizeForm(true);
    }
    catch (e) {
      await this.uiService.showError(e);
    }
  }

  setMinimizeForm(value) {

    if(!this.isBudget){

      if (value == false && this.selectEstablishmentInitialized == false) {
        this.selectEstablishment.initialize();
      }

      this.minimizeForm = value;

      if (this.minimizeForm) {
        this.uiService.sendUiEvent("establishmentClosed");
      } else {
        this.uiService.sendUiEvent("establishmentOpen");
      }
    }
  }

  expandAccordion() {
    this.setMinimizeForm(!this.minimizeForm);
  }

  async onChange(establishment) {

    try {

      this.model = establishment;

      this.orgStructSelected = true;

      this.setMinimizeForm(true);

      if (this.model) {
        await this.paymentService.saveEstablishment(this.model);
      }
    }
    catch (e) {
      await this.uiService.showError(e);
    }
  }

  async validateModel(structureId: any, model: any){
    if(!model || (model && (!model.Id || model.Id == 0))){
      this.showRestrictAffiliateOrganizationalStructureMessage = this.filterAffiliateAllowedEstablishments ?? true;
      await this.paymentService.removeOrgStruct();
      return null;
    }
    this.showRestrictAffiliateOrganizationalStructureMessage = false;
    return structureId;
  }

  async showRestrictOrganizationalStructureMessage(){
    this.uiService.showMessage({
      title: 'Ops..',
      html: await this.uiService.translate("restrictAffiliateOrganizationalStructure")
    });
  }

}
