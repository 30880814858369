import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { PaymentService } from 'src/app/services/payment.service';
import { PixService } from 'src/app/services/pix.service';
import { GiftitemService } from 'src/app/services/giftitem.service';

@Component({
  selector: 'app-finalize-payment',
  templateUrl: './finalize-payment.component.html',
  styleUrls: ['./finalize-payment.component.scss']
})
export class FinalizePaymentComponent implements OnInit {
  total: number;
  paymentMethod_CreditCardType: any;
  code: any;
  giftItemCampaigns: any[] = [];
  @Output() finalizeWithPixEvent = new EventEmitter<any>(null);


  constructor(
    private paymentService: PaymentService,
    private pixService: PixService,
    private giftItemService: GiftitemService
    ) { }

  ngOnInit(): void {

    this.paymentService.dataChangedEvent.subscribe((e) => {
      this.total = e.data.order.totalAmount;
      this.paymentMethod_CreditCardType = e.data.order.paymentMethod_CreditCardType;
      this.code = e.data.order.code;
      this.giftItemCampaigns = e.data.order.giftItemCampaigns;
    });
  }

  async finalize(){

    var validateResult = await this.paymentService.validatePaymentRequestVersion();
    if(!validateResult.versionsMatch){
      await this.paymentService.displayModalGoToMyBudgets();
      return;
    }

    if(this.paymentMethod_CreditCardType == 3){
      await this.validateGiftItemAndFinalizePix();
    }else{
      this.paymentService.finalizePurchase.next(true);
    }
  }

  async validateGiftItemAndFinalizePix(){
    var finalize = false;
    var hasGiftCampaignAvaliable = this.giftItemCampaigns && this.giftItemCampaigns.length > 0;
    if (hasGiftCampaignAvaliable) {
      var appliedGifts = await this.paymentService.checkForAppliedGifts();
      if (!appliedGifts) {
        var response = await this.giftItemService.showNoGiftItemSelectedModal();
        if (!response.value) {
            finalize = true;
        }
      }else{
        finalize = true;
      }
    }else{
      finalize = true;
    }

    if(finalize){
      await this.finalizePix();
    }

  }

  async finalizePix(){
    var result = await this.pixService.finalizePix();
    if(result.transacaoId){
        this.finalizeWithPixEvent.emit(result.transacaoId);
    }
  }



}
