export enum PaymentRequestStatusEnum {
    requested = 1,
    voucherSelected = 2,
    paymentMethodSelected = 3,
    paymentConditionSelected = 4,
    creditCardSelected = 5,
    finalized = 6,
    canceled = 7,
    deleted = 8,
    giftCampaignSelected = 9,
    notExists = 99
  }