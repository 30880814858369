import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import  { PaymentService } from 'src/app/services/payment.service';

@Component({
  selector: 'app-partial-done',
  templateUrl: './partial-done.component.html',
  styleUrls: ['./partial-done.component.scss']
})
export class PartialDoneComponent implements OnInit {

  displaySvgCheck: boolean = false;
  ready: boolean = false;

  constructor(private paymentService: PaymentService,
    private translate: TranslateService) { }

  async ngOnInit() {
    this.displaySvgCheck = true;
    await this.checkPaymentStatus();
  }

  confirm(){
    this.paymentService.goToPix.emit(true);
  }

  async checkPaymentStatus(){
    var interval = setInterval(async () => {
      var result = await this.paymentService.checkPaymentStatus();
      if(result.paymentReceived){
        clearInterval(interval);
        this.ready = true;
      }
    }, 10000);
  }
}
