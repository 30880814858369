<section [hidden]="!ready" class="sec-voucher">
    <div class="input-wrapper">
        <input type="text" placeholder="{{'haveVoucher' | translate}}" id="inputCouponCode" name="couponCode" [(ngModel)]="model.couponCode" [disabled]="model.voucherApplied"/>
        <span class="icon-label"><img src="../../../../assets/imagens/icon-label.png"/></span>
        <button *ngIf="!model.voucherApplied" type="button" (click)="applyVoucher()">{{'apply' | translate}}</button>
        <button *ngIf="model.voucherApplied" type="button" (click)="removeVoucher()">{{'remove' | translate}}</button>
    </div>
    <div class="applied-voucher-description" *ngIf="model.voucherApplied && packageVoucherItens && packageVoucherItens.length > 0">
        <p>{{'voucher.voucherApplied' | translate}}:</p>
        <ul>
            <li *ngFor="let item of packageVoucherItens">
                <p><b>{{item.description}}</b> <span>&nbsp;({{item.discountPercent}}</span> %OFF)</p>
            </li>
        </ul>
    </div>
</section>
