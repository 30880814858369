import { Component, OnInit, Input } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

export interface CreditCardExternalDisplay {
  name?: string;
  firstSix?: string;
  lastFour?: string;
  validade?: string;
  brand?: string;
  brandImage?: string;
  totalValueFormatted?: string;
}

@Component({
  selector: 'app-credit-card-external-display',
  templateUrl: './credit-card-external-display.component.html',
  styleUrls: ['./credit-card-external-display.component.scss'],
})
export class CreditCardExternalDisplayComponent implements OnInit {
  @Input() displayData: CreditCardExternalDisplay;

  constructor(public translate: TranslateService) {}

  ngOnInit(): void {}

  get displayCard(): boolean {
    return (
      !!this.displayData?.name &&
      this.displayData?.validade?.length > 4 &&
      !!this.displayData?.firstSix &&
      !!this.displayData?.lastFour &&
      !!this.displayData?.brand
    );
  }

  firstSixReplace() {
    return this.displayData.firstSix
      ? this.displayData.firstSix.substring(0, 4)
      : '';
  }
}
