<section class="sec-credit-card-external">
  <div class="header">
    <h2>{{ "creditCard.creditCard" | translate }}</h2>
    <mat-icon (click)="back()">highlight_off</mat-icon>
  </div>

  <p>{{ "creditCard.fillCardDetails" | translate }}</p>

  <div class="payment-credit-card-form">
    <div class="payment-credit-card-form-body">
      <form>
        <!--Nome Impresso no Cartão-->
        <label class="credit-card-label" for="inputCardName">{{
          "CreditCardTitleName" | translate
        }}</label>
        <input
          [(ngModel)]="model.nome"
          class="tokenex-input"
          type="text"
          id="inputCardName"
          name="name"
          (input)="changeName($event); validateForm()"
        />
        <!--Número do Cartão-->
        <label class="credit-card-label">{{
          "CreditCardNumber" | translate
        }}</label>
        <div id="tokenExIframeDiv">
          <div class="card-flag-div-img">
            <div class="card-flag-div" *ngIf="model.brandImg">
              <img
                [src]="'../../../../assets/brands/' + model.brandImg"
                class="card-flag"
                aria-label="image card flag"
              />
            </div>
          </div>
        </div>
        <div class="date-cvv">
          <div>
            <!--Data de Validade-->
            <label class="credit-card-label" for="validade">{{
              "CreditCardValidity" | translate
            }}</label>
            <input
              [(ngModel)]="model.validade"
              class="tokenex-input val"
              type="tel"
              id="validade"
              name="validade"
              (input)="changeValidade($event); validateForm()"
            />
          </div>
          <div class="cvv">
            <!--CVC / CVV-->
            <label class="credit-card-label cvv-label"
              >{{ "creditCard.cvv" | translate }}
              <img
                *ngIf="tooltipReady"
                tippy
                [tippyOptions]="tooltipOptions"
                src="../../../../assets/imagens/icon-question-mark.png"
              />
            </label>
            <div id="CvvTextbox"></div>
          </div>
        </div>
      </form>
    </div>
    <app-credit-card-external-display
      [displayData]="displayData"
    ></app-credit-card-external-display>
  </div>

  <div class="footer" [class.ios]="platFormIsIos">
    <div class="totais">
      <label>{{ "total" | translate }}</label>
      <label class="value"
        ><span>{{ "currencySymbol" | translate }}</span>
        {{ parameters.netValueFormatted }}</label
      >
    </div>
    <div class="buttons" [class.ios]="platFormIsIos">
      <button class="button btn-back" (click)="back()">
        {{ "goBack" | translate }}
      </button>
      <button
        class="button"
        [class]="formValid ? 'btn-primaria' : 'btn-disabled'"
        (click)="confirmPayment()"
        [disabled]="!formValid"
      >
        {{ "confirm" | translate }}
      </button>
    </div>
  </div>
</section>
