import { Injectable } from '@angular/core';
import { UiService } from './ui.service';

@Injectable({
  providedIn: 'root'
})
export class GiftitemService {

  constructor(private uiService: UiService) { }

  async showNoGiftItemSelectedModal() {
    var response = await this.uiService.showMessage({
      title: await this.uiService.translate('confirmation'),
      icon: "question",
      showCancelButton: true,
      focusConfirm: false,
      confirmButtonText: await this.uiService.translate('iWishToClaim'),
      cancelButtonText: await this.uiService.translate('moveFoward'),
      html: await this.uiService.translate('noGiftItemSelected'),
    });

    return response;
  }
}
