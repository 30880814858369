import { Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { CheckoutCmsService } from 'src/app/services/cms/checkoutCms.service';
import { UiService } from 'src/app/services/ui.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
  showCopyRight: boolean = true;
  htmlFooter: any;

  constructor(
    private checkoutCmsService: CheckoutCmsService,
    private sanitizer: DomSanitizer,
    private uiService: UiService) { }

  async ngOnInit(): Promise<void> {
    this.uiService.hideCopyRight.subscribe(hide =>{
      this.showCopyRight = !hide;
    });
    
    var data = await this.checkoutCmsService.getData();

    if(data && data.htmlFooter){
      this.htmlFooter = this.sanitizer.bypassSecurityTrustHtml(data.htmlFooter);
    }
  }
}