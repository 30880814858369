<section class="sec-checkout pagamento" [style.display]="isElos === null || isElos === true ? 'none': 'block'">
    <div>
        <app-stepHeader stepId="3"></app-stepHeader>
    </div>

    <div class="grid grid-title">
        <h2>{{'payment' | translate}}</h2>
    </div>

    <div class="grid" *ngIf="!showPixData">
        <!-- COLUNA FORMA DE PAGAMENTO -->
        <div class="col-form-dados">

            <app-salesman *ngIf="isTotem"></app-salesman>

            <app-manual-apply-campaigns [manualApplyCampaigns]="manualApplyCampaigns">
            </app-manual-apply-campaigns>

            <app-gift-item></app-gift-item>

            <app-voucher></app-voucher>

            <app-affiliates></app-affiliates>

            <app-establishment [hidden]="isTotem" [isTotem]="isTotem"
                [flagshipEstablishmentId]="flagshipEstablishmentId"></app-establishment>

            <app-formapgto (changePaymentMethodEvent)="paymentMethodChangeEvent($event)">
            </app-formapgto>

            <app-card *ngIf="isElos != null && paymentLinkOrigin !== 'ELOS'" [hidden]="!isCreditCard" [isTotem]="isTotem"
            [flagshipEstablishmentId]="flagshipEstablishmentId" [paymentMethod]="paymentMethod" [data]="data"></app-card>

            <app-zero-value-payment class="finalize-desktop"></app-zero-value-payment>

            <app-finalize-payment (finalizeWithPixEvent)="finalizeWithPix($event)" class="finalize-desktop">
            </app-finalize-payment>

        </div>
        <!-- FIM COLUNA FORMA DE PAGAMENTO -->

        <app-summary [ngClass]="(summaryOpen ? 'active ': '') + 'col-resumo'"></app-summary>

        <!--BOTÕES PARA FINALIZAR O PEDIDO NO MOBILE, EXIBIDOS APÓS O RESUMO-->

        <app-zero-value-payment class="finalize-mobile"></app-zero-value-payment>

        <app-finalize-payment (finalizeWithPixEvent)="finalizeWithPix($event)" class="finalize-mobile">
        </app-finalize-payment>

    </div>
</section>

<div *ngIf="showPixData">
    <app-pix [transactionId]="pixTransactionId" [waitingPixProceed]="waitingPixProceed" [externalCode]="externalCode" [isElos]="isElos"></app-pix>
</div>