<div class="sec-term">
    <div class="checkbox">
        <input type="checkbox" name="" id="termo_de_aceite" [(ngModel)]="uiService.termsAccepted" (change)="markTermAccepted(uiService.termsAccepted)">
        <label for="termo_de_aceite">
            <p>{{'readAndAccepted' | translate}} </p>
        </label>
        <div class="btn-termo-de-aceite"><a (click)="termsAndConditions()"> {{'acceptTerms' | translate}}</a></div>
    </div>   
    
    <p *ngIf="finalizeWithoutTerms" class="accept-message"><span style="color: red; font-weight: bold;">{{ 'pleaseAcceptTermsToContinueAttention' | translate  }}</span>{{ 'pleaseAcceptTermsToContinue' | translate }}</p>
</div>