import { OnDestroy } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import {
  DefaultModalComponent,
  DefaultModalModel,
} from 'src/app/components/modal/default-modal/default-modal.component';
import {
  AppIntegrationService,
  EAppIntegrationPaymentStatus,
} from 'src/app/services/app-integration.services';
import { PaymentExternalService } from 'src/app/services/payment-external.services';
import { CreditCardExternalDisplay } from './credit-card-external-display/credit-card-external-display.component';
import { UiService } from 'src/app/services/ui.service';
import { PaymentService } from 'src/app/services/payment.service';
import { APP_AGE } from 'src/app/helpers/constants';

@Component({
  selector: 'app-credit-card-external',
  templateUrl: './credit-card-external.component.html',
  styleUrls: ['./credit-card-external.component.scss'],
})
export class CreditCardExternalComponent implements OnInit, OnDestroy {
  parameters: any;
  formValid: boolean;
  creditCardInfo: any;
  displayData: CreditCardExternalDisplay;
  tooltipOptions: any;
  tooltipReady: boolean = false;

  cardDataSub: Subscription;
  tokenDataSub: Subscription;

  model = {
    nome: '',
    validade: '',
    brandImg: '../../../../assets/brands/none.png',
    brand: '',
  };

  constructor(
    private appIntegrationService: AppIntegrationService,
    private route: ActivatedRoute,
    private paymentExternalService: PaymentExternalService,
    public dialog: MatDialog,
    public translate: TranslateService,
    private uiService: UiService,
    private paymentService: PaymentService
  ) {
    this.route.params.subscribe(async (params) => {
      this.parameters = !!params['data'] ? JSON.parse(params['data']) : null;
    });
  }

  ngOnDestroy() {
    this.cardDataSub?.unsubscribe();
  }

  async ngOnInit() {
    if (this.parameters?.appChannel === APP_AGE) {
      this.uiService.hideModalCookieConsent();
      this.appIntegrationService.loadIntegration(this.parameters, true);
    }

    await this.paymentExternalService.startTokenEx(this.parameters);

    await this.initCVVTooltip();

    this.cardDataSub = this.paymentExternalService.cardData.subscribe(
      (cardData) => {
        this.validateForm();
        this.setCardFlag(cardData);
      }
    );
  }

  async confirmPayment() {
    await this.validateForm(true);

    if (!this.formValid) {
      return;
    }

    this.processTokenData();
  }

  async validateForm(onConfirm = false) {
    this.creditCardInfo = {
      validade: this.model?.validade?.replace('/', ''),
      name: this.model.nome,
    };

    const cardData = this.paymentExternalService.cardData.value;

    this.formValid =
      this.creditCardInfo?.name &&
      this.creditCardInfo?.validade?.length === 4 &&
      this.paymentExternalService.isTokenexFormValid &&
      cardData?.isCvvValid &&
      cardData?.isValid;

    if (!this.formValid && onConfirm) {
      await this.showInvalidCardModal();
    }

    if (!onConfirm) {
      this.displayData = {
        name: this.creditCardInfo.name,
        firstSix: cardData?.firstSix,
        lastFour: cardData?.lastFour,
        validade: this.model.validade,
        brand: this.model.brand,
        brandImage: this.model.brandImg,
        totalValueFormatted: this.parameters?.netValueFormatted,
      };
    }
  }

  /* chamar o tokenize para preparar o token */
  processTokenData() {
    this.tokenDataSub?.unsubscribe();
    this.paymentExternalService.tokenData.next(undefined);
    this.paymentExternalService.tokenize();
    this.tokenDataSub = this.paymentExternalService.tokenData.subscribe(
      async (tokenData) => {
        if (tokenData) {
          this.tokenDataSub?.unsubscribe();
          await this.processPayment(tokenData);
        }
      }
    );
  }

  /* processar o pagamento */
  async processPayment(tokenData) {
    await this.validateForm(true);

    if (!this.formValid) {
      return;
    }

    const storeToken = await this.getStoreToken(tokenData);

    const request = {
      budgetCode: this.parameters.budgetCode,
      budgetId: this.parameters.budgetId,
      transacaoID: this.parameters.code,
      titular: this.creditCardInfo.name,
      vencimento: this.creditCardInfo.validade,
      dadosToken: storeToken?.data.tokenizationData,
    };

    try {
      const result = await this.paymentExternalService.processPayment(request);

      if (result && result?.sucesso) {
        this.appIntegrationService.sendToApp(
          EAppIntegrationPaymentStatus.Confirmed
        );
      } else {
        this.paymentService.showPaymentErrorModal(false);

        this.appIntegrationService.sendToApp(
          EAppIntegrationPaymentStatus.Failure,
          result
        );
      }
    } catch (e) {
      this.appIntegrationService.sendToApp(
        EAppIntegrationPaymentStatus.Error,
        e
      );

      this.paymentService.showPaymentErrorModal(false);
    }
  }

  async getStoreToken(tokenData) {
    return await this.paymentExternalService.getStoreToken({
      tokenData: JSON.stringify(tokenData),
      expiration: this.creditCardInfo?.validade,
      transacaoID: this.parameters.code,
    });
  }

  back() {
    this.appIntegrationService.sendToApp(EAppIntegrationPaymentStatus.Exit);
  }

  changeName($event) {
    this.model.nome = this.model.nome.toLocaleUpperCase();
    setTimeout(() => {
      this.model.nome = this.model.nome
        .normalize('NFD')
        .replace(/[\u0300-\u036f]/g, '');
      this.model.nome = this.model.nome.replace(/[^a-zA-Z ]/g, '');
    }, 10);
  }

  changeValidade($event) {
    switch ($event.inputType) {
      case 'insertText':
      case 'insertFromPaste':
        setTimeout(() => {
          this.model.validade = this.maskvalidade(this.model.validade);
        }, 10);

        break;
      case 'deleteContentBackward':
      case 'deleteContentBackward':
        if (this.model.validade.substr(this.model.validade.length - 1) == '/') {
          this.model.validade = this.model.validade.substr(
            0,
            this.model.validade.length - 2
          );
        }
        break;
    }
  }

  maskvalidade(inputTxt): string {
    inputTxt = inputTxt.replace(/\D/g, '');
    inputTxt = inputTxt.replace(/(\d{2})(\d{1})/, '$1/$2');
    inputTxt = inputTxt.substr(0, 5);

    var currentMonth = new Date().getMonth() + 1;

    if (
      inputTxt.length >= 5 &&
      //mês inválido
      (inputTxt.substr(0, 2) > 12 ||
        inputTxt.substr(0, 2) == '00' ||
        //mês inválido (menor que o mês atual caso seja o ano atual)
        (inputTxt.substr(0, 2) < currentMonth &&
          inputTxt.substr(3, 2) ==
            new Date().getFullYear().toString().substr(2, 2)) ||
        //ano inválido (menor que o ano atual)
        inputTxt.substr(3, 2) <
          new Date().getFullYear().toString().substr(2, 2))
    ) {
      inputTxt = '';
    }

    return inputTxt;
  }

  async showInvalidCardModal() {
    this.dialog.open(DefaultModalComponent, {
      width: '322px',
      data: {
        title: await this.translate.get('invalidCardNumber.title').toPromise(),
        description: await this.translate
          .get('invalidCardNumber.description')
          .toPromise(),
        button: await this.translate
          .get('multiplePaymentsTimeInfo.button')
          .toPromise(),
      } as DefaultModalModel,
    });
  }

  setCardFlag(cardData) {
    const card = this.getCardFlag(cardData?.firstSix, cardData?.lastFour);
    this.model.brandImg = card.image;
    this.model.brand = card.brand;
  }

  getCardFlag(firstSixDigits: string, lastFourDigits: string) {
    const partialCardNumber = firstSixDigits + lastFourDigits;

    if (/^4/.test(partialCardNumber)) {
      return { brand: 'Visa', image: 'visa.png' };
    } else if (/^5[1-5]/.test(partialCardNumber)) {
      return { brand: 'MasterCard', image: 'master.png' };
    } else if (/^34|^37/.test(partialCardNumber)) {
      return { brand: 'American Express', image: 'amex.png' };
    } else if (
      /^6(?:011|5[0-9]{2}|22[1-9]|2[3-9][0-9])/.test(partialCardNumber)
    ) {
      return { brand: 'Discover', image: 'discover.png' };
    } else if (
      /^507(1[0-5]|2[2-9]|5[5])|^50[8-9]|^51|^52|^53|^54|^55/.test(
        partialCardNumber
      )
    ) {
      return { brand: 'Aura', image: 'aura.png' };
    } else if (
      /^36|^3095|^3337|^34[4-9]|^37[0-4|^37[6-9]|^3831|^384[1-9]/.test(
        partialCardNumber
      )
    ) {
      return { brand: 'Diners Club', image: 'diners.png' };
    } else if (
      /^384100|^384140|^384160|^606282|^637095|^637568|^637599|^637609|^637612/.test(
        partialCardNumber
      )
    ) {
      return { brand: 'Hipercard', image: 'hipercard.png' };
    } else if (/^35(2[89]|[3-8][0-9])/.test(partialCardNumber)) {
      return { brand: 'JCB', image: 'jcb.png' };
    }

    return { brand: null, image: null };
  }

  async initCVVTooltip() {
    let tooltipTextContent = {
      title: await this.uiService.translate('cvvTooltip.title'),
      subtitle: await this.uiService.translate('cvvTooltip.subtitle'),
      description: await this.uiService.translate('cvvTooltip.description'),
    };

    this.tooltipOptions = {
      arrow: true,
      content: `<div class="cvv-tooltip">
                  <div class="tooltip-title">${tooltipTextContent.title}</div>
                  <span class="tooltip-tag">
                    ${tooltipTextContent.subtitle}
                  </span>
                  <div class="tooltip-content">
                    ${tooltipTextContent.description}
                  </div>
                </div>`,
      allowHTML: true,
      maxWidth: 283,
    };

    this.tooltipReady = true;
  }

  get platFormIsIos(): boolean {
    return this.parameters?.devicePlatform === 'ios';
  }
}
