<section id="gift-itens-section" [hidden]="!giftItemCampaigns || giftItemCampaigns.length == 0" class="sec-gift-item">
    <div class="grid">
        <div class="title" (click)="expandAccordion()">
            <span class="icon-gift"><img src="../../../../assets/imagens/icon-gift.png"/></span>
            <h2>{{'giftItems' | translate}}</h2>
            <span class="collapse-buttons">
                <span *ngIf="!minimizeForm"><i class="arrow up"></i></span>
                <span *ngIf="minimizeForm"><i class="arrow down"></i></span>
            </span>
        </div>        

        <div [@openClose]="minimizeForm ? 'closed' : 'open'" style="width: 100%;margin-top: 15px;"> 
            <h2>{{'chooseGiftCampaign' | translate }}:</h2>
            
            <div class="gift-campaigns" *ngFor="let campaign of giftItemCampaigns; let lastItem = last;">
                 
                <div class="checkbox campaign-checkbox">
                    <input type="checkbox" id="{{campaign.campaignId}}" name="{{campaign.description}}" [(ngModel)]="campaign.isApplied"  (change)="clearOtherGifts(campaign.campaignId)"/>
                    <label for="{{campaign.campaignId}}" style="width:100%;">                        
                        <p>                                                        
                            {{campaign.description}}
                        </p>
                    </label>                        
                </div> 
                <p class="quantity-to-be-granted">
                    <mat-icon class="form-icon">
                        <svg version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" xmlns:xlink="http://www.w3.org/1999/xlink" enable-background="new 0 0 512 512">
                            <g>
                              <path d="m500.5,94.7h-102.8c6.4-8.6 10.2-19.4 10.2-31.2 0-32.3-27-52-52-52-13.5,0-27,6.2-36.4,15.6l-63.5,63.5-63.5-63.5c-9.4-10.4-22.9-15.6-36.4-15.6-25,0-52,19.8-52,52 0,11.8 3.8,22.6 10.2,31.2h-102.8v123.8h41.6v281.9h405.7v-281.9h41.6v-123.8zm-166.4-53c7.3-7.3 16.6-9.4 21.8-9.4 15.6,0 31.2,11.4 31.2,31.2 0,17.7-13.5,31.2-31.2,31.2h-74.8l53-53zm-209.1,21.8c0-19.8 15.6-31.2 31.2-31.2 5.2,0 13.5,2.1 21.8,9.4l53.1,53h-74.9c-17.7,0-31.2-13.5-31.2-31.2zm-92.6,134.2v-82.2h213.3v82.2h-213.3zm41.6,20.8h171.7v261.1h-171.7v-261.1zm364.2,261.2h-171.7v-261.2h171.7v261.2zm41.6-282h-213.3v-82.2h213.3v82.2z"/>
                            </g>
                        </svg>
                    </mat-icon>
                    {{'youCanChoose' | translate}} {{campaign.giftItems[0].quantityToBeGranted}} {{'prizes' | translate}}
                </p>
                <form style="padding-top:10px;" class="gift-list">
                    <div *ngFor="let item of campaign.giftItems">                                                
                        <div class="checkbox">
                            <input type="checkbox" id="{{item.name}}{{campaign.campaignId}}" name="{{item.name}}{{campaign.campaignId}}" [(ngModel)]="item.isApplied"  [disabled]="campaign.isApplied != true"/>
                            <label for="{{item.name}}{{campaign.campaignId}}" style="width:100%;">
                                <p>{{item.name}}</p>
                            </label>                        
                        </div>             
                    </div>   
                </form>  
                <hr *ngIf="!lastItem"/>  
            </div> 
            <button class="button btn-fechar" [ngStyle]="{'width': (appliedGifts && appliedGifts.length > 0) ? '100%' : '48%'}" (click)="processGiftItems(false)">{{'doNotClaimGiftItems' | translate}}</button>    
            <button *ngIf="!appliedGifts || appliedGifts.length <= 0" class="button btn-finalizar"  style="width:48%;float:right;" (click)="processGiftItems(true)">{{'claimGiftItems' | translate}}</button>
        </div>
    </div>
</section>