<section [hidden]="!ready || total <= 0" class="sec-card">
    <div class="grid">
		<div class="payment-method-title" *ngIf="!isTotem">
			<span class="payment-method-icon"><img src="../../../../assets/imagens/icon-card.png"/></span>
			<span>{{ paymentMethodDescription }}</span>
		</div>
		<!--TOKENEX-->
        <form [@openClose]="minimizeForm ? 'closed' : 'open'" *ngIf="useTokenex">
			<input class="tokenex-input" type="text" id="inputCardName" [(ngModel)]="model.nome" name="name" (ngModelChange)="changeName($event)" placeholder="{{'CreditCardTitleName' | translate}}"/>

			<div id="tokenExIframeDiv" style="max-height: 40px;"> 
			</div>

			<div style="clear:both;"></div>

			<input class="tokenex-input val" type="tel" [(ngModel)]="model.validade" name="validade" (input)="changeValidade($event)" placeholder="{{'CreditCardValidity' | translate}}">

			<div *ngIf="configuracaoToken"
				id="CvvTextbox">
			</div>
			<select
				class="payment-condition-select"
				[(ngModel)]="paymentConditionId" 
				(ngModelChange)="savePaymentMethod()"
				name="paymentCondition">
					<option selected disabled [ngValue]="null">{{'paymentCondition' | translate}}</option>
					<option *ngFor="let condition of paymentConditions" [id]="condition.id" [ngValue]="condition.id">{{condition.displayName}}</option>
			</select>
        </form>

			<!--LEGADO-->
			<form [@openClose]="minimizeForm ? 'closed' : 'open'" *ngIf="!useTokenex">
				<input class="tokenex-input" type="text" id="inputCardName" [(ngModel)]="model.nome" name="name" (ngModelChange)="changeName($event)" placeholder="{{'CreditCardTitleName' | translate}}"/>
				<input class="tokenex-input" type="tel" [(ngModel)]="model.numero" name="numero" (ngModelChange)="changeNumber($event)" placeholder="{{'CreditCardNumber' | translate}}"/>
				<input class="tokenex-input val" type="tel" [(ngModel)]="model.validade" name="validade" (input)="changeValidade($event)" placeholder="{{'CreditCardValidity' | translate}}">
				<input class="tokenex-input" type="tel" [(ngModel)]="model.cvv" name="title" (input)="changeCVV($event)" placeholder="{{'CREDITCARDCVV' | translate}}"/>
	
				<select class="payment-condition-select"
					[(ngModel)]="paymentConditionId" 
					(ngModelChange)="savePaymentMethod()"
					name="paymentCondition">
						<option selected disabled [ngValue]="null">{{'paymentCondition' | translate}}</option>
						<option *ngFor="let condition of paymentConditions" [id]="condition.id" [ngValue]="condition.id">{{condition.displayName}}</option>
				</select>
			</form>
    </div>
</section>