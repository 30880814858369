<div *ngIf="paymentLinkOrigin === 'ELOS'" class="payment-link-payment-way-container">
    <h2>*{{'paymentOptions' | translate}}</h2>
    <div class="payment-ways">
        <div class="payment-method-item" [ngClass]="{'payment-method-item-border-bottom': !paymentMethod.lastItem}" *ngFor="let paymentMethod of paymentMethods; let i = index" (click)="selectPm(paymentMethod)">
            <div class="method-name" *ngIf="!paymentMethod.contingency">
                <img *ngIf="paymentMethod.creditCardType == 1"  src="../../../../assets/imagens/icon-payments-credit.png"/>
                <img *ngIf="paymentMethod.creditCardType == 3" src="../../../../assets/imagens/icon-payments-pix.png"/>
                <img *ngIf="paymentMethod.creditCardType == 2" src="../../../../assets/imagens/icon-payments-recurrent.png"/> 
                <img *ngIf="paymentMethod.creditCardType == paymentMethodEnum.MultiplePayments" src="../../../../assets/imagens/multiple-payments-icon.png"/> 

                <div class="payment-method-text-block">
                    <p>{{ paymentMethod.name }}</p> 
                    <p class="payment-method-description">
                        <span *ngIf="paymentMethod.creditCardType == paymentMethodEnum.CreditCard && paymentMethodsDescription?.credit">{{paymentMethodsDescription.credit}}</span>
                        <span *ngIf="paymentMethod.creditCardType == paymentMethodEnum.Recurrent && paymentMethodsDescription?.recurrent">{{paymentMethodsDescription?.recurrent}}</span>
                        <span *ngIf="paymentMethod.creditCardType == paymentMethodEnum.Pix && paymentMethodsDescription?.pix">{{paymentMethodsDescription?.pix}}</span>
                        <span *ngIf="paymentMethod.creditCardType == paymentMethodEnum.MultiplePayments && paymentMethodsDescription?.multiple">{{paymentMethodsDescription?.multiple}}</span>
                    </p>
                </div>
            </div>
            <!--CONTINGENCIA-->
            <div class="contingency-method-name">
                <div *ngIf="paymentMethod.contingency">
                    <div class="contingency-container">
                        <span class="contingency-tag">{{'new' | translate}}</span>
                        <p>{{ paymentMethod.name }}</p> 
                    </div>
                    <div class="contingency-method-description">
                        <img *ngIf="paymentMethod.creditCardType == 1"  src="../../../../assets/imagens/icon-payments-credit.png"/>
                        <img *ngIf="paymentMethod.creditCardType == 3" src="../../../../assets/imagens/icon-payments-pix.png"/>
                        <img *ngIf="paymentMethod.creditCardType == 2" src="../../../../assets/imagens/icon-payments-recurrent.png"/> 
                        <span *ngIf="paymentMethod.creditCardType == paymentMethodEnum.CreditCard && paymentMethodsDescription?.credit">{{paymentMethodsDescription.credit}}</span>
                        <span *ngIf="paymentMethod.creditCardType == paymentMethodEnum.Recurrent && paymentMethodsDescription?.recurrent">{{paymentMethodsDescription?.recurrent}}</span>
                        <span *ngIf="paymentMethod.creditCardType == paymentMethodEnum.Pix && paymentMethodsDescription?.pix">{{paymentMethodsDescription?.pix}}</span>
                    </div>
                </div>
            </div>
            <!--FIM CONTINGÊNCIA-->
            <div class="method-value" *ngIf="paymentMethod.creditCardType != paymentMethodEnum.MultiplePayments">
                <p *ngIf="paymentMethod.creditCardType != paymentMethodEnum.Pix" class="from">{{'creditCard.from' | translate}}</p>
                <span *ngIf="paymentMethod.showDiscountPercent && paymentMethod.bestDiscount && paymentMethod.creditCardType != paymentMethodEnum.Pix" class="discount-tag">{{paymentMethod.bestDiscount}}% OFF</span>
                <span *ngIf="paymentMethod.showDiscountPercent && paymentMethod.bestDiscount && paymentMethod.creditCardType == paymentMethodEnum.Pix" class="discount-tag pix-tag">+{{paymentMethod.bestDiscount}}% OFF</span>
                <p>
                    <span class="currency-symbol">&nbsp;&nbsp;{{'currencySymbol' | translate }}&nbsp;</span>{{ paymentMethod.netValue - otherDiscounts | currency: translate.instant('currency') : '' : '1.2-2' }}
                </p>
            </div>
            <div class="clear"></div>
            <div class="arrow-right">
                <img src="../../../../assets/imagens/icon-arrow.png"/>
            </div>
        </div>     
    </div>
</div>