import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { List } from 'linqts';
import { PaymentService } from './payment.service';
import { DefaultModalComponent, DefaultModalModel } from '../components/modal/default-modal/default-modal.component';
import { TranslateService } from '@ngx-translate/core';
import { MatDialog } from '@angular/material/dialog';
import { MethodModel } from '../model/payments/method.model';
import { PaymentItemModel } from '../model/payments/payment-item.model';
import { CreditCardDisplayModel } from '../model/payments/credit-card-display.model';
import { PaymentMethodModel } from '../model/payments/payment-method.model';
import { BasePaymentConditionModel, PaymentConditionModel } from '../model/payments/payment-condition.model';

@Injectable({
  providedIn: 'root'
})
export class PaymentLinkService {

  public validateData: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public goBack: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public creditCardData: BehaviorSubject<CreditCardDisplayModel> = new BehaviorSubject<CreditCardDisplayModel>(null);
  public paymentMethodChanged: BehaviorSubject<PaymentMethodModel> = new BehaviorSubject<PaymentMethodModel>(null);
  public paymentConditionChanged: BehaviorSubject<PaymentConditionModel> = new BehaviorSubject<PaymentConditionModel>(null);
  public finalizePurchaseFromPaymentLink = new BehaviorSubject<boolean>(false);
  public multiplePaymentsSelected: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  public multiplePaymentsConditionChanged: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  public clearPixValue: BehaviorSubject<any> = new BehaviorSubject<any>(null);

  constructor(
    private paymentService: PaymentService,
    public dialog: MatDialog,
    public translate: TranslateService) { }

 /**
  * Lista as condições de pagamento de acordo com a forma de pagamento selecionada
  * @param data 
  * @param methodId Id da forma de pagamento de referência, utilizado apenas no caso de múltiplas formas de pagamento
  * @returns 
  */
  queryPaymentConditions(data, methodId: number = null, totalWithoudPixValue: number = null, pixFinalValue: number = null, otherDiscounts: number = null): PaymentConditionModel[] {
      let paymentMethodId = methodId ? methodId : data.order.paymentMethodId;
      let totalAmount = totalWithoudPixValue ? totalWithoudPixValue : data.order.totalAmount;
      
      let paymentConditions = 
        new List<MethodModel>(data.methods)
          .Where(d => d.paymentMethod_Id == paymentMethodId)
          .Select(x => (this.mapPaymentCondition(x, totalAmount, pixFinalValue, otherDiscounts)))
          .OrderBy(x=> x.installments)
          .ToArray();

      return paymentConditions;
  }


  mapPaymentCondition(paymentCondition: BasePaymentConditionModel, totalAmount: any, pixFinalValue: number = null, otherDiscounts: number = null): PaymentConditionModel {
    var valorTotalVenda = totalAmount;
    var discountPercent = Math.round((paymentCondition.discountPercent || paymentCondition.discountAmount) * 100);

    var totalInstallment = (valorTotalVenda - (valorTotalVenda * paymentCondition.discountAmount)) - (pixFinalValue ?? 0) - (otherDiscounts ?? 0);

    return {      
      id: paymentCondition.paymentCondition_Id,
      installments: paymentCondition.paymentCondition_Installments,
      installmentValue: paymentCondition.installmentValue || Math.round((totalInstallment / paymentCondition.paymentCondition_Installments) * 100) / 100,
      discountPercent: paymentCondition.discountPercent,
      formatedDiscountPercent: discountPercent,
      feesPercent: discountPercent > 0 ? null : discountPercent * -1,
      total: paymentCondition?.netValue || Math.round(totalInstallment * 100) / 100,
      minInstallmentAmount: paymentCondition.minInstallmentAmount,
      minPurchaseAmountToEnable: paymentCondition.minPurchaseAmountToEnable,
      paymentCondition_Id: paymentCondition.paymentCondition_Id,
      paymentCondition_Description: paymentCondition.paymentCondition_Description,
      paymentCondition_Installments: paymentCondition.paymentCondition_Installments,
      netValue: paymentCondition.netValue,
      discountAmount: paymentCondition.discountAmount
    };
  }

  getBestDiscount(data, paymentMethodId) {
    let methods = data.methods.filter(w => w.paymentMethod_Id == paymentMethodId);
    let bestDiscount = Math.max(...methods.map(o => o.discountAmount));
    return Math.round((bestDiscount * 100) * 100) / 100;
  }

  async checkMultiplePayments(showModal: boolean = true, reload: boolean = true): Promise<boolean>{
    var result = await this.paymentService.checkMultiplePayments();
    if (!result.isEnable) {
      if (showModal) {
        await this.showMultiplePaymentsTimeInfoModal();
      }

      if (reload) {
        window.location.reload();
      }
    }
    return result.isEnable;
  }

  async showMultiplePaymentsTimeInfoModal(){
    await this.dialog.open(DefaultModalComponent, {
      width: '322px',
      data: {
        title: await this.translate.get("multiplePaymentsTimeInfo.title").toPromise(),
        description: await this.translate.get("multiplePaymentsTimeInfo.description").toPromise(),
        subDescription: await this.translate.get("multiplePaymentsTimeInfo.subDescription").toPromise(),
        button: await this.translate.get("multiplePaymentsTimeInfo.button").toPromise()
      } as DefaultModalModel
    })
    .afterClosed()
    .toPromise();
  }

  getOtherDiscounts(payments: PaymentItemModel[]): number{
    let otherDiscounts = payments?.reduce((previousValue, currentValue) => previousValue + ((currentValue.amount ?? 0) * (currentValue.appliedDiscount ?? 0)), 0);
    return otherDiscounts ?? 0;
  }
}