import { Component, OnInit, Renderer2  } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { PaymentService } from 'src/app/services/payment.service';
import { UiService } from 'src/app/services/ui.service';
import { PixService } from 'src/app/services/pix.service';
import { PaymentLinkService } from 'src/app/services/payment-link.service';
import { ELOS } from 'src/app/helpers/constants';

@Component({
  selector: 'app-payment',
  templateUrl: './payment.component.html',
  styleUrls: ['./payment.component.scss']
})
export class PaymentComponent implements OnInit {

  id: string;
  isLoading: boolean = true;
  isElos?: boolean = null;
  summaryOpen: boolean;
  isBudget: boolean = false;
  isTotem: boolean;
  fromCart: boolean = false;
  flagshipEstablishmentId: number;
  newEmail: string;
  manualApplyCampaigns: any[] = [];
  paymentRequestCode: string;
  paymentMethod: any;
  isCreditCard: boolean = false;
  pixTransactionId: string = null;
  showPixData: boolean = false;
  externalCode: any;
  showCreditCardData: boolean = false;
  data: any;
  paymentLinkOrigin: string;
  waitingPixProceed: boolean = false;
  finalizeWithPixResult: any;

  constructor(private route: ActivatedRoute,
    public uiService: UiService,
    private paymentService: PaymentService,
    private router: Router,
    private renderer: Renderer2,
    private pixService: PixService,
    private paymentLinkService: PaymentLinkService) { }

  async ngOnInit() {
    this.uiService.onLoading.subscribe((data) => {
      this.isLoading = data.loading;
    });

    this.paymentLinkService.goBack.subscribe(e => {
      if(e){
          this.goBackToPaymentMethods(e);
        }
    });

    this.uiService.uiChangedEvent.subscribe((e) => {

      if (e == 'summaryOpen') {
        this.summaryOpen = true;
      }
      if (e == 'summaryClose') {
        this.summaryOpen = false;
      }
    });

    this.route.queryParams.subscribe(async params => {
      if (params['fromCart'] == "true")
      {
        this.fromCart = true;
      }
    });

    this.paymentService.dataChangedEvent.subscribe((e) => {

      this.data = e.data;

      this.paymentLinkOrigin = e.data.order.origin;
      this.isElos = this.paymentLinkOrigin === 'ELOS';

      if(this.paymentLinkOrigin === 'ELOS'){
        this.renderer.addClass(document.body, 'is-payment-link');
      }

      this.isBudget = e.data.order.isBudget;

      this.paymentRequestCode = e.data.order.code;

      this.isCreditCard = e.data.order.paymentMethod_CreditCardType != 3;

      this.externalCode = e.data.order.externalCode;

      this.getManualApplyCampaigns();

      if (e.event == 'finalize' || (e.event == 'loadPayment' && e.data.order.status == 6)) {
        if (this.fromCart)
          this.router.navigate([ `/done/${e.data.order.externalCode}`], { queryParams: { fromCart: "true" } });
        else
          this.router.navigate([ `/done/${e.data.order.externalCode}`]);
      }
      if(this.newEmail){
        var data = this.paymentService.flagshipUpdateClientEmail(this.newEmail);
      }

    });

    this.route.url.subscribe(async params => {
      this.id =  this.route.snapshot.paramMap.get('id');

        try {
          await this.paymentService.loadPaymentRequest(this.id);

          if(this.paymentService.data.order.origin === ELOS){
            await this.paymentService.displayModalGoToMyBudgets();
          }
        }
        catch (e) {
          await this.uiService.showError(e);
        }

    });

    this.route.queryParams.subscribe(async params => {
      this.isTotem = params['tt'] ? params['tt'] : false;
      this.flagshipEstablishmentId = params['id'];
      this.newEmail = params['e'];
    });

  }

  async getManualApplyCampaigns(){
    this.manualApplyCampaigns = await this.paymentService.getManualApplyCampaigns(this.paymentRequestCode);
  }

 async paymentMethodChangeEvent(e){
      this.paymentMethod = e;
  }

  finalizeWithPix(e){
    if(e){
      this.pixTransactionId = e;
      if(!this.isElos){
        this.showPixData = true;
      }
    }
  }

  async goBackToPaymentMethods(e){
    if(e){
      this.showCreditCardData = false;
      this.waitingPixProceed = false;
      this.showPixData = false;
      await this.paymentService.loadPaymentRequest(this.id);
    }
  }

  async finalizePix(){
    this.waitingPixProceed = true;
    this.finalizeWithPixResult = await this.pixService.finalizePix(this.paymentLinkOrigin !== 'ELOS');
  }

  proceed(){
    if(this.finalizeWithPixResult.transacaoId){
      this.finalizeWithPix(this.finalizeWithPixResult.transacaoId);
      this.waitingPixProceed = false;
      this.showPixData = true;
    }
  }

}
