import { Component, OnInit, Input, Output, EventEmitter, Renderer2 } from '@angular/core';
import { PaymentService } from 'src/app/services/payment.service';
import { PaymentLinkService } from 'src/app/services/payment-link.service';
import { PaymentMethodModel } from 'src/app/model/payments/payment-method.model';
import { PaymentConditionModel } from 'src/app/model/payments/payment-condition.model';

@Component({
  selector: 'app-pix-confirmation',
  templateUrl: './pix-confirmation.component.html',
  styleUrls: ['./pix-confirmation.component.scss']
})
export class PixConfirmationComponent implements OnInit {

  @Input() data: any;
  @Output() finalize = new EventEmitter<any>(null);

  paymentMethodSelected: PaymentMethodModel;
  paymentConditionSelected: PaymentConditionModel;

  constructor(
    private renderer: Renderer2,
    private paymentService: PaymentService,
    private paymentLinkService: PaymentLinkService
  ) { }

  ngOnInit(): void {
    this.paymentLinkService.paymentMethodChanged.subscribe((pm: PaymentMethodModel) => {
      this.paymentMethodSelected = pm;
    });

    let otherDiscounts = this.paymentLinkService.getOtherDiscounts(this.data.order.payments);

    this.paymentConditionSelected = this.paymentLinkService.queryPaymentConditions(this.data, null, null, null, otherDiscounts)[0];
    this.paymentLinkService.paymentConditionChanged.next(this.paymentConditionSelected);
    this.renderer.addClass(document.body, 'back-button-visible');
  }

  async goNext(){
    let validateResult = await this.paymentService.validatePaymentRequestVersion();
    if(!validateResult.versionsMatch){
      var modalResult = await this.paymentService.showWrongVersionModal();
      if(modalResult){
          window.location.reload();
      }
      return { outdated: true };
    }
    this.finalize.emit();
  }


}
