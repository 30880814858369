import { Component, OnInit, Renderer2 } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { PaymentService } from 'src/app/services/payment.service';
import { PixService } from 'src/app/services/pix.service';
import { PaymentLinkService } from 'src/app/services/payment-link.service';
import { UiService } from 'src/app/services/ui.service';
import { AppIntegrationService } from 'src/app/services/app-integration.services';
import { APP_AGE } from 'src/app/helpers/constants';

@Component({
  selector: 'app-budget',
  templateUrl: './budget.component.html',
  styleUrls: ['./budget.component.scss']
})
export class BudgetComponent implements OnInit {
  id: string;
  data: any;
  externalCode: string;

  showPixData: boolean = false;
  showCreditCardData: boolean = false;
  showPartialDoneData: boolean = false;

  waitingPixProceed: boolean = false;
  pixTransactionId: string = null;
  paymentMethod: any;
  finalizeWithPixResult: any;
  isLoading: boolean = true;
  showMultiplePayments: boolean = false;
  multiplePayments: any;
  serverTime: any;
  partiallyPaid: boolean = false;
  isAppOrigem: boolean;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private paymentService: PaymentService,
    private pixService: PixService,
    private paymentLinkService: PaymentLinkService,
    private uiService: UiService,
    private renderer: Renderer2,
    private appIntegrationService: AppIntegrationService,
    ) { }

  async ngOnInit() {
    this.uiService.onLoading.subscribe((data) => {
      this.isLoading = data.loading;
    });

    this.renderer.addClass(document.body, 'is-payment-link');

    this.paymentLinkService.goBack.subscribe(e => {
      if (e) {
        this.goBackToPaymentMethods(e);
      }
    });

    this.paymentService.dataChangedEvent.subscribe(async (e) => {
      this.data = e.data;

      this.serverTime = this.data.order.serverTime;

      if (e.data.order.partiallyPaid) {
        this.partiallyPaid = true;
        await this.goToPix();
      }

      this.externalCode = e.data.order.externalCode;

      if (e.event == 'finalize' || (e.event == 'loadPayment' && e.data.order.status == 6)) {
        this.router.navigate([ `/done/${e.data.order.externalCode}`]);
      }
    });

    this.route.url.subscribe(async params => {

      this.id =  this.route.snapshot.paramMap.get('id');

      /* Integração com App de Agendamento */
      if (!this.id) {
        try {
          const origem = this.route.snapshot.paramMap.get('origem');

          if (origem === APP_AGE) {
            const data = this.route.snapshot.paramMap.get('data');

            const parameters = data ? JSON.parse(data) : null;
            this.appIntegrationService.loadIntegration(parameters);

            this.id = parameters?.budgetCode;
            this.isAppOrigem = true;

            this.uiService.hideModalCookieConsent();
          }
        }
        catch (e) {
          await this.uiService.showError(e);
        }
      }

      try {
        await this.paymentService.loadPaymentRequest(this.id, true, this.isAppOrigem);
      }
      catch (e) {
        await this.uiService.showError(e);
      }
    });

    this.paymentService.showPartialDone.subscribe(sub => {
      if (sub) {
        this.goToPartialDone();
      }
    });

    this.paymentService.goToPix.subscribe(async sub =>{
      if (sub) {
        await this.goToPix();
      }
    });
  }

  async paymentMethodChangeEvent(e){
    //se houver mais de uma forma de pagamento selecionada (PIX + Crédito)
    if (e instanceof Array){
      this.paymentMethod = e[0];
      this.multiplePayments = e;
      this.showCreditCardData = true;
      this.showMultiplePayments = true;
    } else {
      this.paymentMethod = e;
      if (this.paymentMethod.creditCardType == 3) {
        let paymentCondition = this.paymentLinkService.queryPaymentConditions(this.data)[0];
        await this.finalizePix(paymentCondition.id);
      } else if (this.paymentMethod.creditCardType == 1 ||  this.paymentMethod.creditCardType == 2){
        this.showCreditCardData = true;
      }
    }
  }

  async goBackToPaymentMethods(e){
    if(e){
      this.showCreditCardData = false;
      this.waitingPixProceed = false;
      this.showPixData = false;
      this.showMultiplePayments = false;
      this.paymentMethod = null;
      this.showPartialDoneData = false;
      await this.paymentService.loadPaymentRequest(this.id, true, this.isAppOrigem);
    }
  }

  async finalizePix(paymentConditionId){
    try {
      this.waitingPixProceed = true;
      this.finalizeWithPixResult = await this.pixService.finalizePix(false, paymentConditionId);
    }
    catch (e) {
      await this.uiService.showError(e);
    }
  }

  async proceed(){
    try {
      if (this.finalizeWithPixResult.transacaoId) {
        this.pixTransactionId = this.finalizeWithPixResult.transacaoId;
        this.waitingPixProceed = false;
        this.showPixData = true;
      }
    }
    catch (e) {
      await this.uiService.showError(e);
    }
  }

  goToPartialDone(){
    this.showCreditCardData = false;
    this.waitingPixProceed = false;
    this.showPixData = false;
    this.showMultiplePayments = false;
    this.showPartialDoneData = true;
  }

  async goToPix(){
    if (!this.data.order?.externalKey) {
      this.data = await this.paymentService.loadPaymentRequest(this.data.order.externalCode, true, this.isAppOrigem);
    }

    this.pixTransactionId = this.data.order.externalKey;
    this.showCreditCardData = false;
    this.waitingPixProceed = false;
    this.showPixData = true;
    this.showMultiplePayments = false;
    this.showPartialDoneData = false;
  }
}
