import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';

export interface MultiplePaymentsConfirmModalModel{
  type: MultiplePaymentsConfirmModalType;
}

export enum MultiplePaymentsConfirmModalType
{
  CreditCard = 1,
  Recurrent = 2
}

@Component({
  selector: 'app-multiple-payments-confirm-modal',
  templateUrl: './multiple-payments-confirm-modal.component.html',
  styleUrls: ['./multiple-payments-confirm-modal.component.scss']
})
export class MultiplePaymentsConfirmModalComponent implements OnInit {
  title: string;

  constructor(
    public dialogRef: MatDialogRef<MultiplePaymentsConfirmModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: MultiplePaymentsConfirmModalModel,
    public translate: TranslateService) {
      
  }

  async ngOnInit() {
    var titleKey = this.data.type == MultiplePaymentsConfirmModalType.CreditCard ? 'multiplePaymentsConfirm.titleCredit' : 'multiplePaymentsConfirm.titleRecurrent';
    this.title = await this.translate.get(titleKey).toPromise();
  }

}
