<h3 class="fs-info" *ngIf="mapReady == false">{{ 'waitMapPermission' | translate }}</h3>

    <div *ngIf="mapReady">

        <mat-select #selectUnidade
            [formControl]="selectUnidadeCtrl"
            [(value)]="model"
            (valueChange)="selecionarUnidade($event)"
            placeholder="{{model && model.Description ? model.Description : 'Selecione a Unidade'}}"
            panelClass="long-panel">
            <mat-select-trigger>{{model && model.Description ? model.Description : ''}}
            </mat-select-trigger>

            <mat-option>
                <ngx-mat-select-search
                    [formControl]="selectUnidadeFilterCtrl"
                    [placeholderLabel]="'Digite o CEP, Cidade ou Bairro'"
                    [noEntriesFoundLabel]="'Nenhum Resultado Encontrado'"
                    [indexAndLengthScreenReaderText]="' de '">
                    <mat-icon ngxMatSelectSearchClear>X</mat-icon>
                </ngx-mat-select-search>
            </mat-option>

            <mat-option class="unidade-option" *ngFor="let unidade of filteredUnidades | async" [value]="unidade">
                <span>
                    <!-- NOME UNIDADE -->
                    <span class="unidade-title"><b>{{getEstablishmentDescription(unidade.Description)}}</b></span>

                    <!-- ENDERECO UNIDADE SELECIONADA -->
                    <p class="unidade-end" *ngIf="unidade.Address">
                        {{unidade.Address.Description}},
                        {{unidade.Address.Neighborhood}},
                        {{unidade.Address.Number}}
                        <br> {{unidade.Address.City}} {{ unidade.Address.Complement ? unidade.Address.Complement : '' }} -
                        {{unidade.Address.StateAbrev}}</p>

                       <!-- HORARIOF FUNCIONAMENTO UNIDADE -->
                       <ng-template [ngIf]="unidade.SchedulerCalendarTime">
                    <p class="unidade-end"><b> HORÁRIOS DE FUNCIONAMENTO</b></p>
                    <p class="unidade-end">
                        <span *ngFor="let calendar of unidade.SchedulerCalendarTime">
                            {{ getWeekDay(calendar.DaysOfWeek) }} das {{calendar.StartTime}} às {{calendar.EndTime}}
                            <br>
                        </span>
                    </p>
                </ng-template>
                </span>
                <mat-divider></mat-divider>
            </mat-option>
        </mat-select>

        <h3 class="fs-mini-info" >{{ 'inputCityForMoreLocationResults' | translate }}</h3>

    </div>