import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';

export interface DefaultModalModel{
  title: string;
  description: string;
  subDescription?: string;
  icon?: string;
  button?: string;
  showConfirmation?: boolean;
}

@Component({
  selector: 'app-default-modal',
  templateUrl: './default-modal.component.html',
  styleUrls: ['./default-modal.component.scss']
})
export class DefaultModalComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<DefaultModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DefaultModalModel,
    public translate: TranslateService) {
    }

  async ngOnInit() {
    this.data.button = this.data.button ?? await this.translate.get('close').toPromise();
  }

  closeModal(){
    this.dialogRef.close();
  }
}
