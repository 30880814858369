import { Injectable, Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { UiService } from 'src/app/services/ui.service';
import { CheckoutCmsService } from './cms/checkoutCms.service';

@Injectable({
  providedIn: 'root'
})

export class GtmService {

  constructor(private uiService: UiService, private checkoutCmsService: CheckoutCmsService, @Inject(DOCUMENT) private doc) {}

   async createGTM() : Promise<void> {

    if(this.uiService.isBrowser){

      var result = await this.checkoutCmsService.getData();

      let script: HTMLScriptElement = this.doc.createElement('script');
      script.innerText = "(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':"+
      "new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],"+
      "j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src="+
      "'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);"+
      "})(window,document,'script','dataLayer','"+ result.gtm +"');";
      this.doc.head.prepend(script);

      let noscript: HTMLElement = this.doc.createElement('noscript');
      noscript.innerText = "<iframe src='https://www.googletagmanager.com/ns.html?id="+ result.gtm +"' height='0' width='0' style='display:none;visibility:hidden'></iframe>";
      this.doc.body.prepend(noscript);
    }
  }
}