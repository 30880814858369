import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import gql from 'graphql-tag';
import { BehaviorSubject } from 'rxjs';
import { BaseService } from '../base/base.service';
import { TransferStateService } from '../transfer-state.service';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';



const checkoutConfigQuery = `
{
    queryCheckoutContents {
      flatData {
        termoCondicoes,
        gtm,
        htmlFooter,
        timeWaitPaymentConfirmation
      }
    }
  }`;

  const brandQuery = `{
    queryHomeContents {
      flatData {
        brand
      }
    }
  }`;

  const pixQuery = `{
    queryCheckoutContents {
      flatData {
        backgroundPix {
          url
        },
        pixInstructions
      }
    }
  }`;

  const recurentInfoQuery = `{
    queryCheckoutContents {
      flatData {
        recurrentInformative
      }
    }
  }`;

  const paymentMethodsDescriptionQuery = `{
    queryCheckoutContents{
      flatData{
        pixPaymentMethodDescription,
        creditPaymentMethodDescription,
        recurrentPaymentMethodDescription,
        multiplePaymentMethodsDescription
      }
    }
  }`;

  const paymentMethodsSubtitlesQuery =`{
    queryCheckoutContents{
      flatData{
        subTitlePaymentMethodCredit,
        subTitlePaymentMethodRecurrent,
        pixPartialPaymentDescription
      }
    }
  }`;

  const budgetNotFoundQuery = `{
    queryCheckoutContents {
      flatData {
        solicitacaoPagamentoNaoEncontrada
      }
    }
  }`;

export interface PaymentMethodsDescription {
  pix: string;
  credit: string;
  recurrent: string;
  multiple: string;
}

export interface PaymentMethodsSubtitle {
  credit: string;
  recurrent: string;
  pixPartial: string;
}

export interface PixContent {
  background: string;
  instructions: SafeHtml;
}

export interface RecurrentInfoContent {
  informative: SafeHtml;
}

@Injectable({
  providedIn: 'root'
})

export class CheckoutCmsService extends BaseService {

  public conheceAreas: any;
  public data: BehaviorSubject<any> = new BehaviorSubject<any>(undefined);
  brand;
  pix: PixContent;
  recurrentInfo: RecurrentInfoContent;

  paymentMethodDescription: PaymentMethodsDescription = {
    pix: null,
    credit: null,
    recurrent: null,
    multiple: null,
  };

  paymentMethodSubtitle: PaymentMethodsSubtitle = {
    credit: null,
    recurrent: null,
    pixPartial: null
  }

  constructor(
    apollo: Apollo, 
    transferState: TransferStateService,
    private sanitizer: DomSanitizer) {
    super(null, apollo, transferState);
  }

  async getData(): Promise<any> {

    var result = await this.getFromCMS('CheckoutCmsService.getData', checkoutConfigQuery);

    var data = result.data.queryCheckoutContents.map(q => {
      return {
        termoCondicoes: q.flatData.termoCondicoes,
        gtm: q.flatData.gtm,
        htmlFooter: q.flatData.htmlFooter,
        timeWaitPaymentConfirmation: q.flatData.timeWaitPaymentConfirmation
      }
    })[0];

    this.data.next(data);

    return data;
  }

  async getBrand(): Promise<any> {
    if (this.brand) return this.brand;

    var result = await this.getFromCMS('CheckoutCmsService.getBrand', brandQuery);

    this.brand = result.data.queryHomeContents[0].flatData.brand;

    return this.brand;
  }

  async getPixContent(): Promise<PixContent> {
    if (this.pix) return this.pix;

    var result = await this.getFromCMS('CheckoutCmsService.getPixContent', pixQuery);

    var flatData = result.data.queryCheckoutContents[0].flatData;

    this.pix = {
      background: flatData.backgroundPix && flatData.backgroundPix.length > 0 ? flatData.backgroundPix[0].url : null,
      instructions: this.sanitizer.bypassSecurityTrustHtml(flatData.pixInstructions)
    } as PixContent;

    return this.pix;
  }

  async recurrentInfoContent(): Promise<RecurrentInfoContent> {
    if (this.recurrentInfo) return this.recurrentInfo;

    var result = await this.getFromCMS('CheckoutCmsService.recurrentInfoContent', recurentInfoQuery);

    var flatData = result.data.queryCheckoutContents[0].flatData;

    this.recurrentInfo = {
      informative: this.sanitizer.bypassSecurityTrustHtml(flatData.recurrentInformative)
    } as RecurrentInfoContent;

    return this.recurrentInfo;
  }

  async getPaymentMethodsDescriptions(): Promise<any> {
    
    var result = await this.getFromCMS('CheckoutCmsService.getPaymentMethodsDescriptions', paymentMethodsDescriptionQuery);

    this.paymentMethodDescription.pix = result.data.queryCheckoutContents[0]?.flatData?.pixPaymentMethodDescription;
    this.paymentMethodDescription.credit = result.data.queryCheckoutContents[0]?.flatData?.creditPaymentMethodDescription;
    this.paymentMethodDescription.recurrent = result.data.queryCheckoutContents[0]?.flatData?.recurrentPaymentMethodDescription;
    this.paymentMethodDescription.multiple = result.data.queryCheckoutContents[0]?.flatData?.multiplePaymentMethodsDescription;

    return this.paymentMethodDescription;
  }

  async getPaymentMethodsSubtitles(): Promise<any> {
    
    var result = await this.getFromCMS('CheckoutCmsService.getPaymentMethodsSubtitles', paymentMethodsSubtitlesQuery);
    
    this.paymentMethodSubtitle.credit = result.data.queryCheckoutContents[0]?.flatData?.subTitlePaymentMethodCredit;
    this.paymentMethodSubtitle.recurrent = result.data.queryCheckoutContents[0]?.flatData?.subTitlePaymentMethodRecurrent;
    this.paymentMethodSubtitle.pixPartial = result.data.queryCheckoutContents[0]?.flatData?.pixPartialPaymentDescription;

    return this.paymentMethodSubtitle;
  }

  async getBudgetNotFoundContent(): Promise<SafeHtml> {
    var result = await this.getFromCMS('CheckoutCmsService.getBudgetNotFoundContent', budgetNotFoundQuery);
    var flatData = result.data.queryCheckoutContents[0].flatData;
    return this.sanitizer.bypassSecurityTrustHtml(flatData.solicitacaoPagamentoNaoEncontrada);
  }

}
