<link href="https://fonts.googleapis.com/css2?family=Noto+Sans:wght@400;700&family=Open+Sans:wght@700&family=Work+Sans:wght@700&display=swap" rel="stylesheet">

<section class="sec-pix" *ngIf="!waitingPixProceed && pixResult">
    <div class="pix-container">
        <div class="sec-pix" [ngClass]="{'grid': isElos, 'grid-pix': isElos}">
            <div class="grid">
                <div class="top-content">
                    <span class="pix-title">
                        {{'pix.pix' | translate}}
                    </span>
                    <div class="clear"></div>
                    <div class="subtitle">
                      <p>{{'pix.generated' | translate}}</p>
                    </div>
                    <div class="timer">
                        <div class="pix-timer">
                            <p>{{'pix.payUntil' | translate}}</p>
                            <span *ngIf="remainingTime">{{remainingTime?.hours}}h, {{remainingTime?.minutes}}m e {{remainingTime?.seconds}}s</span>
                        </div>
                        <div class="icon-timer">
                            <img src="../../../../assets/imagens/icon-clock.png" />
                        </div>
                        <div class="clear"></div>
                    </div>
                </div>
                <div class="clear"></div>
                <div class="pix-content">
                    <div class="pixcode-description">
                        <p class="pix-desc"><span class="pix-currency">{{pixResultCurrency}} <b>{{pixResultValue | currency:'BRL':'':'1.2-2'}}</b></span></p>
                        <div class="toggle-qrcode">
                            <div *ngIf="!hideCode" (click)="hideQRCode()">
                                <img src="../../../../assets/imagens/icon-eye.png" />
                                <p>{{'pix.hideQRCode' | translate}}</p>
                            </div>
                            <div *ngIf="hideCode" (click)="hideQRCode()">
                                <img src="../../../../assets/imagens/icon-qr.png" />
                                <p>{{'pix.showQRCode' | translate}}</p>
                            </div>
                        </div>
                        <div class="clear"></div>
                    </div>
                </div>
            </div>
            <div class="grid">
                <div class="pix-content" *ngIf="!pixContent.background">
                    <div class="pix-qrcode" *ngIf="!hideCode">
                        <img [src]="pixResult.QRCode" />
                    </div>
                </div>
                <div class="pix-content pix-content-dynamic" *ngIf="pixContent.background">
                    <div class="pix-qrcode" *ngIf="!hideCode"  [ngStyle]="{'pixContent.background': 'url(' + pixContent.background + ') center center'}">
                        <img [src]="pixResult.QRCode" />
                    </div>
                </div>
            </div>
            <div class="grid">
                <div class="pix-content">
                    <div style="width: 100%;" class="pix-code">
                        <textarea #code readonly>{{linkPix}}</textarea>

                        <button type="button" class="button btn-pix" (click)="copyCode(code)">
                            <span>
                                <img src="../../../../assets/imagens/icon-copy.png" />
                                <p>{{'pix.copyPixCode' | translate}}</p>
                            </span>
                        </button>
                        <p *ngIf="copiedToClipboard">
                            {{'pix.copiedToClipboard' | translate}}
                        </p>
                    </div>

                    <div [innerHtml]="pixContent.instructions"></div>

                    <div *ngIf="!isElos" class="footer-instructions">
                        <img src="../../../../assets/imagens/icon-pedidos.png"/>
                        <p>{{'pix.myOrdersDescription' | translate}}</p>
                    </div>

                    <div *ngIf="isElos">
                        <button class="btn btn-change-payment-method" (click)="confirmCancel()">
                            <img src="../../../../assets/imagens/icon-change.png"/>
                            {{'pix.changePaymentMethod' | translate}}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="sec-pix pix-container" *ngIf="false">
  <div class="pix-container">
  </div>
</section>
