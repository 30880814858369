import {NgModule} from '@angular/core';
import { Apollo } from 'apollo-angular';
import { InMemoryCache } from '@apollo/client/core';
import {HttpLink} from 'apollo-angular/http';
import { environment } from 'src/environments/environment';
import { BrowserTransferStateModule } from '@angular/platform-browser';
import { makeStateKey } from '@angular/platform-browser';
import { TransferState } from '@angular/platform-browser';

const uri = `${environment.cms_url}${environment.cms_content_endpoint}`;

const uriContract = `${environment.cms_url}${environment.cms_content_endpoint_shared}`;

const STATE_KEY = makeStateKey<any>('apollo.state');

@NgModule({
  imports: [
    BrowserTransferStateModule 
  ]
})
export class GraphQLModule {
  cache: InMemoryCache;
  
  constructor(
    apollo: Apollo,
    httpLink: HttpLink,
    private readonly transferState: TransferState) {
    this.cache = new InMemoryCache();

    apollo.create({
      link: httpLink.create({uri}),
      cache: this.cache
    });

    apollo.create({
      link: httpLink.create({ uri: uriContract, method: "GET" }),
      cache: this.cache,
      ssrMode: true
    }, "getSharedContent");

    const isBrowser = this.transferState.hasKey<any>(STATE_KEY);

    if (isBrowser) {
      this.onBrowser();
    } else {
      this.onServer();
    }
  }

  onServer() {
    this.transferState.onSerialize(STATE_KEY, () =>
      this.cache.extract()
    );
  }

  onBrowser() {
    const state = this.transferState.get<any>(STATE_KEY, null);

    this.cache.restore(state);
  }
}
