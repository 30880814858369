<div class="center">
    <div>
        <div id="step1" class="step">{{step1}}</div>
        <div id="step2" *ngIf="showStep" class="step">{{step2}}</div>
        <div id="step3" class="step">{{step3}}</div>
        <div id="step4" class="step">{{step4}}</div>
    </div>
    <ul>
        <li id="step1" [ngClass]="(stepId=='1' ? 'active' : '')">{{numStep1}}</li>
        <li id="step2" *ngIf="showStep" [ngClass]="(stepId=='2' ? 'active' : '')">{{numStep2}}</li>
        <li id="step3" [ngClass]="(stepId=='3' ? 'active' : '')">{{numStep3}}</li>
        <li id="step4" [ngClass]="(stepId=='4' ? 'active' : '')">{{numStep4}}</li>
    </ul>
</div>