<section [hidden]="total <= 0" *ngIf="total > 0" class="sec-finalize-payment ">
   
    <app-terms></app-terms>
    
    <button app-prevent-double-click class="button btn-finalizar" [throttleTime]="5000" (throttledClick)="finalize()">
        {{'checkout' | translate}}
        <mat-icon class="ico">shopping_cart</mat-icon>
    </button>
    
</section>
